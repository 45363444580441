import { Subject } from 'rxjs';

export class StoreTextEditor {
  // private _previewSubject = new Subject<INotificationChangedHtml>();

  private _isBold = false
  private _isItalic = false
  private _isStrikeThrough = false
  private _isCode = false
  private _isCodeBlock = false

  private _pipelineId = ''
  private _lastRange: Range

  // get previewState() {
  //   return this._previewSubject.asObservable();
  // }

  // set lockInput(val) {
  //   this._lockInput = val;
  // }

  get isBold() {
    return this._isBold
  }
  get isItalic() {
    return this._isItalic
  }
  get isStrikeThrough() {
    return this._isStrikeThrough
  }
  get isCode() {
    return this._isCode
  }
  get isCodeBlock() {
    return this._isCodeBlock
  }

  set isBold(val) {
    this._isBold = val
  }
  set isItalic(val) {
    this._isItalic = val
  }
  set isStrikeThrough(val) {
    this._isStrikeThrough = val
  }
  set isCode(val) {
    this._isCode = val
  }
  set isCodeBlock(val) {
    this._isCodeBlock = val
  }
  set pipelineId(val: string) {
    this._pipelineId = val
  }
  set lastRange(val: Range) {
    this._lastRange = val
  }

  constructor() { }

  public insertLink(text: string, link: string) {
    const selection = window.getSelection()
    const range = this._lastRange
    selection.removeAllRanges()
    selection.addRange(range)
    const a = document.createElement('a')
    a.rel = 'noopener noreferrer'
    a.target = '_blank'
    a.textContent = text
    a.href = link
    range.insertNode(a)
  }

}
