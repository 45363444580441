import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { ICreative } from 'src/app/logic/interface/creative';
import { ModelCreative } from 'src/app/logic/model/creative.model';

@Component({
  selector: 'app-modal-add-directory',
  templateUrl: './add-directory.component.html',
})

export class ModalAddDirectoryComponent implements OnInit {

  public name = new FormControl('', [
    Validators.required,
  ]);

  constructor(
    public dialogRef: MatDialogRef<ModalAddDirectoryComponent, ICreative | undefined>,
    @Inject(MAT_DIALOG_DATA) public parent: ICreative | null,
    private modelCreative: ModelCreative,
  ) {
  }

  ngOnInit() { }

  onSubmit() {
    this.modelCreative.addDirectory(this.parent.projectId, this.parent.id, this.name.value).subscribe({
      next: (result) => {
        this.dialogRef.close(result);
      },
      error: (err) => {
        console.error(err);
        alert(err);
      }
    });
  }
}


