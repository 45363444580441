import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { ISession } from '../interface/session'
import { LibStorage } from '../lib/storage.lib'
import { IPartyUser, IUser } from '../interface/user';
import { partyId } from '../helper/util';

@Injectable({
  providedIn: 'root'
})
export class StoreSession {

  private _session: ISession;
  private _changeSession = new Subject<ISession>();

  private _user: IUser;
  private _partyUser: IPartyUser;

  public changeSession = this._changeSession.asObservable();


  get session(): ISession {
    return this._session;
  }
  get user(): IUser {
    return this._user;
  }
  get nowPartyUser(): IPartyUser {
    return this._partyUser;
  }
  set user(val) {
    this._user = val;
    // 現在のpartyUserをセット
    const nowPartyUser = this._user.partyUsers.find((partyUser) => partyUser.partyId === partyId())
    if (nowPartyUser) {
      this._partyUser = nowPartyUser
    }
  }
  set nowPartyUser(val) {
    this._partyUser = val;
  }

  constructor(private strage: LibStorage) {
    this._session = this.initSession();
    const strageSession = this.strage.getObject<ISession>('session');
    if (strageSession != null) {
      this._session = strageSession;
    }
  }

  public login(token: string) {
    this._session.token = token;
    this.strage.setObject('session', this.session);
    this._changeSession.next(this.session);
    location.href = '/'
  }

  public logout() {
    this._session = this.initSession();
    this.strage.setObject('session', this._session);
    this._changeSession.next(this._session);
  }

  public isLogin(): boolean {
    if (this._session.token) {
      // TODO: tokenの期限チェックをいれてもいい
      return true
    }
    return false
  }

  public switchParty(partyId: string) {
    this._session.partyId = partyId

  }



  private initSession(): ISession {
    return {};
  }
}
