<div class="layout-column area-480px height-100">
  <button [mat-dialog-close]="true">
    <i class="jam jam-close close-icon ft-size-6"></i>
  </button>
  <div class="mt-15 box-w-p20">
    <div class="layout-row i-center c-between">
      <div class="layout-column">
        <div class="layout-row i-center">
          <span class="color-gray1 ft-size-1">プロジェクト名</span>
          <button class="btn-main small ml-10" tabindex="-1">edit</button>
        </div>
        <span class="mt-6 ft-size-4 ft-weight-bold text-ellipsis2">{{ storeSidemenu.settingProject.name }}</span>
      </div>
      <div class="img-box-60 circle change ml-20 mr-20 pointer" ngfDrop [selectable]=true [(file)]="image">
        <img *ngIf="storeSidemenu.settingProject.imageUrl !== undefined" [ngfSrc]="storeSidemenu.settingProject.imageUrl">
        <img *ngIf="storeSidemenu.settingProject.imageUrl === undefined" style="background-color: lightgray;">
      </div>
    </div>
    <div class="layout-column mt-20">
      <div class="layout-row i-center">
        <span class="color-gray1 ft-size-1">説明</span>
        <button class="btn-main small ml-10" tabindex="-1">edit</button>
      </div>
      <span class="mt-6 ft-size-2 ft-weight-bold">{{ storeSidemenu.settingProject.description }}</span>
    </div>
    <div class="layout-column mt-20">
      <span class="color-gray1 ft-size-1">プロジェクトユーザー</span>
      <div class="list-box mt-6 bdr-box bdr-gray3">
        <ng-container *ngFor="let partyUser of storeSidemenu.settingProjectPartyUsers">
          <div class="list-row layout-row i-center box-p10">
            <div class="layout-row i-center area-40">
              <div class="img-box-40 circle">
                <img src="{{ partyUser.profileImage }}">
              </div>
              <div class="ml-10 text-ellipsis2">
                {{ partyUser.name }}
              </div>
            </div>
            <div class="layout-row i-center c-end area-10">
              <button class="btn-main small ml-10" tabindex="-1">削除</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="layout-row i-center c-end flex-shrink box-w-p20 box-h-p15">
    <button class="btn-main middle area-200px" (click)="onAddProjectPartyUser()">
      プロジェクトユーザー追加
    </button>
    <button class="btn-main middle area-80px">
      更新
    </button>
  </div>
</div>
