<div class="layout-column c-between area-800px height-100">
  <div class="layout-row i-center flex-shrink box-w-p20 height-50px">
    <p class="ft-size-6 ft-weight-bold">Creative</p>
    <i (click)="isListView = true; changeLayout.next();" class="jam jam-unordered-list ml-40 ft-size-6 pointer"></i>
    <i (click)="isListView = false; changeLayout.next();" class="jam jam-grid ml-10 ft-size-6 pointer"></i>
    <button [mat-dialog-close]="true">
      <i class="jam jam-close close-icon ft-size-6"></i>
    </button>
  </div>
  <div class="layout-column hidden-box">
    <div class="layout-row i-center flex-shrink box-w-p20 height-46px bg-w color-gray1 shadow-bottom">
      <button class="btn-bdr middle area-min-80px"
       [class.selected]="selectedProjectIds.length === storeSidemenu.projects.length"
       (click)="onClickAllProject()">
       全て
      </button>
      <button *ngFor="let project of storeSidemenu.projects; index as i;"
        class="btn-bdr middle area-min-80px area-max-120px ml-10"
        [class.selected]="selectedProjectIds.indexOf(project.id) >= 0"
        (click)="onClickProjectFilter(project)">
        <div class="img-box-18 rect">
          <img *ngIf="project.imageUrl !== null" [src]="project.imageUrl">
        </div>
        <span class="ml-6 text-ellipsis">{{project.name}}</span>
      </button>
    </div>

    <ng-container *ngIf="isListView; else templateGallery" >
      <!-- リスト表示 -->
      <div *ngIf="isListView" class="layout-row i-center flex-shrink box-w-p20 height-34px bg-w color-gray1 ft-size-1 shadow-bottom">
        <div class="flex-shrink area-30px"><i class="jam jam-arrow-square-down ft-size-3"></i></div>
        <div class="area-35 ml-10">名前</div>
        <div class="area-20 ml-10">更新日</div>
        <div class="area-20 ml-10">ステータス</div>
        <div class="area-25 ml-10">サムネイル</div>
      </div>
      <div class="scroll-container" appScrollbar [changeLayout]="changeLayout.asObservable()">
        <ul class="scroll-y">
          <li *ngFor="let row of creatives"
            class="list-row list-select layout-row i-center box-w-p20 height-80px bg-gray4"
            [class.selected]="row.id === selectedCreative.id"
            (click)="onClickCreative(row)">
            <div class="flex-shrink area-30px">
              <i class="jam ft-size-5"
              [class.jam-picture]="row.type === CreativeType.FILE"
              [class.jam-folder]="row.type === CreativeType.DIRECTORY"></i>
            </div>
            <div class="area-35 ml-10 text-ellipsis2">{{row.name}}</div>
            <div class="area-20 ml-10">{{row.updatedAt | date:"yyyy/MM/dd"}}</div>
            <!-- TODO: 利用中の判定 -->
            <div class="area-20 ml-10">利用中</div>
            <div class="area-25 ml-10">
              <div class="img-thumb height-60px">
                <img src="{{row.url}}">
              </div>
            </div>
          </li>
        </ul>
      </div>
      
    </ng-container>

    <ng-template #templateGallery>
      <!-- ギャラリー表示 -->
      <div class="scroll-container" appScrollbar [changeLayout]="changeLayout.asObservable()">
        <div class="scroll-y">
          <div class="box-p10 layout-row flex-wrap">
            <div *ngFor="let row of creatives" class="box-p10">
              <div class="list-select layout-column i-center box-p5 area-218px bg-gray4 bdr-box bdr-gray3 bdr-radius selected">
                <div class="img-thumb layout-column i-center c-center flex-shrink box-w-p5 height-140px mt-10">
                  <img src="{{row.url}}">
                </div>
                <div class="layout-column c-center box-p5 height-40px ft-size-1 text-center">
                  <span class="text-ellipsis2">{{row.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

  </div>

  <div class="layout-row i-center c-end flex-shrink box-w-p20 box-h-p15 shadow-top">
    <button class="btn-main middle area-80px" (click)="onPostMaterial()">
      選択
    </button>
  </div>
</div>
