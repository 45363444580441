import { Injectable } from '@angular/core';

import { Observable, of, map } from 'rxjs';

import { concatMap } from 'rxjs/operators';
import { ICase, IRequestPostCase } from 'src/app/logic/interface/case';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { DataCase } from '../data/case.data';
import { ModelImage } from './image.model';
import { partyId } from '../helper/util';

@Injectable({
  providedIn: 'root'
})
export class ModelCase {

  constructor(
    private dataCase: DataCase,
    private storeSidemenu: StoreSidemenu,
    private modelImage: ModelImage,
  ) { }

  public addCase(projectId: string, name: string, description: string, image: File | undefined): Observable<void> {

    let createdCase: ICase;

    const params: IRequestPostCase = {
      partyId: partyId(),
      fileType: image ? image.type : null,
      case: {
        projectId,
        name,
        description,
      }
    }
    return this.dataCase.addCase(params).pipe(
      concatMap((result) => {
        createdCase = result.case;
        if (!image) {
          return of(void 0);
        }
        return this.modelImage.uploadImage(result.imageUrl, image);
      }),
      map(() => {
        this.storeSidemenu.addCase(createdCase);
      }),
    );
  }

}
