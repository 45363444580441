<div class="layout-column c-between height-100">
  <div class="layout-row i-center flex-shrink box-w-p20 height-50px">
    <p class="ft-size-6 ft-weight-bold">projectPartyUser追加</p>
    <button [mat-dialog-close]="true">
      <i class="jam jam-close close-icon ft-size-6"></i>
    </button>
  </div>
  <div class="box-w-p20">
    <div class="layout-row i-center">
      <div class="layout-column mt-20 area-480px height-100">
        <span class="color-gray1 ft-size-1">パーティユーザー</span>
          <ng-container *ngIf="this.storeSidemenu.settingPartyUsersOfNotJoinedProject.length > 0 else notExistAddableUser">
            <div class="list-box mt-6 bdr-box bdr-gray3">
              <ng-container *ngFor="let partyUser of this.storeSidemenu.settingPartyUsersOfNotJoinedProject">
                <div class="list-row layout-row i-center box-p10">
                  <div class="layout-row i-center c-end area-5">
                    <input type="checkbox" id="{{partyUser.id}}" [checked]="this.storeSidemenu.settingPartyUsersCheckList[partyUser.id]" (change)="onChecked(partyUser.id)">
                  </div>
                  <div class="layout-row i-center area-40">
                    <div class="img-box-40 circle">
                      <img src="{{ partyUser.profileImage }}">
                    </div>
                    <div class="ml-10 text-ellipsis2">
                      {{ partyUser.name }}
                    </div>
                  </div>
                  <div class="layout-row i-center c-end area-10">
                    <button class="btn-main small ml-10" tabindex="-1">削除</button>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #notExistAddableUser>
            projectに追加できるpartyユーザーが存在しません。
          </ng-template>
      </div>
    </div>
  </div>
  <div class="layout-row i-center c-end flex-shrink box-w-p20 box-h-p15">
    <button class="btn-main middle area-80px"
      (click)="onSubmit()"
      [ngClass]="{'disabled': !anyOneOfChecked}"
      [disabled]="!anyOneOfChecked"
    >
      追加
    </button>
  </div>
</div>
