import { Component, OnInit } from '@angular/core';
import { ModelUser } from 'src/app/logic/model/user.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
    ]),
    password: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(private modelUser: ModelUser, private router: Router) { }

  ngOnInit() {
    this.modelUser.logout();

    this.formGroup.valueChanges.subscribe((value: { [key: string]: string }) => {
      // TODO: validate
    });
  }

  login(tooltip: MatTooltip) {
    if (!this.formGroup.valid) {
      return;
    }
    this.modelUser.login(this.formGroup.get('email').value, this.formGroup.get('password').value).subscribe({
      error: (error) => {
        // ログイン失敗
        console.error(error);
        tooltip.message = 'ログインできませんでした。';
        tooltip.show();
        setTimeout(() => {
          tooltip.hide();
          tooltip.message = '';
        }, 2000);
      }
    });
  }
}
