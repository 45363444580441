import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';

@Component({
  selector: 'app-party-top',
  templateUrl: './party-top.component.html',
})
export class PartyTopComponent {
  constructor(
    public storeSidemenu: StoreSidemenu,
    public activatedRoute: ActivatedRoute,
  ) {
    // const partyId = this.activatedRoute.snapshot.params.partyId
    // const party = this.storeSidemenu.parties.find((party) => party.id === partyId)
    // this.storeSidemenu.selectedParty = party
  }

  ngOnInit() {

  }

}
