import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LibStorage {

  constructor() { }

  setObject(key: string, obj: any) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  getList<T>(key: string): T[] | null {
    // strageから取得
    const item = localStorage.getItem(key);
    if (item == null) {
      return null;
    }
    const list: any[] = JSON.parse(item);
    return list.map((row: any) => {
      return Object.assign({}, row) as T;
    });
  }

  getObject<T>(key: string): T | null {
    // strageから取得
    const item = localStorage.getItem(key);
    if (item == null) {
      return null;
    }
    // parse ⇒ object ⇒ T
    return Object.assign({}, JSON.parse(item)) as T;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
