import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataInvitation } from '../data/invitation.data';
import { StoreSession } from 'src/app/logic/store/session.store';
import { IDBParty } from '../interface/party';

@Injectable({
  providedIn: 'root'
})
export class ModelInvitation {

  constructor(
    private dataInvitation: DataInvitation,
  ) { }

  getInvitedPartyData(code: string): Observable<IDBParty> {
    return this.dataInvitation.getInvitedPartyData(code)
  }

  postInvitedUserToPartyUser(code: string, name: string): Observable<IDBParty> {
    return this.dataInvitation.postInvitedUserToPartyUser(code, name)
  }
}
