<div class="layout-column i-center c-center height-100vh bg-w">
  <div class="layout-column box-p10">
    <button class="btn-g area-380px">
      <img src="../../../assets/img/g_icon.svg" class="height-20px mr-10">
      <span class="ft-weight-bold">Googleアカウントで始める</span>
    </button>
  </div>
  <div class="layout-column c-center box-p10">
    <span class="color-gray4 ft-size-4 ft-weight-bold">or</span>
  </div>
  <div class="layout-column i-center box-p10">
    <input class="big area-380px ft-size-4" placeholder="name@email.address">
    <button class="btn-main area-320px mt-20 ft-weight-bold">
      次へ
    </button>
  </div>
</div>
