import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LibHttp, HttpErrorStatus } from '../../logic/lib/http.lib';
import { StoreSession } from 'src/app/logic/store/session.store';
import { filter } from 'rxjs/operators';
import { IPage, pages } from 'src/app/app-routing.module';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { DataParty } from 'src/app/logic/data/party.data';
import { DataUser } from 'src/app/logic/data/user.data';
import { WebpushService } from 'src/app/logic/webpush.service';
import { StoreWebSocket } from 'src/app/logic/store/websocket.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('rootRef', { static: false }) rootRef: ElementRef

  login: boolean
  isShowSideMenu = false
  route: IPage
  styleSheet: CSSStyleSheet

  constructor(
    public storeSidemenu: StoreSidemenu,
    private router: Router,
    private session: StoreSession,
    private http: LibHttp,
    private dataParty: DataParty,
    private dataUser: DataUser,
    private webpushService: WebpushService,
    private storeWebSocket: StoreWebSocket,
  ) {
  }

  ngOnInit() {
    this.session.changeSession.subscribe(() => {
      // ログイン成功時
      if (this.session.isLogin()) {
        this.init()
        this.router.navigate([`/`])
      } else {
        this.toLogin()
      }
    })

    this.storeSidemenu.changeIsCollapsed.subscribe((isCollapsed) => {
      this.chageStyle()
    })

    this.http.observer.subscribe(
      (error: HttpErrorStatus) => {
        if (error === HttpErrorStatus.AUTH) {
          this.toLogin()
        }
      }
    )

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      const route = pages.find((row) => {
        if (e.url.replace('/', '') === row.path || e.urlAfterRedirects.replace('/', '') === row.path) {
          return true;
        } else {
          if (row.path.match(/:.+/) === null) {
            return false
          }

          // TODO: 2つ以上のpath paramの場合
          const pattern = row.path.replace(/:.+/g, '.+')
          const regexp = new RegExp(pattern, 'g');
          if (pattern.length > 0 && regexp.test(e.url.replace('/', ''))) {
            return true;
          } else {
            return false
          }
        }
      })
      this.route = route;
      if (this.route) {
        this.isShowSideMenu = this.route.showSidemenu
      } else {
        this.isShowSideMenu = false
      }
    })

    if (this.session.isLogin()) {
      this.init()
    }
  }

  ngAfterViewInit(): void {
    for (let i = 0; i < document.styleSheets.length; i += 1) {
      const row = document.styleSheets[i]
      try {
        const { cssRules } = row
        if (cssRules.length > 0) {
          this.styleSheet = row
          break
        }
      } catch (error) {
        // console.error(error)
      } 
    }

    this.rootRef.nativeElement.addEventListener('click', (e) => {
      this.webpushService.setAudio()
    })

    // const options = {
    //   body: `テストさんからのメッセージ`,
    //   icon: 'assets/img/favicon.ico',
    //   data: { url: `https://dev.donatello.rundle.jp/` },
    // };
    // this.webpushService.showNotification('新着メッセージ', options);
  }

  private init() {
    // TOOD: 処理タイミングとか見直す
    this.dataUser.getMe().subscribe((res) => {
      this.session.user = res.user
    })
    this.dataParty.getParties().subscribe({
      next: (list) => {
        // Partyを取得してParty画面へリダイレクト
        this.storeSidemenu.parties = list

        const regexp = new RegExp('party/.+', 'g')
        if (!regexp.test(location.pathname)) {
          /*
          TODO: 一旦自動でチーム選択はしないが仕様検討
          if (list.length > 0) {
            console.log('分岐1')
            // TODO: デフォルトを1つ目としていいのか
            const partyId = list[0].id
            this.router.navigate([`/party/${partyId}`])
          } else {
            // Dashboard?
            this.router.navigate([`/`])
          }
          */
        } else {
          // TODO: 正規表現で取得するようにする
          const pathes = location.pathname.replace('/', '').split('/')
          const partyId = pathes[1]
          const party = this.storeSidemenu.parties.find((party) => party.id === partyId)
          this.storeSidemenu.selectedParty = party
          this.storeWebSocket.connect()
          this.storeWebSocket.joinParty(partyId)
        }
      }
    })
  }

  private toLogin() {
    this.router.navigate([`/login`])
  }

  private chageStyle() {
    this.styleSheet?.deleteRule(0)
    this.styleSheet?.insertRule(`#donatello-ui { grid-template-columns: ${this.storeSidemenu.width}px auto; }`, 0)
  }

}
