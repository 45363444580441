import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { DataPipeline } from 'src/app/logic/data/pipeline.data';
import { ICase } from 'src/app/logic/interface/case';
import { IPipeline } from 'src/app/logic/interface/pipeline';

@Component({
  selector: 'app-modal-add-pipeline',
  templateUrl: './add-pipeline.component.html',
})

export class ModalAddPipelineComponent implements OnInit {

  public pipelineName = new FormControl('', [
    Validators.required,
  ]);

  constructor(
    public dialogRef: MatDialogRef<ModalAddPipelineComponent, IPipeline>,
    @Inject(MAT_DIALOG_DATA) public caseObj: ICase,
    private dataPipeline: DataPipeline) {
  }

  ngOnInit() { }

  onSubmit() {
    this.dataPipeline.addPipeline(this.caseObj.id, this.pipelineName.value).subscribe(
      (pipeline: IPipeline) => {
        this.dialogRef.close(pipeline);
      },
      (error: any) => {
        alert(error);
      }
    );
  }
}


