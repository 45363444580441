import { Component, OnInit, ViewChild, OnDestroy, ElementRef, Input } from '@angular/core';
import { ModelScroll } from 'src/app/directive/scrollbar/scroll.service';

@Component({
  selector: 'app-common-scrollbar',
  templateUrl: './scrollbar.component.html'
})

export class CommonScrollbarComponent implements OnInit, OnDestroy {

  @Input() index: number;

  @ViewChild('scrollbar', { static: true }) scrollbar: ElementRef;
  @ViewChild('scrollbarThumb', { static: true }) scrollbarthumb: ElementRef;

  public visibility = 'hidden';

  private parent: ElementRef;

  // つまみをつかんでいる状態かどうか
  private active = false;
  // スクロール域が短くて表示する必要がない場合false
  private short = false;
  // 領域内にマウスがあるかどうか
  private isMouseOn = false;

  constructor(private modelScroll: ModelScroll) { }

  ngOnInit() {
    // Serviceからのmouseup通知を受け取る
    this.modelScroll.mouseup.subscribe(
      () => {
        this.active = false;
        this.setVisibility(false);
      });
  }

  ngOnDestroy() { }

  public setOnMouse(isOn: boolean) {
    this.isMouseOn = isOn;
    this.setVisibility(isOn);
  }

  public setVisibility(isVisible: boolean) {
    // マウスが領域内
    if (this.isMouseOn && !this.short) {
      this.visibility = 'visible';
    } else if (isVisible && !this.short) {
      this.visibility = 'visible';
    } else if (!this.active) {
      this.visibility = 'hidden';
    }
  }

  public setParent(parent: ElementRef, scrollEvent: () => {}) {
    this.parent = parent;
    this.short = false;

    // 各要素を取得
    const scrollArea = this.parent.nativeElement as HTMLElement;
    const scrollBoxY = scrollArea.getElementsByClassName('scroll-y')[0] as HTMLElement;
    const scrollbarthumb = this.scrollbarthumb.nativeElement as HTMLElement;
    const scrollbarthumbStyle = document.getElementsByClassName('scrollbar-thumb')[0] as HTMLElement;

    // scrollbarの高さを設定
    const elements = document.getElementsByClassName('mat-dialog-container');
    if (elements.length > 0) {
      (elements[elements.length - 1] as HTMLElement).style.height = String(elements[elements.length - 1].clientHeight) + 'px';
    }
    const scrollAreaHeight = scrollArea.clientHeight;
    const scrollBoxYHeight = scrollBoxY.scrollHeight;

    if (scrollBoxYHeight <= scrollAreaHeight) {
      this.short = true;
      this.visibility = 'hidden';
      return;
    }

    const scrollbarHeight = scrollAreaHeight * scrollAreaHeight / scrollBoxYHeight;
    const scrollbarthumbTop = parseInt(window.getComputedStyle(scrollbarthumbStyle).getPropertyValue('top'));
    // scrollbarthumbの高さをtop位置の設定と連動して調整
    if (scrollbarthumbTop > 0) {
      scrollbarthumb.style.height = scrollbarHeight - (scrollbarthumbTop * 2) + 'px';
    } else {
      scrollbarthumb.style.height = scrollbarHeight + 'px';
    }

    // scrollに応じてscrollbarを移動
    let scrolltrack = scrollAreaHeight - scrollbarHeight;

    // scrollbarHeightがmin-heightよりも小さかったら移動距離をmin-height分減らす
    if (scrollbarHeight < 50) {
      scrolltrack = scrollAreaHeight - 60;
    } else if (scrollbarHeight > 50) {
      scrolltrack = scrollAreaHeight - scrollbarHeight;
    }

    scrollBoxY.onscroll = () => {
      if (scrollEvent !== undefined) {
        scrollEvent();
      }

      this.setVisibility(true);
      const offset = scrollBoxY.scrollTop * scrolltrack / (scrollBoxYHeight - scrollAreaHeight);
      scrollbarthumb.style.transform = 'translateY(' + offset + 'px)';
    };

    // scrollbarをmouseで移動
    let scrollbarthumbCursorY;
    const onMouseDown = (event) => {
      this.active = true;
      const rect = scrollbarthumb.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      scrollbarthumbCursorY = event.pageY - rect.top + scrollTop;

      // アクティブなスクロールバーを登録
      this.modelScroll.setScrollbar(
        this.parent,
        this.scrollbar,
        this.scrollbarthumb,
        scrolltrack,
        scrollbarthumbCursorY);
    };
    scrollbarthumb.onmousedown = onMouseDown;

    // つまみを操作中はテキスト選択できないようにする
    scrollArea.onselectstart = (_event) => {
      if (this.active) {
        return false;
      }
    };
  }

}
