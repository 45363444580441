import { Injectable } from '@angular/core';
import { Subject, interval } from 'rxjs';

import { ICase } from '../interface/case';
import { IDBParty } from '../interface/party';
import { IProject } from '../interface/project';
import { IPartyUser } from '../interface/user';

@Injectable({
  providedIn: 'root'
})
export class StoreSidemenu {

  /* Subject */
  private _addedProject = new Subject<void>();
  public addedProject = this._addedProject.asObservable();

  private _changeIsCollapsed = new Subject<boolean>();
  public changeIsCollapsed = this._changeIsCollapsed.asObservable();
  /* Subject */

  /* UI */
  private _elId = ''
  private _isCollapsed = false
  private _width = 264 // 仮
  /* UI */

  private _noParty: boolean | undefined
  private _parties: IDBParty[] = [];
  private _projects: IProject[] | undefined;

  private _selectedParty: IDBParty | null = null;
  private _selectedCase: ICase;

  private _settingProject: IProject;
  private _settingPartyUsers: IPartyUser[];
  private _settingProjectPartyUsers: IPartyUser[];
  private _settingPartyUsersCheckList: { [key: string]: boolean };
  private _settingPartyUsersOfNotJoinedProject: IPartyUser[];

  

  get noParty(): boolean | undefined {
    return this._noParty;
  }
  get parties(): IDBParty[] {
    return this._parties;
  }
  get selectedParty(): IDBParty | null {
    return this._selectedParty;
  }
  get projects(): IProject[] | undefined {
    return this._projects;
  }
  get selectedCase(): ICase | null {
    if (!this._selectedCase) {
      const json = localStorage.getItem('case')
      if (json) {
        this._selectedCase = JSON.parse(json)
      }
    }
    return this._selectedCase;
  }
  get settingProject(): IProject {
    return this._settingProject;
  }
  get settingPartyUsers(): IPartyUser[] {
    return this._settingPartyUsers;
  }
  get settingProjectPartyUsers(): IPartyUser[] {
    return this._settingProjectPartyUsers;
  }
  get settingPartyUsersCheckList(): { [key: string]: boolean } {
    return this._settingPartyUsersCheckList;
  }
  get settingPartyUsersOfNotJoinedProject(): IPartyUser[] {
    return this._settingPartyUsersOfNotJoinedProject;
  }
  get isCollapsed(): boolean {
    return this._isCollapsed;
  }
  get width(): number {
    return this._width;
  }

  set parties(val: IDBParty[]) {
    this._parties = val;
    this._noParty = val.length === 0
  }
  set selectedParty(val) {
    this._selectedParty = val;
  }
  set projects(val) {
    this._projects = val;
  }
  set selectedCase(val) {
    this._selectedCase = val;
    localStorage.setItem('case', JSON.stringify(val))
  }
  set settingProject(val) {
    this._settingProject = val;
  }
  set settingPartyUsers(val) {
    this._settingPartyUsers = val;
  }
  set settingProjectPartyUsers(val) {
    this._settingProjectPartyUsers = val;
  }
  set settingPartyUsersCheckList(val) {
    this._settingPartyUsersCheckList = val;
  }
  set settingPartyUsersOfNotJoinedProject(val) {
    this._settingPartyUsersOfNotJoinedProject = val;
  }
  set elId(val: string) {
    this._elId = val;
  }
  set isCollapsed(val) {
    this._isCollapsed = val
    const elm = document.getElementById(this._elId)
    setTimeout(() => {
      this._width = elm.offsetWidth
      this._changeIsCollapsed.next(val)
    }, 300) // animation time
  }

  constructor() { }

  getCase(caseId: string): ICase {
    const ref = interval(500).subscribe(() => {
      if (!this.projects) {
        return
      }
      ref.unsubscribe()
    })

    let target: ICase;
    for (let i = 0; i < this.projects.length; i++) {
      target = this.projects[i].cases.find((row) => row.id === caseId);
      if (target) {
        break;
      }
    }
    return target;
  }

  addProject(project: IProject) {
    this._projects.push(project);
    this._addedProject.next();
  }

  addCase(caseObj: ICase) {
    const project = this._projects.find((row) => row.id === caseObj.projectId)
    project.cases.push(caseObj);
  }

  deleteCase(caseObj: ICase) {
    const project = this._projects.find((row) => row.id === caseObj.projectId);
    const targetIndex = project.cases.findIndex((row) => row.id === caseObj.id);
    project.cases.splice(targetIndex, 1)
  }

}
