<div class="dn-case-container">
  <div class="dn-case-header">
    <div 
      *ngIf="case != undefined" 
      class="dn-case-header__info">
      <div class="dn-icon-case__la">
        <img *ngIf="case.imageUrl !== null" [src]="case.imageUrl">
      </div>
      <div class="dn-case-header__info__text">
        <span class="dn-case-header__info__title">
          {{case.name}}
        </span>
        <span class="dn-case-header__info__desc">
          {{case.description}}
        </span>
      </div>
      <button class="dn-case-header__info__btn-detail dn-btn-icon">
        <i class="ri-survey-line"></i>
      </button>
    </div>
    <div class="dn-case-header__right">
      <button class="dn-btn-details">
        <div class="dn-btn-details__summary_num">{{ getTotalFileCount() }}</div>
        <div class="dn-btn-details__divider"></div>
        <div class="dn-btn-details__pipeline_running">
          <i class="ri-run-line"></i>
          <span>{{ getTotalUncompleteCount() }}</span>
        </div>
        <div class="dn-btn-details__pipeline_done">
          <i class="ri-checkbox-circle-fill"></i>
          <span>{{ getTotalCompleteCount() }}</span>
        </div>
      </button>
      <button class="dn-btn-details">
        <div class="dn-btn-details__summary_num">8</div>
        <div class="dn-btn-details__divider"></div>
        <div class="dn-btn-details__case_member">
          <div class="dn-icon-user__xs">
            <img src="./../../../../../assets/img/user.jpg" alt="">
          </div>
          <div class="dn-icon-user__xs">
            <img src="./../../../../../assets/img/user2.jpg" alt="">
          </div>
          <div class="dn-icon-user__xs">
            <img src="./../../../../../assets/img/user3.jpg" alt="">
          </div>
        </div>
      </button>
      <button class="btn-main middle" (click)="onAddPipeline()">
        <i class="ri-add-fill"></i>
        パイプライン
      </button>
    </div>
  </div>

  <div class="dn-case-body scroll-box-x">
    <ng-container *ngFor="let pipeline of pipeLines">
      <div app-case-pipeline
        [pipeline]="pipeline"
        [case]="case"
        (deletePipeline)="onDeletePipeline($event)"
        class="dn-pipeline-container">
      </div>
    </ng-container>
  </div>
</div>

<!-- sidepeek -->
<!-- <div class="dn-sidepeek-container">
  <div>
  <button class="dn-btn-icon__border">
    <i class="rdi-modal-line"></i>
  </button>
  <button class="dn-btn-icon__border">
    <i class="ri-close-line"></i>
  </button>
  </div>
</div> -->