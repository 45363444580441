import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LibHttp } from '../lib/http.lib';
import { IProject } from 'src/app/logic/interface/project';

@Injectable({
  providedIn: 'root'
})
export class DataProject {

  constructor(private http: LibHttp) { }

  public addProject(partyId: string, name: string, description: string, fileType: string | null): Observable<{ project: IProject, imageUrl: string }> {
    const params = {
      partyId,
      name,
      description,
      fileType
    };
    return this.http.post('project', params);
  }

  public getProjects(partyId: string): Observable<IProject[]> {
    return this.http.get(`project`, { partyId });
  }
}
