<div class="layout-column c-between area-min-400px height-100">
  <div *ngIf="isImage(creative.fileType); else notImageTemplate">
    <img [src]="creative.url">
  </div>
  <ng-template #notImageTemplate>
    <div >
      <iframe [src]="fileUrl" style="width:800px; height:600px;" frameborder="0"></iframe>
    </div>
  </ng-template>
  
</div>
