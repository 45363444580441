import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LibLoading } from 'src/app/logic/lib/loading.lib';
import { ModelProject } from 'src/app/logic/model/project.model';

@Component({
  selector: 'app-modal-add-project',
  templateUrl: './add-project.component.html',
})

export class ModalAddProjectComponent implements OnInit {

  image: File;

  formGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
    ]),
    description: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<ModalAddProjectComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public projectId: number,
    private loading: LibLoading,
    private modelProject: ModelProject) { }

  ngOnInit() { }

  onSubmit() {
    this.loading.show();
    this.modelProject.addProject(this.formGroup.value.name, this.formGroup.value.description, this.image).subscribe(
      () => {
        this.loading.hide();
        this.dialogRef.close();
      },
      (error) => {
        this.loading.hide();
        alert('エラー');
      }
    );

  }

}


