import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddMaterialInputComponent } from './input/input.component';
import { ModelCreative } from 'src/app/logic/model/creative.model';

import { LibLoading } from 'src/app/logic/lib/loading.lib';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { IRequestPostCreative } from 'src/app/logic/interface/creative';
import { partyId } from 'src/app/logic/helper/util';

@Component({
  selector: 'app-modal-add-material',
  templateUrl: './add-material.component.html',
})

export class ModalAddMaterialComponent implements OnInit {

  // @ViewChildren('inputComponet') childs: ModalAddMaterialInputComponent[];
  @ViewChildren(AddMaterialInputComponent) public childs: QueryList<AddMaterialInputComponent>;

  // バリデート結果
  isValid = false;

  // アップロード
  uploadFiles: File[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalAddMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public storeSidemenu: StoreSidemenu,
    private modelCreative: ModelCreative,
    private loading: LibLoading,
  ) { }

  ngOnInit() {
  }

  /* Notice From FileUploadComponent */
  onSelectedFiles(files: File[]) {
    this.uploadFiles = files;
  }
  /* From FileUploadComponent */

  onChangeInputComponent() {
    setTimeout(() => {
      this.isValid = false;
      let notValidcount = 0;
      this.childs.forEach((child: AddMaterialInputComponent) => {
        if (!child.isValid()) {
          notValidcount += 1;
        }
      });
      if (notValidcount === 0) {
        this.isValid = true;
      }
    }, 100);
  }

  onSubmit() {
    this.loading.show();

    const fileInfos: IRequestPostCreative[] = [];
    const files: File[] = [];
    this.childs.forEach((child, i) => {
      fileInfos.push({
        partyId: partyId(),
        index: i,
        projectId: child.formGroup.value.projectId,
        fileName: child.formGroup.value.fileName,
        fileType: child.formGroup.value.fileType,
        fileSize: child.file.size,
        fileWidth: child.imageWidth,
        fileHeight: child.imageHeight,
      });
      files.push(child.file);
    });

    this.modelCreative.addCreatives(fileInfos, files).subscribe(
      (res) => {
        this.dialogRef.close(true);
        this.loading.hide();
      },
      (error) => {
        this.loading.hide();
        alert('アップロード失敗');
      });
  }
}


