import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, isDevMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { DragDropModule } from '@angular/cdk/drag-drop';

// Angular-File
import { ngfModule } from 'angular-file';

// Angular Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

// Root
import { AppComponent } from './component/root/app.component';
import { AppSideMenuComponent } from './component/root/side-menu/side-menu.component';
import { SideMenuProjectListComponent } from './component/root/side-menu/project-list/project-list.component';
import { ModalAddProjectComponent } from './component/modal/add-project/add-project.component';
import { ModalProjectSettingComponent } from './component/modal/project-setting/project-setting.component';
import { ModalAddCaseComponent } from './component/modal/add-case/add-case.component';
import { ModalAddPartyUserComponent } from './component/modal/add-party-user/add-party-user.component';
import { ModalAddProjectPartyUserComponent } from './component/modal/add-project-party-user/add-project-party-user.component';

// Login
import { LoginComponent } from './component/view/login/login.component';

// Signin
import { SigninComponent } from './component/view/signin/signin.component';

// Signup
import { SignupComponent } from './component/view/signup/signup.component';

// UserAuth
import { UserAuthComponent } from './component/view/user-auth/user-auth.component';

// Party
import { PartyComponent } from './component/view/party/party.component';

// Dashboard
import { DashboardComponent } from './component/view/dashboard/dashboard.component';

// Invitation
import { InvitationComponent } from './component/view/invitation/invitation.component';

// Case
import { CaseComponent } from './component/view/project/case/case.component';
// Pipeline
import { CasePipelineComponent } from './component/view/project/case/pipeline/pipeline.component';
import { CasePipelinePostAreaComponent } from './component/view/project/case/pipeline/post-area/post-area.component';
import { CasePipelinePostAreaToolbarComponent } from './component/view/project/case/pipeline/post-area/toolbar/toolbar.component';
import { CasePipelineMessageItemComponent } from './component/view/project/case/pipeline/message-item/message-item.component';

import { ModalAddPipelineComponent } from './component/modal/add-pipeline/add-pipeline.component';
import { ModalSelectMaterialComponent } from './component/modal/select-material/select-material.component';
import { ModalPostMaterialComponent } from './component/modal/post-material/post-material.component';
import { ModalFileDetailComponent } from './component/modal/file-detail/file-detail.component';

// Creative
import { CreativeListComponent } from './component/view/strage/creative-list/creative-list.component';
import { ModalAddMaterialComponent } from './component/modal/add-material/add-material.component';
import { AddMaterialInputComponent } from './component/modal/add-material/input/input.component';
import { ModalAddDirectoryComponent } from './component/modal/add-directory/add-directory.component';

/* Common */
import { FileUploadComponent } from './component/common/file-upload.component';
import { LoadingComponent } from './component/common/loading/loading.component';
import { CommonAlertComponent } from './component/common/alert/alert.component';
import { TextEditorComponent } from './component/view/project/case/pipeline/post-area/text-editor/text-editor.component';

// directive
import { MouseEventDirective } from './directive/mouse-event.directive';
import { ScrollbarDirective } from './directive/scrollbar/scrollbar.directive';
import { CommonScrollbarComponent } from './directive/scrollbar/scrollbar.component';
import { PopoverDirective } from './directive/popover/popover.directive';
import { CommonPopoverComponent } from './directive/popover/popover.component';
import { KeyEventDirective } from './directive/key-event.directive';
import { ImageDirective } from './directive/image.directive';

// Pipe
import { ImageSizePipe } from './pipe/image-size.pipe';
import { SanitizePipe } from './pipe/sanitize.pipe';

// other
import { BeforeunloadGuard } from './guard/beforeunload.guard';
import { AutofocusDirective } from './directive/autofocus.directive';
import { ApiRequestInterceptor } from './logic/lib/http.lib';
import { PartyTopComponent } from './component/view/party-top/party-top.component';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({ 
    // [設定必要]手動でComponentを作成する場合はここに追加
    declarations: [
        /* Component */
        // Root
        AppComponent, AppSideMenuComponent, SideMenuProjectListComponent,
        ModalAddProjectComponent, ModalProjectSettingComponent,
        ModalAddCaseComponent, ModalAddPartyUserComponent,
        ModalAddProjectPartyUserComponent,
        // Dashboard
        DashboardComponent,
        // Invitation
        InvitationComponent,
        // Case
        CaseComponent,
        // Pipeline
        CasePipelineComponent, CasePipelinePostAreaComponent, CasePipelinePostAreaToolbarComponent, CasePipelineMessageItemComponent,
        ModalAddPipelineComponent,
        ModalSelectMaterialComponent,
        ModalPostMaterialComponent,
        ModalFileDetailComponent,
        // Creative
        CreativeListComponent,
        ModalAddMaterialComponent, AddMaterialInputComponent, ModalAddDirectoryComponent,
        // Login
        LoginComponent,
        // Signin
        SigninComponent,
        // Signup
        SignupComponent,
        // UserAuth
        UserAuthComponent,
        // Party
        PartyComponent,
        PartyTopComponent,
        // Common
        FileUploadComponent,
        LoadingComponent,
        CommonAlertComponent,
        CommonScrollbarComponent,
        CommonPopoverComponent,
        TextEditorComponent,
        /* Directive */
        MouseEventDirective,
        AutofocusDirective,
        ScrollbarDirective,
        PopoverDirective,
        KeyEventDirective,
        ImageDirective,
        /* Pipe */
        ImageSizePipe,
        SanitizePipe,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule, ReactiveFormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        // File Upload
        ngfModule,
        // Angular Material
        MatDialogModule, MatFormFieldModule, MatInputModule,
        MatCardModule, MatSelectModule, MatButtonModule, MatSnackBarModule,
        MatTooltipModule, MatTreeModule, MatAutocompleteModule, MatProgressSpinnerModule,
        // CDK
        DragDropModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })], providers: [BeforeunloadGuard, { provide: HTTP_INTERCEPTORS, useClass: ApiRequestInterceptor, multi: true }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
