import { Injectable } from '@angular/core';

import { Observable, of, from, Subscriber } from 'rxjs';

import { concatMap, map } from 'rxjs/operators';
import { DataPipelineMessage } from '../data/pipeline-message.data';
import { IRequestPostCreative } from '../interface/creative';
import { IPipelineMessage, IPipelineMessageInputData } from '../interface/pipeline-message';
import { ModelCreative } from './creative.model';
import { partyId } from '../helper/util';

@Injectable({
  providedIn: 'root'
})
export class ModelPipeline {

  constructor(
    private dataPipelineMessage: DataPipelineMessage,
    private modelCreaetive: ModelCreative,
  ) { }

  public sendMessageWithCreative(caseId: string, inputData: IPipelineMessageInputData): Observable<IPipelineMessage> {
    const creatives = [
      {
        creativeId: inputData.creative.id,
        version: inputData.version,
      }
    ]
    return this.dataPipelineMessage.addPipelineMessage(caseId, inputData.pipelineId, inputData.message, creatives);
  }

  public sendMessageWithLocalImage(caseId: string, pipelineId: string, message: string, creatives: { creativeId: string, version: string }[]): Observable<IPipelineMessage> {
    return this.dataPipelineMessage.addPipelineMessage(caseId, pipelineId, message, creatives)
  }

  private getImageSize(file: File) {
    return new Observable<{ width: number, height: number }>(
      (observer: Subscriber<{ width: number, height: number }>) => {
        if (file && this.isImage(file.type)) {
          const image = new Image();
          image.src = URL.createObjectURL(file);
    
          image.onload = function () {
            const width = image.width;
            const height = image.height;
    
            observer.next({ width, height })
            observer.complete()
          };
        } else {
          observer.next({ width: 0, height: 0 })
          observer.complete()
        }
      },
    );
  }

  private isImage(fileType: string) {
    return fileType.indexOf('image') >= 0
  }

  public addCreatives(projectId: string, fileDatasets: { file: File, version: string, previewImage?: File }[]): Observable<{ creativeId: string, version: string }[]> {
    return new Observable<{ creativeId: string, version: string }[]>((observer) => {

      const returns: { creativeId: string, version: string }[] = []

      from(fileDatasets).pipe(
        // ファイルサイズ取得
        concatMap((fileData) => {
          const { file } = fileData
          return this.getImageSize(file)
        }),
        // creative登録
        concatMap((size, i) => {
          const { file, version, previewImage } = fileDatasets[i]
          const params: IRequestPostCreative = {
            partyId: partyId(),
            index: 0,
            projectId: projectId,
            fileName: file.name,
            fileType: file.type,
            fileSize: file.size,
            fileWidth: size.width,
            fileHeight: size.height,
          }
          return this.modelCreaetive.addCreative(params, file, previewImage).pipe(
            map((res) => {
              return {
                creativeId: res.id,
                version,
              }
            })
          )
        })
      ).subscribe({
        next: (res) => {
          console.log(res)
          returns.push(res)
        },
        complete: () => {
          console.log('complete')
          observer.next(returns)
          observer.complete()
        }
      })
 
    })
  }
}
