<div class="layout-column c-between height-100">
  <div class="layout-row i-center flex-shrink box-w-p20 height-50px">
    <p class="ft-size-6 ft-weight-bold">Upload</p>
    <button [mat-dialog-close]="true">
      <i class="jam jam-close close-icon ft-size-6"></i>
    </button>
  </div>
  <div class="layout-row box-w-p10 scroll-box-x">
    <!-- 初期 -->
    <app-file-upload *ngIf="uploadFiles.length === 0" (selectedFiles)="onSelectedFiles($event)" class="layout-block box-w-p10"></app-file-upload>
    
    <!-- 選択後 -->
    <ng-container>      
      <app-add-material-input
        *ngFor="let file of uploadFiles; index as i;"
        [file]="file"
        [projects]="storeSidemenu.projects"
        (changeValues)="onChangeInputComponent()"
        class="layout-block box-w-p10"
        [ngClass]="{'area-410px': uploadFiles.length === 1, 'area-300px area-min-200px': uploadFiles.length > 1}">
      </app-add-material-input>
    </ng-container>
  </div>
  <div class="layout-row i-center c-end flex-shrink box-w-p20 height-60px">
    <button class="btn-main middle area-80px" [ngClass]="{'disabled': !isValid}" [attr.disabled]="!isValid || null"
      (click)="onSubmit()">
      登録
    </button>
  </div>
</div>
