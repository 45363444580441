import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { StoreTextEditor } from './text-editor.store';

export type TFuncName = 'bold' | 'italic' | 'strike-through' | 'link' | 'order-list' | 'point-list' | 'quote' | 'code' | 'code-block'

const DEFAULT_INNER_HTML = '<div data-text-line><br></div>'
const DEFAULT_PLACEHOLDER = '投稿する...'
@Component({
  selector: '[app-text-editor]',
  templateUrl: './text-editor.component.html',
})
export class TextEditorComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() elementId: string;
  @Output() focused = new EventEmitter<void>();

  @ViewChild('rootRef', { static: false }) rootRef: ElementRef

  dummyHtml: string
  rootElement: HTMLElement
  dataPlaceholder = DEFAULT_PLACEHOLDER

  constructor(
    public el: ElementRef,
    private storeTextEditor: StoreTextEditor,
  ) {
  }

  ngOnInit() {
    this.dummyHtml = DEFAULT_INNER_HTML
  }

  ngAfterViewInit() {
    this.rootElement = document.getElementById(this.elementId)
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  onTabed(event: KeyboardEvent) {
    const selection = window.getSelection()
    const range = selection.getRangeAt(0)
    let targetLiElm: HTMLElement
    if (range.startContainer.nodeName === 'LI') {
      targetLiElm = range.startContainer as HTMLElement
    } else if (range.startContainer.parentElement.tagName === 'LI') {
      targetLiElm = range.startContainer.parentElement
    } else {
      const li = range.startContainer.parentElement.closest('li')
      if (li) {
        targetLiElm = li
      }
    }
    if (!targetLiElm) {
      return
    }
    console.log('li/ulタグ内でタブが入力されました', `Shift${event.shiftKey ? 'あり' : 'なし'}`,)

    let targetLiIndex = 0
    const matched = targetLiElm.classList.value.match(/indent-(\d+)/)
    if (matched !== null) {
      targetLiIndex = Number(matched[1])
    }
    if (event.shiftKey) {
      if (targetLiIndex === 0) {
        return
      }
      event.preventDefault()
      targetLiElm.classList.add(`indent-${targetLiIndex - 1}`)
      targetLiElm.classList.remove(`indent-${targetLiIndex}`)
    } else {
      event.preventDefault()
      targetLiElm.classList.add(`indent-${targetLiIndex + 1}`)
      targetLiElm.classList.remove(`indent-${targetLiIndex}`)
    }
  }

  onEntered() {
    console.log('onEntered')
    // const textArea = document.getElementById(this.elementId);
    // const divs = Array.from(textArea.children);
    // divs.forEach((div) => {
    //   const spans = Array.from(div.children);
    //   spans.forEach((spanElement) => {
    //     // styleしか持たない構造を統合する
    //     // <span style="..."><span style="...">...</span></span>
    //     if (spanElement.childNodes.length === 1 && spanElement.firstChild.nodeName === 'SPAN') {
    //       const childElement = spanElement.firstChild as HTMLElement;

    //       let parentStyle = spanElement.getAttribute('style');
    //       let childStyle = childElement.getAttribute('style');

    //       spanElement.setAttribute('style', parentStyle + childStyle);
    //       childElement.childNodes.forEach((row) => {
    //         spanElement.appendChild(row);
    //       });
    //       spanElement.removeChild(childElement);
    //     }
    //   });
    // });
  }

  onKeydown(event: KeyboardEvent) {
    this.updateButtonState()

    const selection = window.getSelection()
    const range = selection.getRangeAt(0)

    // 先頭で">"を入力した場合は引用
    if (range.commonAncestorContainer === range.startContainer && range.startOffset === 0) {
      if (event.key === '>') {
        this.createBlockquoteElement()
        return false
      }
    }


    if (this.storeTextEditor.isCode) {

      if (event.key === 'ArrowRight') {
        // 右端の場合は<code>を出る
        if (range.endOffset === range.endContainer.textContent.length) {
          const codeTag = range.endContainer.parentElement
          console.log(codeTag.nextSibling)
          if (!codeTag.nextSibling || codeTag.nextSibling.textContent.length === 0) {
            codeTag.insertAdjacentText('afterend', (' '));
          }
        }
      } else if (event.key === 'Enter') {
        // 制御が難しいので暫定的にcodeタグ内でEnter禁止にする
        return false
      }
    } else if (this.storeTextEditor.isCodeBlock) {
      if (event.key === 'ArrowDown') {
        if (range.commonAncestorContainer.nodeName === '#text') {
          if (range.commonAncestorContainer.parentElement.nextElementSibling === null) {
            range.commonAncestorContainer.parentElement.insertAdjacentHTML('afterend', DEFAULT_INNER_HTML)
          }
        } else if (range.commonAncestorContainer.nodeName === 'DIV') {
          if ((range.commonAncestorContainer as HTMLElement).nextElementSibling === null) {
            (range.commonAncestorContainer as HTMLElement).insertAdjacentHTML('afterend', DEFAULT_INNER_HTML)
          }
        }
      } else if (event.key === 'ArrowUp') {
        if (range.commonAncestorContainer.nodeName === '#text') {
          if (range.commonAncestorContainer.parentElement.previousElementSibling === null) {
            range.commonAncestorContainer.parentElement.insertAdjacentHTML('beforebegin', DEFAULT_INNER_HTML)
          }
        } else if (range.commonAncestorContainer.nodeName === 'DIV') {
          if ((range.commonAncestorContainer as HTMLElement).previousElementSibling === null) {
            (range.commonAncestorContainer as HTMLElement).insertAdjacentHTML('beforebegin', DEFAULT_INNER_HTML)
          }
        }
      }
    }
  }

  onKeyup(event: KeyboardEvent) {
    // this.updateButtonState()

    if (event.key === 'Backspace') {
      // 空になった場合は初期値のdivをセットする
      if ((this.rootElement.children.length === 0 && this.rootElement.childNodes.length === 0) || (this.rootElement.children.length === 1 && this.rootElement.children[0].tagName === 'BR')) {
        this.initEditor()
      }
    }
    const selection = window.getSelection();
    const selectedRange = selection.getRangeAt(0);
    if (selectedRange.commonAncestorContainer.nodeName === '#text') {
      if (this.isURL(selectedRange.commonAncestorContainer.textContent) && selectedRange.commonAncestorContainer.parentElement.tagName === 'DIV') {
        const { startOffset } = selectedRange
        const text = selectedRange.commonAncestorContainer.textContent
        selectedRange.commonAncestorContainer.textContent = ''
        
        const a = document.createElement('a')
        a.rel = 'noopener noreferrer'
        a.target = '_blank'
        a.textContent = text
        a.href = text

        selectedRange.insertNode(a)
        const newRange = document.createRange()
        newRange.setStart(a.firstChild, startOffset)
        selection.removeAllRanges()
        selection.addRange(newRange)
      } else if (selectedRange.commonAncestorContainer.textContent.indexOf(' ') < 0 && selectedRange.commonAncestorContainer.previousSibling?.nodeName === 'A'){
        const text = selectedRange.commonAncestorContainer.textContent
        selectedRange.commonAncestorContainer.textContent = ''
        const aTag = selectedRange.commonAncestorContainer.previousSibling as HTMLAnchorElement
        const newText = `${selectedRange.commonAncestorContainer.previousSibling.firstChild.textContent}${text}`
        aTag.firstChild.textContent = newText
        aTag.href = newText
      }
    }
    
    // else if (event.key === 'ArrowRight') {
    //   if (this.storeTextEditor.isCode) {
    //     // 右端の場合は<code>を出る
    //     const selection = window.getSelection();
    //     const range = selection.getRangeAt(0);
    //     if (range.endOffset === range.endContainer.textContent.length) {
    //       console.log('codeから抜ける')

    //     }
    //   }

    // }

    // this.storeTextEditor.onKeyup(event);
    // htmlを更新
    // const elm = document.getElementById(this.elementId);
    // if (elm === null) {
    //   return;
    // }
    setTimeout(() => {
      this.fixElements()
    }, 100);

    this.changeDataPlaceholder()
  }

  onMouseup() {
    this.updateButtonState()
  }

  onFocus() {
    this.updateButtonState()
  }

  onClick(event: MouseEvent) {
    // const selection = window.getSelection()
    // const range = selection.getRangeAt(0)
    // console.log(range.collapsed, range.startContainer.parentElement.tagName)

    // if (range.collapsed && range.startContainer.parentElement.tagName === 'A') {
    //   event.stopPropagation()
    //   event.preventDefault()

    //   console.log('click link')
    //   range.startContainer.parentElement.click()

    //   // event.prevent
    // }
  }

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData
    const pastedHtml = clipboardData.getData('text/html')
    const pastedText = clipboardData.getData('text/plain')
    if (pastedHtml.length > 0) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = pastedHtml

      const anchors = tempDiv.getElementsByTagName('a')
      if (anchors.length === 0) {
        return
      }

      event.preventDefault()

      for (let i = 0; i < anchors.length; i++) {
        const atag = anchors[i];
        // テキストとhrefだけ引き継いでaタグを挿入する(複数リンクには対応)
        this.storeTextEditor.insertLink(atag.text, atag.href)
      }
      
    } else {
      try {
        new URL(pastedText)
        event.preventDefault()
        this.storeTextEditor.insertLink(pastedText, pastedText)
      } catch { }
    }

    this.changeDataPlaceholder()
  }

  public doFunction(funcName: TFuncName) {
    console.log('doFunction', funcName)

    if (funcName === 'bold') {
      this.execBold()
    } else if (funcName === 'italic') {
      this.execItalic()
    } else if (funcName === 'strike-through') {
      this.execStrikeThrough()
    } else if (funcName === 'order-list') {
      this.createListElement('ol')
    } else if (funcName === 'point-list') {
      this.createListElement('ul')
    } else if (funcName === 'quote') {
      this.createBlockquoteElement()
    } else if (funcName === 'code') {
      this.createCodeElement()
    } else if (funcName === 'code-block') {
      this.createCodeBlockElement()
    }

    this.changeDataPlaceholder()
  }

  public setMessageContent(content: string) {
    (this.rootRef.nativeElement as HTMLElement).innerHTML = content
  }
  public getMessageContent() {
    return (this.rootRef.nativeElement as HTMLElement).innerHTML
  }

  public getMessageElement() {
    return this.rootRef.nativeElement as HTMLElement
  }

  public reset() {
    (this.rootRef.nativeElement as HTMLElement).innerHTML = DEFAULT_INNER_HTML
    this.changeDataPlaceholder()
  }

  private createListElement(tagName: 'ol' | 'ul') {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const ol = document.createElement(tagName)
    ol.classList.add(`editor-${tagName}`)
    const li = document.createElement('li')
    li.classList.add('indent-0')
    li.innerHTML = '&#8203;'
    ol.appendChild(li)
    range.insertNode(ol)
    range.selectNode(li)
    range.setStart(li, 1)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  private createBlockquoteElement() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const el = document.createElement('blockquote')
    const p = document.createElement('p')
    p.innerHTML = '&#8203;'
    el.appendChild(p)
    range.insertNode(el)
    range.selectNode(p)
    range.setStart(p, 1)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  private createCodeElement() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const el = document.createElement('code')
    el.innerHTML = '&#8203;'
    range.insertNode(el)
    range.selectNode(el)
    range.setStart(el, 1)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  private createCodeBlockElement() {
    const selection = window.getSelection()
    const range = selection.getRangeAt(0)

    // 何も入力されていない場合に限るので、検討必要
    const div = range.startContainer as HTMLElement
    div.classList.add('ql-code-block')
    range.setStart(div, 0)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  private execBold() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    // const cursorElement = range.commonAncestorContainer;

    // 範囲選択している状態
    if (!range.collapsed) {
      console.log('範囲選択')

      // 複数行選択
      if (this.isMultiLine(range)) {
        console.log('複数行選択')

        const startLineIndex = this.getLineIndex(range.startContainer)
        const endLineIndex = this.getLineIndex(range.endContainer)
        console.log(startLineIndex, endLineIndex)

        // Strongを解除
        if (this.storeTextEditor.isBold) {
          console.log('strongを解除します')

          const divElements = this.rootElement.children
          let startNode: Node
          let endNode: Node
          for (let i = startLineIndex; i <= endLineIndex; i++) {
            const newRange = document.createRange()
            if (i === startLineIndex) {

              const strong = range.startContainer.parentElement
              newRange.selectNodeContents(range.startContainer);
              newRange.setStart(range.startContainer, range.startOffset)

              if (newRange.startOffset > 0) {
                const newRange2 = document.createRange()
                newRange2.selectNodeContents(range.startContainer);
                newRange2.setEnd(range.startContainer, range.startOffset)
                const newStrongTag = document.createElement('strong')
                newRange2.surroundContents(newStrongTag)
                strong.before(newStrongTag)
              }
              // // 切りとってstrongタグの前に挿入
              const extracted = newRange.extractContents()
              strong.before(extracted)
              startNode = strong.previousSibling
              strong.remove()
            } else if (i === endLineIndex) {
              const strong = range.endContainer.parentElement
              newRange.selectNodeContents(range.endContainer);
              newRange.setEnd(range.endContainer, range.endOffset)
              // // 切りとってstrongタグの前に挿入
              const extracted = newRange.extractContents()
              strong.before(extracted)
              endNode = strong.previousSibling
            } else {
              const divElement = divElements[i]
              const strong = divElement.children[0]
              newRange.selectNodeContents(strong)
              // // 切りとってstrongタグの前に挿入
              const extracted = newRange.extractContents()
              strong.before(extracted)
              strong.remove()
            }
          }
          // 選択しなおす
          const newRange = document.createRange()
          newRange.setStart(startNode, 0)
          newRange.setEnd(endNode, endNode.textContent.length)
          const selection = window.getSelection()
          selection.removeAllRanges()
          selection.addRange(newRange)

        } else {
          // Strongにする
          console.log('strongにします')

          let start
          let end
          const divElements = this.rootElement.children
          for (let i = startLineIndex; i <= endLineIndex; i++) {
            const divElement = divElements[i];

            // 選択範囲を特定する
            const clonedRange = range.cloneRange();
            if (i === startLineIndex) {
              clonedRange.selectNodeContents(range.startContainer);
              clonedRange.setStart(range.startContainer, range.startOffset)
            } else if (i === endLineIndex) {
              clonedRange.selectNodeContents(range.endContainer);
              clonedRange.setEnd(range.endContainer, range.endOffset)
            } else {
              clonedRange.selectNodeContents(divElement.childNodes[0]);
            }

            // 選択範囲をstrongで置き換える
            const newStrongTag = document.createElement('strong')
            clonedRange.surroundContents(newStrongTag)

            if (i === startLineIndex) {
              start = newStrongTag
            } else if (i === endLineIndex) {
              end = newStrongTag
            }
          }
          // 選択しなおす
          range.setStart(start, 0)
          range.setEnd(end, end.innerText.length - 1)
          const selection = window.getSelection()
          selection.removeAllRanges()
          selection.addRange(range)

        }

      } else {
        console.log('1行選択')

        // Strongを解除
        if (this.storeTextEditor.isBold) {
          console.log('strongを解除します')

          const strong = range.startContainer.parentElement
          const clonedRange = range.cloneRange();
          if (range.startOffset > 0) {
            clonedRange.selectNodeContents(range.startContainer);
            clonedRange.setEnd(range.startContainer, range.startOffset)
            const newStrongTag = document.createElement('strong')
            clonedRange.surroundContents(newStrongTag)
            strong.before(newStrongTag)
          }
          // 切りとってstrongタグの前に挿入
          const extracted = range.extractContents()
          strong.before(extracted)
          // // 選択状態を復元
          range.selectNodeContents(strong.previousSibling)

        } else {
          // Strongにする
          console.log('strongにします')

          // strongタグで囲む
          const newStrongTag = document.createElement('strong')
          // range.surroundContents(newStrongTag)

          const clone = range.cloneContents()
          newStrongTag.appendChild(clone)
          // 範囲選択個所を削除して再追加
          range.deleteContents()
          range.insertNode(newStrongTag)

          // 同一タグが多階層になっている場合は修正する
          console.log(newStrongTag.innerHTML)
          if (newStrongTag.innerHTML.indexOf('<strong>') >= 0) {
            console.log('同一タグが階層化しています')
            newStrongTag.innerHTML = newStrongTag.innerHTML.replace(/<strong>/g, '').replace(/<\/strong>/g, '')
          }
          // strongタグを選択している状態に
          range.selectNodeContents(newStrongTag)

          // 後ろに同一タグがある場合はくっつける
          if (newStrongTag.nextElementSibling?.tagName === 'STRONG') {
            console.log('同一タグが連続しています')
            newStrongTag.append(newStrongTag.nextElementSibling.innerHTML)
            // newStrongTag.innerHTML += newStrongTag.nextElementSibling.innerHTML
            newStrongTag.nextElementSibling.remove()
          }
          // 前に同一タグがある場合はくっつける
          if (newStrongTag.previousElementSibling?.tagName === 'STRONG') {
            console.log('同一タグが連続しています(前)')
            newStrongTag.previousElementSibling.append(newStrongTag.innerHTML)
            newStrongTag.remove()
          }
        }
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)

        // this.storeTextEditor.isBold = !this.storeTextEditor.isBold
      }

    } else {
      // カーソルが当たっているだけの状態
      console.log('範囲選択ではない')

      if (this.storeTextEditor.isBold) {
        console.log('strongをOFFにします')
        const strong = range.startContainer.parentElement
        const clonedRange = range.cloneRange();
        if (range.startOffset > 0) {
          clonedRange.selectNodeContents(range.startContainer);
          clonedRange.setEnd(range.startContainer, range.startOffset)
          const newStrongTag = document.createElement('strong')
          clonedRange.surroundContents(newStrongTag)
          strong.before(newStrongTag)
        }
        const newSpanTag = document.createElement('span')
        newSpanTag.innerHTML = '&#8203;'
        strong.before(newSpanTag)
        range.selectNodeContents(strong.previousSibling)
        range.setStart(strong.previousSibling, 1)
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
      } else {
        console.log('strongをONにします')
        const newStrongTag = document.createElement('strong')
        range.surroundContents(newStrongTag)
        // strongタグにカーソルを当てることができないため"Zero-width space"を挿入しておく
        newStrongTag.innerHTML = '&#8203;'
        // 新しく挿入したstrongタグ内にカーソルをセットする
        this.setCursorToStrongTag(newStrongTag, 1)
      }
    }

    setTimeout(() => {
      this.fixElements()
    }, 100);
  }

  private execItalic() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    // const cursorElement = range.commonAncestorContainer;

    // 範囲選択している状態
    if (!range.collapsed) {
      console.log('範囲選択')

      // 複数行選択
      if (this.isMultiLine(range)) {
        console.log('複数行選択')

        const startLineIndex = this.getLineIndex(range.startContainer)
        const endLineIndex = this.getLineIndex(range.endContainer)
        console.log(startLineIndex, endLineIndex)

        // Strongを解除
        if (this.storeTextEditor.isItalic) {
          console.log('strongを解除します')

          const divElements = this.rootElement.children
          let startNode: Node
          let endNode: Node
          for (let i = startLineIndex; i <= endLineIndex; i++) {
            const newRange = document.createRange()
            if (i === startLineIndex) {

              const strong = range.startContainer.parentElement
              newRange.selectNodeContents(range.startContainer);
              newRange.setStart(range.startContainer, range.startOffset)

              if (newRange.startOffset > 0) {
                const newRange2 = document.createRange()
                newRange2.selectNodeContents(range.startContainer);
                newRange2.setEnd(range.startContainer, range.startOffset)
                const newStrongTag = document.createElement('em')
                newRange2.surroundContents(newStrongTag)
                strong.before(newStrongTag)
              }
              // // 切りとってstrongタグの前に挿入
              const extracted = newRange.extractContents()
              strong.before(extracted)
              startNode = strong.previousSibling
              strong.remove()
            } else if (i === endLineIndex) {
              const strong = range.endContainer.parentElement
              newRange.selectNodeContents(range.endContainer);
              newRange.setEnd(range.endContainer, range.endOffset)
              // // 切りとってstrongタグの前に挿入
              const extracted = newRange.extractContents()
              strong.before(extracted)
              endNode = strong.previousSibling
            } else {
              const divElement = divElements[i]
              const strong = divElement.children[0]
              newRange.selectNodeContents(strong)
              // // 切りとってstrongタグの前に挿入
              const extracted = newRange.extractContents()
              strong.before(extracted)
              strong.remove()
            }
          }
          // 選択しなおす
          const newRange = document.createRange()
          newRange.setStart(startNode, 0)
          newRange.setEnd(endNode, endNode.textContent.length)
          const selection = window.getSelection()
          selection.removeAllRanges()
          selection.addRange(newRange)

        } else {
          // Strongにする
          console.log('emにします')

          let start
          let end
          const divElements = this.rootElement.children
          for (let i = startLineIndex; i <= endLineIndex; i++) {
            const divElement = divElements[i];

            // 選択範囲を特定する
            const clonedRange = range.cloneRange();
            if (i === startLineIndex) {
              clonedRange.selectNodeContents(range.startContainer);
              clonedRange.setStart(range.startContainer, range.startOffset)
            } else if (i === endLineIndex) {
              clonedRange.selectNodeContents(range.endContainer);
              clonedRange.setEnd(range.endContainer, range.endOffset)
            } else {
              clonedRange.selectNodeContents(divElement.childNodes[0]);
            }

            // 選択範囲をstrongで置き換える
            const newStrongTag = document.createElement('em')
            clonedRange.surroundContents(newStrongTag)

            if (i === startLineIndex) {
              start = newStrongTag
            } else if (i === endLineIndex) {
              end = newStrongTag
            }
          }
          // 選択しなおす
          range.setStart(start, 0)
          range.setEnd(end, end.innerText.length - 1)
          const selection = window.getSelection()
          selection.removeAllRanges()
          selection.addRange(range)

        }

      } else {
        console.log('1行選択')

        // Strongを解除
        if (this.storeTextEditor.isItalic) {
          console.log('emを解除します')

          const strong = range.startContainer.parentElement
          const clonedRange = range.cloneRange();
          if (range.startOffset > 0) {
            clonedRange.selectNodeContents(range.startContainer);
            clonedRange.setEnd(range.startContainer, range.startOffset)
            const newStrongTag = document.createElement('em')
            clonedRange.surroundContents(newStrongTag)
            strong.before(newStrongTag)
          }
          // 切りとってstrongタグの前に挿入
          const extracted = range.extractContents()
          strong.before(extracted)
          // // 選択状態を復元
          range.selectNodeContents(strong.previousSibling)

        } else {
          // Strongにする
          console.log('emにします')

          // strongタグで囲む
          const newStrongTag = document.createElement('em')
          // range.surroundContents(newStrongTag)

          const clone = range.cloneContents()
          newStrongTag.appendChild(clone)
          // 範囲選択個所を削除して再追加
          range.deleteContents()
          range.insertNode(newStrongTag)

          // 同一タグが多階層になっている場合は修正する
          console.log(newStrongTag.innerHTML)
          if (newStrongTag.innerHTML.indexOf('<em>') >= 0) {
            console.log('同一タグが階層化しています')
            newStrongTag.innerHTML = newStrongTag.innerHTML.replace(/<em>/g, '').replace(/<\/em>/g, '')
          }
          // strongタグを選択している状態に
          range.selectNodeContents(newStrongTag)

          // 後ろに同一タグがある場合はくっつける
          if (newStrongTag.nextElementSibling?.tagName === 'EM') {
            console.log('同一タグが連続しています')
            newStrongTag.append(newStrongTag.nextElementSibling.innerHTML)
            // newStrongTag.innerHTML += newStrongTag.nextElementSibling.innerHTML
            newStrongTag.nextElementSibling.remove()
          }
          // 前に同一タグがある場合はくっつける
          if (newStrongTag.previousElementSibling?.tagName === 'EM') {
            console.log('同一タグが連続しています(前)')
            newStrongTag.previousElementSibling.append(newStrongTag.innerHTML)
            newStrongTag.remove()
          }
        }
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
      }

    } else {
      // カーソルが当たっているだけの状態
      console.log('範囲選択ではない')

      if (this.storeTextEditor.isItalic) {
        console.log('emをOFFにします')
        const strong = range.startContainer.parentElement
        const clonedRange = range.cloneRange();
        if (range.startOffset > 0) {
          clonedRange.selectNodeContents(range.startContainer);
          clonedRange.setEnd(range.startContainer, range.startOffset)
          const newStrongTag = document.createElement('em')
          clonedRange.surroundContents(newStrongTag)
          strong.before(newStrongTag)
        }
        const newSpanTag = document.createElement('span')
        newSpanTag.innerHTML = '&#8203;'
        strong.before(newSpanTag)
        range.selectNodeContents(strong.previousSibling)
        range.setStart(strong.previousSibling, 1)
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
      } else {
        console.log('emをONにします')
        const newStrongTag = document.createElement('em')
        range.surroundContents(newStrongTag)
        // strongタグにカーソルを当てることができないため"Zero-width space"を挿入しておく
        newStrongTag.innerHTML = '&#8203;'
        // 新しく挿入したstrongタグ内にカーソルをセットする
        this.setCursorToStrongTag(newStrongTag, 1)
      }
    }

    setTimeout(() => {
      this.fixElements()
    }, 100);
  }

  private execStrikeThrough() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    // const cursorElement = range.commonAncestorContainer;

    // 範囲選択している状態
    if (!range.collapsed) {
      console.log('範囲選択')

      // 複数行選択
      if (this.isMultiLine(range)) {
        console.log('複数行選択')

        const startLineIndex = this.getLineIndex(range.startContainer)
        const endLineIndex = this.getLineIndex(range.endContainer)
        console.log(startLineIndex, endLineIndex)

        // sを解除
        if (this.storeTextEditor.isStrikeThrough) {
          console.log('sを解除します')

          const divElements = this.rootElement.children
          let startNode: Node
          let endNode: Node
          for (let i = startLineIndex; i <= endLineIndex; i++) {
            const newRange = document.createRange()
            if (i === startLineIndex) {

              const strong = range.startContainer.parentElement
              newRange.selectNodeContents(range.startContainer);
              newRange.setStart(range.startContainer, range.startOffset)

              if (newRange.startOffset > 0) {
                const newRange2 = document.createRange()
                newRange2.selectNodeContents(range.startContainer);
                newRange2.setEnd(range.startContainer, range.startOffset)
                const newStrongTag = document.createElement('s')
                newRange2.surroundContents(newStrongTag)
                strong.before(newStrongTag)
              }
              // // 切りとってstrongタグの前に挿入
              const extracted = newRange.extractContents()
              strong.before(extracted)
              startNode = strong.previousSibling
              strong.remove()
            } else if (i === endLineIndex) {
              const strong = range.endContainer.parentElement
              newRange.selectNodeContents(range.endContainer);
              newRange.setEnd(range.endContainer, range.endOffset)
              // // 切りとってstrongタグの前に挿入
              const extracted = newRange.extractContents()
              strong.before(extracted)
              endNode = strong.previousSibling
            } else {
              const divElement = divElements[i]
              const strong = divElement.children[0]
              newRange.selectNodeContents(strong)
              // // 切りとってstrongタグの前に挿入
              const extracted = newRange.extractContents()
              strong.before(extracted)
              strong.remove()
            }
          }
          // 選択しなおす
          const newRange = document.createRange()
          newRange.setStart(startNode, 0)
          newRange.setEnd(endNode, endNode.textContent.length)
          const selection = window.getSelection()
          selection.removeAllRanges()
          selection.addRange(newRange)

        } else {
          // sにする
          console.log('sにします')

          let start
          let end
          const divElements = this.rootElement.children
          for (let i = startLineIndex; i <= endLineIndex; i++) {
            const divElement = divElements[i];

            // 選択範囲を特定する
            const clonedRange = range.cloneRange();
            if (i === startLineIndex) {
              clonedRange.selectNodeContents(range.startContainer);
              clonedRange.setStart(range.startContainer, range.startOffset)
            } else if (i === endLineIndex) {
              clonedRange.selectNodeContents(range.endContainer);
              clonedRange.setEnd(range.endContainer, range.endOffset)
            } else {
              clonedRange.selectNodeContents(divElement.childNodes[0]);
            }

            // 選択範囲をstrongで置き換える
            const newStrongTag = document.createElement('s')
            clonedRange.surroundContents(newStrongTag)

            if (i === startLineIndex) {
              start = newStrongTag
            } else if (i === endLineIndex) {
              end = newStrongTag
            }
          }
          // 選択しなおす
          range.setStart(start, 0)
          range.setEnd(end, end.innerText.length - 1)
          const selection = window.getSelection()
          selection.removeAllRanges()
          selection.addRange(range)

        }

      } else {
        console.log('1行選択')

        // Strongを解除
        if (this.storeTextEditor.isStrikeThrough) {
          console.log('sを解除します')

          const strong = range.startContainer.parentElement
          const clonedRange = range.cloneRange();
          if (range.startOffset > 0) {
            clonedRange.selectNodeContents(range.startContainer);
            clonedRange.setEnd(range.startContainer, range.startOffset)
            const newStrongTag = document.createElement('s')
            clonedRange.surroundContents(newStrongTag)
            strong.before(newStrongTag)
          }
          // 切りとってstrongタグの前に挿入
          const extracted = range.extractContents()
          strong.before(extracted)
          // // 選択状態を復元
          range.selectNodeContents(strong.previousSibling)

        } else {
          // Strongにする
          console.log('strongにします')

          // strongタグで囲む
          const newStrongTag = document.createElement('s')
          // range.surroundContents(newStrongTag)

          const clone = range.cloneContents()
          newStrongTag.appendChild(clone)
          // 範囲選択個所を削除して再追加
          range.deleteContents()
          range.insertNode(newStrongTag)

          // 同一タグが多階層になっている場合は修正する
          console.log(newStrongTag.innerHTML)
          if (newStrongTag.innerHTML.indexOf('<s>') >= 0) {
            console.log('同一タグが階層化しています')
            newStrongTag.innerHTML = newStrongTag.innerHTML.replace(/<s>/g, '').replace(/<\/s>/g, '')
          }
          // strongタグを選択している状態に
          range.selectNodeContents(newStrongTag)

          // 後ろに同一タグがある場合はくっつける
          if (newStrongTag.nextElementSibling?.tagName === 'S') {
            console.log('同一タグが連続しています')
            newStrongTag.append(newStrongTag.nextElementSibling.innerHTML)
            // newStrongTag.innerHTML += newStrongTag.nextElementSibling.innerHTML
            newStrongTag.nextElementSibling.remove()
          }
          // 前に同一タグがある場合はくっつける
          if (newStrongTag.previousElementSibling?.tagName === 'S') {
            console.log('同一タグが連続しています(前)')
            newStrongTag.previousElementSibling.append(newStrongTag.innerHTML)
            newStrongTag.remove()
          }
        }
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
      }

    } else {
      // カーソルが当たっているだけの状態
      console.log('範囲選択ではない')

      if (this.storeTextEditor.isStrikeThrough) {
        console.log('sをOFFにします')
        const strong = range.startContainer.parentElement
        const clonedRange = range.cloneRange();
        if (range.startOffset > 0) {
          clonedRange.selectNodeContents(range.startContainer);
          clonedRange.setEnd(range.startContainer, range.startOffset)
          const newStrongTag = document.createElement('s')
          clonedRange.surroundContents(newStrongTag)
          strong.before(newStrongTag)
        }
        const newSpanTag = document.createElement('span')
        newSpanTag.innerHTML = '&#8203;'
        strong.before(newSpanTag)
        range.selectNodeContents(strong.previousSibling)
        range.setStart(strong.previousSibling, 1)
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
      } else {
        console.log('sをONにします')
        const newStrongTag = document.createElement('s')
        range.surroundContents(newStrongTag)
        // strongタグにカーソルを当てることができないため"Zero-width space"を挿入しておく
        newStrongTag.innerHTML = '&#8203;'
        // 新しく挿入したstrongタグ内にカーソルをセットする
        this.setCursorToStrongTag(newStrongTag, 1)
      }
    }

    setTimeout(() => {
      this.fixElements()
    }, 100);
  }

  private isMultiLine(range: Range) {
    // if (range.cloneContents().children.length > 1 && range.cloneContents().children[0].nodeName === 'DIV') {
    if ((range.commonAncestorContainer as HTMLElement).id === this.elementId) {
      return true
    }
    return false
  }

  private getLineIndex(node: Node) {
    let element = node.parentElement.previousElementSibling
    if (node.parentElement.tagName !== 'DIV') {
      const lineRootDiv = node.parentElement.closest('div[data-text-line]')
      if (lineRootDiv) {
        element = lineRootDiv.previousElementSibling
      }
    }
    let lineIdx = 0
    while (element !== null) {
      element = element.previousElementSibling
      lineIdx += 1
    }
    return lineIdx
  }

  private setCursorToStrongTag(targetNode: Node, offset: number, limit?: number) {
    console.log('setCursorToStrongTag', targetNode, offset, limit)

    const range = document.createRange()
    const selection = window.getSelection()
    range.setStart(targetNode, offset)
    // if (limit !== undefined) {
    //   range.setEnd(targetNode, limit)
    // }
    // range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  private initEditor() {
    console.log('initEditor')
    for (let i = 0; i < this.rootElement.children.length; i++) {
      this.rootElement.children[i].remove()
    }
    const div = document.createElement('div')
    const br = document.createElement('br')
    div.appendChild(br)
    this.rootElement.appendChild(div)
    this.changeDataPlaceholder()
  }

  private isSameTagName(range: Range, tagName: 'STRONG' | 'EM' | 'S' ) {
    // 範囲未選択(カーソルが当たっているだけの状態)
    if (range.collapsed) {
      // TODO: 斜体や取消線が入ってくると親階層まで見る必要がある
      return range.startContainer.parentElement.tagName === tagName || (range.startContainer as HTMLElement).tagName === tagName
    }
    if ((range.commonAncestorContainer as HTMLElement).tagName === tagName) {
      return true
    }
    // 同要素内
    if ((range.commonAncestorContainer as HTMLElement).tagName !== 'DIV') {
      return range.startContainer.parentElement.tagName === tagName
    }
    // 要素をまたぐ場合は全ての要素がstrong内か判定する
    if (this.isMultiLine(range)) {
      console.log('TODO: 複数行選択中')
      let isStrong = true
      for (let i = 0; i < range.cloneContents().children.length; i++) {
        const element = range.cloneContents().children[i]
        if (element.children.length === 1 && element.children[0].tagName === tagName && element.children[0].textContent === element.textContent) {
        } else {
          isStrong = false
        }
        if (!isStrong) break
      }
      return isStrong
    } else {
      console.log('1行内で複数要素選択中')
      // <strong>aa</strong><strong>bb</strong>のようなstrongが続くような構造は存在しないようにするため
      return false
    }
  }

  private isCode(range: Range) {
    if (range.startContainer.parentElement.tagName === 'CODE' || (range.startContainer as HTMLElement).tagName === 'CODE') {
      return true
    }
    return false
  }

  private isCodeBlock(range: Range) {
    if (((range.startContainer as HTMLElement).tagName === 'DIV' && (range.startContainer as HTMLElement).classList.contains('ql-code-block'))
      || range.startContainer.parentElement.classList.contains('ql-code-block')) {
      return true
    }
    return false
  }

  private updateButtonState() {
    const selection = window.getSelection()
    const range = selection.getRangeAt(0)
    this.storeTextEditor.isBold = this.isSameTagName(range, 'STRONG')
    this.storeTextEditor.isItalic = this.isSameTagName(range, 'EM')
    this.storeTextEditor.isStrikeThrough = this.isSameTagName(range, 'S')
    this.storeTextEditor.isCode = this.isCode(range)
    this.storeTextEditor.isCodeBlock = this.isCodeBlock(range)
    this.storeTextEditor.lastRange = range
  }

  private fixElements() {
    const ZERO_WIDTH_SPACES_REGEX = /([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g;

    const selection = window.getSelection()
    const range = selection.getRangeAt(0)

    for (let i = 0; i < this.rootElement.children.length; i++) {
      const lineRootDiv = this.rootElement.children[i];
      lineRootDiv.childNodes.forEach((childNode) => {
        if ((childNode as HTMLElement).tagName === 'SPAN' && range.startContainer.nodeName !== 'SPAN' && range.startContainer.parentElement.nodeName !== 'SPAN') {
          if (childNode.textContent.match(ZERO_WIDTH_SPACES_REGEX)) {
            childNode.textContent = childNode.textContent.replace(ZERO_WIDTH_SPACES_REGEX, '')
          }
          if (childNode.textContent.length === 0) {
            childNode.remove()
          }
        }
      })
    }

  }

  private isURL(str: string) {
    try {
      new URL(str)
      return true
    } catch {
      return false
    }

    
  }

  private changeDataPlaceholder() {
    const textContent = (this.rootRef.nativeElement as HTMLElement).textContent
    if (textContent.length > 0) {
      this.dataPlaceholder = ''
    } else {
      this.dataPlaceholder = DEFAULT_PLACEHOLDER
    }
  }

}
