import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebpushService {

  private audio: HTMLAudioElement

  constructor() { 
    if ('Notification' in window) {
      Notification.requestPermission().then(permission => {
        if (permission !== 'granted') {
          console.error('Permission not granted for Notification');
        }
      });
    } else {
      console.error('Notification API not supported');
    }
  }

  setAudio() {
    this.audio = new Audio('assets/sounds/webpush.mp3');
  }

  showNotification(title: string, options?: NotificationOptions): void {
    console.log('showNotification')
    if (Notification.permission === 'granted') {
      const notification = new Notification(title, options);
      this.playNotificationSound();

      notification.onclick = (event) => {
        console.log('onClick', event)
        // event.preventDefault(); // 通知クリック時の既定の動作をキャンセル
        // if (options && options.data && options.data.url) {
        //   window.open(options.data.url, '_blank'); // 指定されたURLを新しいタブで開く
        // }
      };
    } else {
      console.error('Notifications are not permitted');
    }
  }

  private playNotificationSound(): void {
    if (this.audio) {
      this.audio.play();
    }
    
  }
}
