import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPartyUser } from '../interface/user';

import { LibHttp } from '../lib/http.lib';

@Injectable({
  providedIn: 'root'
})
export class DataPartyUser {

  constructor(private http: LibHttp) { }

  public addPartyUser(partyId: string, emails: string[]): Observable<string> {
    const params = {
      partyId,
      emails
    };
    return this.http.post('invitation', params);
  }

  public addProjectPartyUser(partyId: string, projectId: string, partyUserIds: string[]): Observable<string> {
    const params = {
      partyId,
      projectId,
      partyUserIds
    };
    return this.http.post('project/user', params);
  }

  public getPartyUsersByPartyIdOrProjectId(partyId: string, projectId?: string): Observable<IPartyUser[]> {
    const params = {
      partyId,
      projectId,
    };
    return this.http.get('party/user', params);
  }

}
