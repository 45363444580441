import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';

// [設定必要]URL呼び出しが必要なcomponentを読み込む
import { LoginComponent } from './component/view/login/login.component';
import { SigninComponent } from './component/view/signin/signin.component';
import { SignupComponent } from './component/view/signup/signup.component';
import { UserAuthComponent } from './component/view/user-auth/user-auth.component';
import { PartyComponent } from './component/view/party/party.component';
import { DashboardComponent } from './component/view/dashboard/dashboard.component';
import { CaseComponent } from './component/view/project/case/case.component';
import { CreativeListComponent } from './component/view/strage/creative-list/creative-list.component';
import { InvitationComponent } from './component/view/invitation/invitation.component';
import { AuthGuard } from './guard/auth.guard';
import { PartyTopComponent } from './component/view/party-top/party-top.component';
import { InitializationGuard } from './guard/Initialization.guard';

export interface IPage extends Route {
  path: string
  // menu: string
  showSidemenu: boolean; // サイドメニューを表示するかどうか
  // regExp?: string;
  // backPath?: string;
}

type IPages = IPage[]

// [設定必要]パスを設定
export const pages: IPages = [
  { path: 'signin', component: SigninComponent, showSidemenu: false },
  { path: 'signup', component: SignupComponent, showSidemenu: false },
  { path: 'user-auth', component: UserAuthComponent, showSidemenu: false },
  { path: 'login', component: LoginComponent, showSidemenu: false },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, InitializationGuard], showSidemenu: false },
  { path: 'invitation/:code', component: InvitationComponent, canActivate: [AuthGuard], showSidemenu: false },
  { path: 'party/:partyId', component: PartyTopComponent, showSidemenu: true, canActivate: [AuthGuard, InitializationGuard] },
  { path: 'party', component: PartyComponent, showSidemenu: false, canActivate: [AuthGuard] },
  { path: 'party/:partyId/project/:pid/case/:cid', component: CaseComponent, showSidemenu: true, canActivate: [AuthGuard, InitializationGuard] },
  { path: 'party/:partyId/storage', component: CreativeListComponent, showSidemenu: true, canActivate: [AuthGuard, InitializationGuard] },
  { path: 'party/:partyId/creative/:dirid', component: CreativeListComponent, showSidemenu: true, canActivate: [AuthGuard, InitializationGuard] }
];

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
]
pages.forEach((row) => {
  routes.push(row);
})

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
