import { Injectable } from '@angular/core';

import { concatMap, map, Observable, of } from 'rxjs';

import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { DataParty } from '../data/party.data';
import { DataProject } from '../data/project.data';
import { IPostedParty } from 'src/app/logic/interface/party';

@Injectable({
  providedIn: 'root'
})
export class ModelParty {

  constructor(
    private dataParty: DataParty,
    private dataProject: DataProject,
    private storeSidemenu: StoreSidemenu,
  ) { }

  public getParties(): Observable<void> {
    return this.dataParty.getParties().pipe(
      map((list) => {
        this.storeSidemenu.parties = list;
      }),

    );
  }

  /**
   * 
   * @returns PartyとProjectの一覧を取得
   */
  // public getPartiesAndProjects(): Observable<void> {
  //   return this.dataParty.getParties().pipe(
  //     map((list) => {
  //       this.storeSidemenu.parties = list;
  //     }),
  //     concatMap(() => {
  //       return this.dataProject.getProjects().pipe(
  //         map((projects) => {
  //           this.storeSidemenu.projects = projects;
  //         }),
  //       );
  //     }),
  //   );
  // }

  public postParty(name: string, partyUserName: string): Observable<IPostedParty> {
    return this.dataParty.postParty(name, partyUserName)
  }

}
