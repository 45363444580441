import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IProject } from 'src/app/logic/interface/project';
import { ICase } from 'src/app/logic/interface/case';
import { ModalProjectSettingComponent } from '../../../modal/project-setting/project-setting.component';
import { ModalAddCaseComponent } from '../../../modal/add-case/add-case.component';
import { ModalAddProjectComponent } from '../../../modal/add-project/add-project.component';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { DataCase } from 'src/app/logic/data/case.data';
import { LibAlert } from 'src/app/logic/lib/alert.lib';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataProject } from 'src/app/logic/data/project.data';
import { partyId } from 'src/app/logic/helper/util';

@Component({
  selector: '[app-side-menu-project-list]',
  templateUrl: './project-list.component.html'
})

export class SideMenuProjectListComponent implements OnInit {

  isCollapsedProjects = false;
  isCollapsedCases: boolean[] = [];

  constructor(
    public storeSidemenu: StoreSidemenu,
    private router: Router,
    private dialog: MatDialog,
    private alert: LibAlert,
    private dataCase: DataCase,
    private snackBar: MatSnackBar,
    private dataProject: DataProject,
  ) {
  }

  ngOnInit() {
    this.dataProject.getProjects(this.storeSidemenu.selectedParty.id).subscribe((projects) => {
      this.storeSidemenu.projects = projects;
      this.isCollapsedCases = [...Array(this.storeSidemenu.projects.length)].map((_, i) => false);
    })

    this.storeSidemenu.addedProject.subscribe(() => {
      this.isCollapsedCases.push(false);
    })

  }

  onAddProject() {
    this.dialog.open(ModalAddProjectComponent, {
      maxHeight: '90vh',
    });
  }

  onClickCase(project: IProject, caseObj: ICase) {
    this.storeSidemenu.selectedCase = caseObj;
    this.router.navigate([`party/${partyId()}/project/${project.id}/case/${caseObj.id}/`]);
  }

  onProjectSetting(project: IProject) {
    this.storeSidemenu.settingProject = project;
    const dialogRef = this.dialog.open(ModalProjectSettingComponent, {
      maxHeight: '90vh',
    });
    dialogRef.afterClosed().subscribe(_result => {
    });
  }

  onAddCase(projectId: string) {
    this.dialog.open(ModalAddCaseComponent, {
      maxHeight: '90vh',
      data: projectId,
    });
  }

  onDeleteCase(caseObj: ICase) {
    const ref = this.alert.showConfirm('Case削除', '本当に削除しますか？', '削除する', '削除しない');
    ref.afterClosed().subscribe((result) => {
      if (result === true) {
        this.dataCase.deleteCase(partyId(), caseObj.id).subscribe({
          next: () => {
            this.storeSidemenu.deleteCase(caseObj);
            this.snackBar.open('削除しました。', 'OK', {
              verticalPosition: 'top',
              duration: 3000
            });
          },
          error: (e) => {
            this.alert.showNotice('エラー', '削除できませんでした', '閉じる')
          }
        });
      }
    });
  }
}
