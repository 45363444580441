// Creative種別
export enum ECreativeType {
  FILE = 'file',
  DIRECTORY = 'directory'
}

export enum EAuthority {
  ADMIN = 1,
}


