<div 
  class="dn-postarea-container"
  [class.focus]="isInputFocus">
  <!-- toolbar -->
  <div
    app-case-pipeline-post-area-toolbar
    class="dn-postarea-toolbar"
    (clickedFunc)="editorComponent.doFunction($event)"></div>
  <!-- editor -->
  <div class="scroll-container" appScrollbar>
    <div class="scroll-y" #inputElement>
      <div app-text-editor
        [elementId]="pipeline.id">
      </div>
    </div>
    <!-- file area -->
    <div class="layout-row">
      <div *ngFor="let data of fileDataList">
        <div class="img-box-50">
          <img
            *ngIf="isImage(data.file.type); else templateIsNotImage"
            appImage [file]="data.file" #image>
        </div>
        <ng-template #templateIsNotImage>
          <div [ngSwitch]="fileType(data.file.type)">
            <ng-container *ngSwitchCase="'pdf'">
              PDF
            </ng-container>
            <ng-container *ngSwitchCase="'ppt'">
              PPT/PPTX
            </ng-container>
            <ng-container *ngSwitchCase="'xls'">
              XLS/XLSX
            </ng-container>
            <ng-container *ngSwitchDefault>
              未判定のファイル形式
            </ng-container>
          </div>
        </ng-template>
        <div>
          {{data.file.name}}
        </div>
        <div>
          <input [ngModel]="data.version" type="text" >
        </div>
      </div>
    </div>
  </div>
  <!-- footer -->
  <div class="dn-postarea-footer">
    <button
      class="dn-btn-icon__sm">
      <i class="ri-draft-line"></i>
    </button>
    <button 
      rdPopover
      [bodyTemplate]="attachmentItemMenu"
      [trigger]="'click'"
      [placement]="'top'"
      [alignX]="'left'"
      #modal="rdPopover"
      class="dn-btn-icon__sm">
      <i class="ri-attachment-2"></i>
      <ng-template #attachmentItemMenu>
        <div class="dn-pop-menu">
          <div class="dn-pop-menu__item">
            <button
              class="dn-btn-text"
              (click)="onSelectLocalFile(); modal.hide()">
              <i class="ri-macbook-line"></i>
              Local file
            </button>
          </div>
          <div class="dn-pop-menu__item">
            <button
              class="dn-btn-text"
              (click)="onSelectMaterial(); modal.hide()">
              <i class="ri-database-2-line"></i>
              クリエイティブから
            </button>
          </div>
        </div>
      </ng-template>
    </button>
    <button class="dn-btn-icon__sm">
      <i class="ri-emotion-line"></i>
    </button>
    <div class="dn-postarea-footer__divider"></div>
    <button
      class="dn-btn-icon__sm"
      (click)="onSendMessage()">
      <i class="ri-send-plane-2-fill"></i>
    </button>
  </div>
</div>