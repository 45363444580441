import { Injectable } from '@angular/core';

import { from, Observable, Subscriber, map, of } from 'rxjs';

import { concatMap, concatWith } from 'rxjs/operators';
import { DataCreative } from '../data/creative.data';
import { ICreative, IRequestPostCreative, IResponsePostCreative } from '../interface/creative';

@Injectable({
  providedIn: 'root'
})
export class ModelCreative {

  constructor(private dataCreative: DataCreative) { }

  public addCreatives(creatives: IRequestPostCreative[], files: File[]): Observable<void> {
    return new Observable<void>((observer: Subscriber<void>) => {
      from(creatives).pipe(
        // mergeMapの方がいいか
        concatMap((postParams, i) => {
          return this.addCreative(postParams, files[i]);
        }),
      ).subscribe({
        complete: () => {
          observer.next();
          observer.complete();
        },
        error: (e) => {
          console.error(e);
          observer.error(e);
        },
      });
    },
    );
  }

  public addCreative(params: IRequestPostCreative, file: File, previewImage?: File): Observable<IResponsePostCreative> {
    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {

      return this.dataCreative.addCreativeWithFile({ ...params, file }).pipe(
        concatMap((responsePostCreatives) => {
          // 取得したurlにアップロードする
          return this.upload(responsePostCreatives.url, file).pipe(
            map(() => {
              return responsePostCreatives
            })
          )
        }),
      )

    } else {
      return this.dataCreative.addCreative(params).pipe(
        concatMap((responsePostCreatives) => {
            // 取得したurlにアップロードする
            return this.upload(responsePostCreatives.url, file).pipe(
              concatMap(() => {
                if (previewImage && responsePostCreatives.previewImageUrl !== null) {
                  // プレビュー画像をアップロード(pdfファイルの場合)
                  return this.upload(responsePostCreatives.previewImageUrl, previewImage).pipe(
                    map(() => {
                      return responsePostCreatives;
                    }),
                  )
                } else {
                  return of(responsePostCreatives)
                }
              }),
            )
        }),
      )

    }
  }

  public addDirectory(projectId: string, parentId: string | null, name: string): Observable<ICreative> {
    return this.dataCreative.addDirectory({ projectId, parentId, name });
  }

  private upload(url: string, file: File) {
    // 取得したurlにアップロードする
    return new Observable<void>(
      (observer: Subscriber<void>) => {
        fetch(url, {
          method: 'PUT',
          mode: 'cors',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        }).then(res => {
          observer.next();
          observer.complete();
        }).catch(error => {
          observer.error(error);
        });
      },
    );
  }

}
