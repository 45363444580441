import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalAddMaterialComponent } from '../../../modal/add-material/add-material.component';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { CommonCreativeListComponent } from 'src/app/component/common/creative-list.component';
import { DataCreative } from 'src/app/logic/data/creative.data';
import { ICreative } from 'src/app/logic/interface/creative';
import { ECreativeType } from 'src/app/logic/definition/enum';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalAddDirectoryComponent } from 'src/app/component/modal/add-directory/add-directory.component';
import { interval } from 'rxjs';
import { partyId } from 'src/app/logic/helper/util';

@Component({
  selector: 'app-creative-list',
  templateUrl: './creative-list.component.html',
  styleUrls: ['./creative-list.component.css'],
})
export class CreativeListComponent extends CommonCreativeListComponent implements OnInit {

  public isDragOver = false;

  constructor(
    public storeSidemenu: StoreSidemenu,
    public dataCreative: DataCreative,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super(storeSidemenu, dataCreative);
  }

  ngOnInit() {
    // const dirId = this.activatedRoute.snapshot.params.dirid;
    this.activatedRoute.params.subscribe((params) => {
      if (params.dirid) {
        this.dirId = params.dirid;
      }

      // Projectが取得されるまで待つ
      const sub = interval(500).subscribe(() => {
        if (this.storeSidemenu.projects !== undefined) {
          sub.unsubscribe()

          this.getCreatives();
          if (this.dirId) {
            this.getDirectory();
          }
        }
      })
    });
  }

  onAddDirectory() {
    const dialogRef = this.dialog.open(ModalAddDirectoryComponent, {
      maxHeight: '90vh',
      data: this.directory,
    });
    dialogRef.afterClosed().subscribe((creative?: ICreative) => {
      if (creative) {
        // TODO: とりあえず末尾に追加
        this.creatives.push(creative);
      }
    });
  }

  onAddMaterial() {
    const dialogRef = this.dialog.open(ModalAddMaterialComponent, {
      maxHeight: '90vh',
    });
    dialogRef.afterClosed().subscribe((update: boolean) => {
      if (update) {
        this.getCreatives();
      }
    });
  }

  onDblClick(creative: ICreative) {
    if (creative.type !== ECreativeType.DIRECTORY) {
      return;
    }
    this.router.navigate([`party/${partyId()}/creative/${creative.id}`]);
  }

  dragEvent(event, name) {
    console.log(name);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();

    if (!this.isDragOver) {
      this.isDragOver = true;
    }
  }

  onMouseLeave(event: MouseEvent) {
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
  }
}
