<div class="layout-column c-between height-100">
  <div class="layout-row i-center flex-shrink box-w-p20 height-50px">
    <p class="ft-size-6 ft-weight-bold">Pipeline</p>
    <button [mat-dialog-close]="true">
      <i class="jam jam-close close-icon ft-size-6"></i>
    </button>
  </div>
  <div class="box-w-p20">
    <div class="layout-column area-410px">
      <span class="color-gray1 ft-size-1">パイプライン名</span>
      <input class="mt-4" [formControl]="pipelineName">
    </div>
  </div>
  <div class="layout-row i-center c-end flex-shrink box-w-p20 box-h-p15">
    <button
      class="btn-main middle area-80px"
      [ngClass]="{'disabled': !pipelineName.valid}"
      [attr.disabled]="!pipelineName.valid || null"
      (click)="onSubmit()">
      作成
    </button>
  </div>
</div>
