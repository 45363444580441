import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { ModalAddPartyUserComponent } from '../../modal/add-party-user/add-party-user.component';
import { IDBParty } from 'src/app/logic/interface/party';

@Component({
  selector: '[app-root-side-menu]',
  templateUrl: './side-menu.component.html'
})

export class AppSideMenuComponent implements OnInit, AfterViewInit {

  constructor(
    public storeSidemenu: StoreSidemenu,
    private dialog: MatDialog,
    private el: ElementRef
  ) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.storeSidemenu.elId = this.el.nativeElement.id
  }

  

  onAddPartyUser() {
    this.dialog.open(ModalAddPartyUserComponent, {
      maxHeight: '90vh',
    });
  }

  onClickMenu() {
    this.storeSidemenu.selectedCase = null
  }

  onSwitchParty(party: IDBParty) {
    location.href = `/party/${party.id}`
  }

  onChangeCollapsedSidebar() {
    this.storeSidemenu.isCollapsed = !this.storeSidemenu.isCollapsed
  }

}
