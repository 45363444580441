<div class="dn-pipeline-header">
  <div class="dn-pipeline-header__title">{{pipeline.name}}</div>
  <div class="dn-pipeline-header__right">
    <button 
      rdPopover
      [bodyTemplate]="fileHistory"
      [trigger]="'click'"
      [placement]="'bottom'"
      [alignX]="'left'"
      #modal="rdPopover"
      class="dn-btn-details__xs" 
      (click)="onDisplayHistory()">
      <div class="dn-btn-details__summary_num">{{ pipeline.fileCount }}</div>
      <div class="dn-btn-details__divider"></div>
      <div class="dn-btn-details__text">Files</div>
    </button>
    <ng-template #fileHistory>
      <div class="dn-pop-menu">
        <div 
          *ngFor="let set of fileSets"
          class="dn-pipeline-files__item">
          <!-- 最新バージョンのファイル -->
          <div class="dn-pipeline-files__item__latest">
            <div class="dn-file-thumb__xs">
              <img src="../../../../../../assets/img/cam_thumb.jpg">
            </div>
            <a class="dn-tag__v_latest dn-pipeline-files__tag">{{ set.root.version }}</a>
            <div class="dn-pipeline-files__title">{{ set.root.name }}</div>
          </div>
          <!-- 過去のファイル群 -->
          <div 
            *ngIf="set.history.length > 0"
            class="dn-pipeline-files__tags">
            <a 
              *ngFor="let file of set.history"
              class="dn-tag__v dn-tag__sm">
              {{ file.version }}
            </a>
          </div>
        </div>
      </div>
    </ng-template>
    <button 
      class="dn-btn-text__border__xs" 
      (click)="onSwitchStatus()">
      {{ pipeline.isComplete? '戻す' : '完了' }}
    </button>
    <button 
      rdPopover
      [bodyTemplate]="pipelineMenu"
      [trigger]="'click'"
      [placement]="'bottom'"
      [alignX]="'right'"
      #modal="rdPopover"
      class="dn-btn-icon">
      <i class="ri-more-fill"></i>
      <ng-template #pipelineMenu>
        <div class="dn-pop-menu">
          <div class="dn-pop-menu__item">
            <button
              class="dn-btn-text"
              (click)="onDeletePipeline()">
              pipelineを削除
            </button>
          </div>
        </div>
      </ng-template>
    </button>
  </div>
</div>
<div
  class="dn-pipeline-body scroll-container"
  appScrollbar [changeLayout]="changeLayout.asObservable()"
  (dragover)="onDragOver($event)">
  <div class="scroll-y" #scrollArea (scroll)="onScrolled($event)">
    <div
      *ngIf="pipeline.messages.length === 0"
      class="dn-pipeline-item">
      ファイルやメッセージが投稿されていません。
    </div>
    <ng-container *ngFor="let message of pipeline.messages">
      <div 
        app-case-pipeline-message-item
        [pipeline]="pipeline"
        [case]="case"
        [message]="message"
        rdPopover
        [bodyTemplate]="sentItemMenu"
        [trigger]="'hover'"
        [placement]="'top'"
        [alignX]="'right'"
        #modal="rdPopover"
        class="dn-pipeline-item">
        <ng-template #sentItemMenu>
          <div class="dn-pipeline-item__menu">
            <button class="dn-btn-icon">
              <i class="ri-emotion-line"></i>
            </button>
            <button class="dn-btn-icon">
              <i class="ri-emotion-unhappy-line"></i>
            </button>
            <button class="dn-btn-icon">
              <i class="ri-emotion-sad-line"></i>
            </button>
            <button class="dn-btn-icon">
              <i class="ri-reply-line"></i>
            </button>
            <button 
              rdPopover
              [bodyTemplate]="sentItemMenuDetail"
              [trigger]="'click'"
              [placement]="'bottom'"
              [alignX]="'right'"
              #modal="rdPopover"
              class="dn-btn-icon">
              <i class="ri-more-fill"></i>
              <ng-template #sentItemMenuDetail>
                <div class="dn-pop-menu">
                  <div class="dn-pop-menu__item">
                    <button class="dn-btn-text">
                      メッセージを引用
                    </button>
                  </div>
                  <div class="dn-pop-menu__item">
                    <button class="dn-btn-text">
                      リンクをコピー
                    </button>
                  </div>
                  <div class="dn-pop-menu__item">
                    <button class="dn-btn-text">
                      メッセージを編集
                    </button>
                  </div>
                  <div class="dn-pop-menu__item">
                    <button class="dn-btn-text">
                      メッセージを削除
                    </button>
                  </div>
                </div>
              </ng-template>
            </button>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>
<div
  app-case-pipeline-post-area
  #postArea
  [case]="case"
  [pipeline]="pipeline"
  (sendMessage)="onSendMessage()"
  class="dn-pipeline-footer"></div>

<div
  *ngIf="isDragOver"
  id="drop-layer"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"></div>
