import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { isImage } from 'src/app/logic/helper/util';
import { ICreative, TCreativeMin } from 'src/app/logic/interface/creative';
import { IPipeline } from 'src/app/logic/interface/pipeline';
import { IPipelineMessage, IPipelineMessageInputData } from 'src/app/logic/interface/pipeline-message';

@Component({
  selector: 'app-modal-file-detail',
  templateUrl: './file-detail.component.html',
})

export class ModalFileDetailComponent implements OnInit {

  fileUrl: SafeResourceUrl
  isImage = isImage

  constructor(
    public dialogRef: MatDialogRef<ModalFileDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public creative: TCreativeMin,
    private sanitizer: DomSanitizer
  ) {
    if (isImage(creative.fileType)) {

    } else {
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/gview?url=${creative.url}&embedded=true`)
    }
  }

  ngOnInit() {
    
  }

}


