import {
  Directive, ElementRef, HostListener, Input, ComponentFactory,
  ViewContainerRef, ComponentFactoryResolver, OnInit, ComponentRef
} from '@angular/core';
import { CommonScrollbarComponent } from './scrollbar.component';
import { Observable } from 'rxjs';

export enum MouseEventType {
  MOUSE_ENTER = 'mouseenter',
  MOUSE_LEAVE = 'mouseleave',
}

@Directive({
  selector: '[appScrollbar]'
})
export class ScrollbarDirective implements OnInit {

  @Input() scrollbar: CommonScrollbarComponent;
  @Input() observable: Observable<any>;
  @Input() changeLayout: Observable<any>;
  @Input() scrollEvent: () => {};

  private factory: ComponentFactory<CommonScrollbarComponent>;

  private initialized = false;

  constructor(
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    // スクロールバーを生成
    this.factory = this.resolver.resolveComponentFactory(CommonScrollbarComponent);
    const componentRef: ComponentRef<CommonScrollbarComponent> = this.viewContainerRef.createComponent(this.factory);
    this.scrollbar = componentRef.instance;

    // 挿入箇所を調整する
    const element = this.scrollbar.scrollbar.nativeElement as HTMLElement;
    (this.el.nativeElement as HTMLElement).appendChild(element);

    if (this.initialized) {
      return;
    }

    if (this.observable !== undefined) {
      this.observable.subscribe((value) => {
        if (value instanceof Array) {
          if (value.length > 0) {
            setTimeout(() => {
              this.initialized = true;
              this.scrollbar.setParent(this.el, this.scrollEvent);
            }, 100);
          }
        } else {
          setTimeout(() => {
            this.initialized = true;
            this.scrollbar.setParent(this.el, this.scrollEvent);
          }, 100);

        }
      });
    } else {
      setTimeout(() => {
        this.initialized = true;
        this.scrollbar.setParent(this.el, this.scrollEvent);
      }, 100);
    }

    if (this.changeLayout !== undefined) {
      this.changeLayout.subscribe(() => {
        const elements = document.getElementsByClassName('mat-dialog-container');
        if (elements.length > 0) {
          (elements[elements.length - 1] as HTMLElement).style.height = null;
        }
        setTimeout(() => {
          this.initialized = true;
          this.scrollbar.setParent(this.el, this.scrollEvent);
        }, 100);
      });
    }

  }

  @HostListener('mouseover') onMouseOver() {
  }

  @HostListener('mouseenter') onMouseEnter() {
    setTimeout(() => {
      // if (!this.initialized) {
      //   this.initialized = true;
      //   this.scrollbar.setParent(this.el);
      // }
      // this.scrollbar.setVisibility(true);
    }, 100);
    this.scrollbar.setOnMouse(true);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.scrollbar.setOnMouse(false);
  }

}
