<ng-container [formGroup]="formGroup">
    <div class="layout-row i-center flex-shrink box-w-p20 height-50px">
      <p class="ft-size-6 ft-weight-bold">User招待</p>
    </div>
    <div class="box-w-p20">
      <div class="layout-column mt-20">
        <span class="color-gray1 ft-size-1">メールアドレス（複数アドレスの場合は改行）</span>
        <textarea formControlName="emails" class="mt-4" rows="10"></textarea>
      </div>
    </div>
    <div class="layout-row i-center c-end flex-shrink box-w-p20 box-h-p15">
      <button class="btn-main middle area-80px"
        (click)="onSubmit()"
        [ngClass]="{'disabled': !formGroup.valid}"
        [attr.disabled]="!formGroup.valid || null">
        招待
      </button>
    </div>
  </ng-container>