import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataUser } from '../data/user.data';
import { StoreSession } from 'src/app/logic/store/session.store';

@Injectable({
  providedIn: 'root'
})
export class ModelUser {

  constructor(private dataUser: DataUser, private session: StoreSession) {
  }

  login(email: string, password: string): Observable<any> {
    return this.dataUser.login(email, password).pipe(
      map((res) => {
        this.session.login(res.token);
      })
    );
  }

  logout(): void {
    this.session.logout();
  }

}
