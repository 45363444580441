import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { StoreSession } from 'src/app/logic/store/session.store';
import { DataUserAuth } from '../data/user-auth.data';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModelUserAuth {

  constructor(
    private dataUserAuth: DataUserAuth,
    private session: StoreSession
  ) { }

  signup(email: string, password: string, name: string): Observable<any> {
    return this.dataUserAuth.signup(email, password, name);
  }

  authenticateUser(email: string, authenticationCode: number): Observable<any> {
    return this.dataUserAuth.authenticateUser(email, authenticationCode).pipe(
      map((res) => {
        this.session.login(res.token);
        return res.token
      })
    );
  }

  logout(): void {
    this.session.logout();
  }

}
