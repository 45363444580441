<div class="layout-column height-100">
  <div class="mt-20 ml-20">チーム一覧を表示する画面 (slackのTOPのようなイメージ)</div>

  <div *ngIf="store.parties.length > 0; else tempNoTeam" class="mt-20 ml-20">
    <div>チーム一覧</div>
    <div *ngFor="let team of store.parties">
      <a href="/party/{{ team.id }}">{{ team.name }}</a>
    </div>
    <div class="mt-20">
      <a routerLink="/party">Partyを作成する</a>
    </div>
  </div>
  <ng-template #tempNoTeam>
    <div class="mt-20 ml-20">
      <div>所属しているPartyがありません。</div>
      <a routerLink="/party">Partyを作成する</a>
    </div>
  </ng-template>
  
</div>
