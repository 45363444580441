import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import { LibHttp } from '../lib/http.lib';
import { IProject } from 'src/app/logic/interface/project';
import { IPipeline } from 'src/app/logic/interface/pipeline';
import { map } from 'rxjs/operators';
import { partyId } from '../helper/util';
import { IPipelineMessage } from '../interface/pipeline-message';

@Injectable({
  providedIn: 'root'
})
export class DataPipeline {

  constructor(private http: LibHttp) { }

  public addPipeline(caseId: string, name: string): Observable<IPipeline> {
    const params = {
      caseId,
      name,
      partyId: partyId(),
    };
    return this.http.post('pipeline', params).pipe(
      map(
        (response: any) => {
          return response.pipeline;
        })
    );
  }

  public completePipeline(pipelineId: string,): Observable<void> {
    return this.http.patch(`pipeline/${pipelineId}/status/complete`, { partyId: partyId() })
  }

  public deletePipeline(pipelineId: string,): Observable<void> {
    return this.http.delete(`pipeline/${pipelineId}`, { body: { partyId: partyId()} })
  }

  public restorePipeline(pipelineId: string,): Observable<void> {
    return this.http.patch(`pipeline/${pipelineId}/status/restore`, { partyId: partyId() })
  }

  public getPipelines(partyId: string, caseId: string): Observable<IPipeline[]> {
    return this.http.get(`pipeline`, { caseId, partyId }).pipe(
      map((res: any) => {
        return res.pipelines
      })
    )
  }

  public getPipelineMessages(partyId: string, pipelineId: string, oldestMessageId: string): Observable<IPipelineMessage[]> {
    return this.http.get(`pipeline/${pipelineId}/message`, { pipelineId, partyId, oldestMessageId }).pipe(
      map((res: any) => {
        return res.messages
      })
    )
  }

}
