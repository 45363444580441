<div [formGroup]="formGroup" class="layout-column c-between height-100">
  <div class="layout-row i-center flex-shrink box-w-p20 height-50px">
    <p class="ft-size-6 ft-weight-bold">case</p>
    <button [mat-dialog-close]="true">
      <i class="jam jam-close close-icon ft-size-6"></i>
    </button>
  </div>
  <div class="box-w-p20">
    <div class="layout-row i-center">
      <div class="layout-column">
        <span class="color-gray1 ft-size-1">case名</span>
        <input formControlName="name" class="area-220px mt-4">
      </div>
      
      <div class="img-box-50 circle change ml-14 pointer"
        ngfDrop
        [selectable]=true
        [(file)]="image">
        <img *ngIf="image !== undefined" [ngfSrc]="image">
        <img *ngIf="image === undefined" style="background-color: lightgray;">
      </div>

    </div>
    <div class="layout-column mt-20">
      <span class="color-gray1 ft-size-1">説明</span>
      <textarea formControlName="description" class="mt-4" rows="4"></textarea>
    </div>
  </div>
  <div class="layout-row i-center c-end flex-shrink box-w-p20 box-h-p15">
    <button class="btn-main middle area-80px"
      (click)="onSubmit()"
      [ngClass]="{'disabled': !formGroup.valid}"
      [attr.disabled]="!formGroup.valid || null">
      作成
    </button>
  </div>
</div>
