import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IProject } from 'src/app/logic/interface/project';

@Component({
  selector: 'app-add-material-input',
  templateUrl: './input.component.html',
})

export class AddMaterialInputComponent implements OnInit, OnChanges {

  @Input() file: File;
  @Input() projects: IProject[];

  @Output() changeValues = new EventEmitter<void>();

  @ViewChild('image', { static: true }) imageTag: ElementRef;

  selected = '指定しないで登録';

  // 入力
  formGroup = new FormGroup({
    fileName: new FormControl('', [
      Validators.required,
    ]),
    fileType: new FormControl('', [
      Validators.required,
    ]),
    projectId: new FormControl('', [
      Validators.required,
      Validators.min(1)
    ]),
  });

  imageWidth = 0;
  imageHeight = 0;

  constructor() { }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe(() => {
      this.changeValues.emit();
    });
    this.formGroup.patchValue({ fileName: this.file.name, fileType: this.file.type });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getImageSize();
  }

  onChangeFile() {
    this.formGroup.patchValue({ fileName: this.file.name, fileType: this.file.type });

    this.getImageSize();
  }

  public isValid() {
    return this.formGroup.valid;
  }

  private getImageSize() {
    setTimeout(() => {
      const imageElement = this.imageTag.nativeElement as HTMLImageElement;
      this.imageWidth = imageElement.naturalWidth;
      this.imageHeight = imageElement.naturalHeight;
    }, 1000);
  }
}


