import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { CommonAlertComponent, AlertDialogConfig } from '../../component/common/alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class LibAlert {

  private ref: MatDialogRef<CommonAlertComponent>;

  constructor(private dialog: MatDialog) { }

  /**
   * 実行かキャンセルかの2択を前提にしたアラート表示
   * @param title タイトル
   * @param message エラーメッセージ(HTML)
   * @param okButtonLabel 実行ボタンのテキスト
   * @param cancelButtonLabel キャンセルボタンのテキスト
   * @returns 
   */
  public showConfirm(
    title: string, message: string, okButtonLabel: string, cancelButtonLabel: string
  ): MatDialogRef<CommonAlertComponent> {
    return this._show({
      title,
      message,
      okButtonLabel,
      cancelButtonLabel,
      showCancel: true,
      panelClass: 'round-modal',
    });
  }

  /**
   * 実行を伴わない一つボタンを前提にしたアラート表示
   * 実行完了時やエラー表示を想定
   * @param title タイトル
   * @param message メッセージ(HTML)
   * @param okButtonLabel ボタンのテキスト
   * @returns 
   */
  public showNotice(
    title: string, message: string, okButtonLabel: string
  ): MatDialogRef<CommonAlertComponent> {
    return this._show({
      title,
      message,
      okButtonLabel,
      showCancel: false,
      panelClass: 'round-modal',
    });
  }

  // public showError(title: string) {
  //   let errorMessage = `
  //         <div>
  //           <p class="mt-6 color-highlight5 ft-size-2ft-weight-bold">${title}</p>
  //         </div>`;
  //   `<div class="mt-10"> += errorMessage += </div>`;

  //   this._show({
  //     message: errorMessage,
  //     showCancel: false,
  //     panelClass: 'round-modal',
  //   });
  // }

  public show(title: string, showCancel: boolean = false): MatDialogRef<CommonAlertComponent> {
    return this._show({
      message: title,
      showCancel: showCancel,
      panelClass: 'round-modal',
    });
  }

  public hide() {
    if (this.ref !== undefined) {
      this.ref.close();
      this.ref = undefined;
    }
  }

  private _show(config?: AlertDialogConfig) {
    if (config !== undefined) {
      config.data = config;
    }
    this.ref = this.dialog.open(CommonAlertComponent, config);
    return this.ref;
  }
}
