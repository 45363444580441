import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export interface OnBeforeunload {
  shouldConfirmOnBeforeunload: () => boolean;
}

@Injectable()
export class BeforeunloadGuard  {
  constructor(private dialog: MatDialog) { }

  canDeactivate(
    component: OnBeforeunload,
    _currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return true;
  }
}
