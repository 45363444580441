import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appKeyEvent]'
})
export class KeyEventDirective {

  private canEnter = false;
  private isShiftOn = false;

  constructor() { }

  @Output() entered = new EventEmitter<void>();
  @Output() tabed = new EventEmitter<KeyboardEvent>();

  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    const key = event.key;

    if (event.key.toLowerCase() === 'tab') {
      this.tabed.emit(event)
    }

    if (event.key.toLowerCase() === 'shift') {
      this.isShiftOn = true;
    }

    if (key === 'Enter' && !this.isShiftOn) {
      if (!this.canEnter) {
        return;
      }
      this.canEnter = false;
      this.entered.emit();
    }
  }

  @HostListener('keypress') onKeypress() {
    // 日本語入力中は呼ばれない→変換確定時は呼ばれない
    this.canEnter = true;
  }

  @HostListener('keyup', ['$event']) onKeyup(event: KeyboardEvent) {
    const key = event.key;

    if (key.toLowerCase() === 'shift') {
      this.isShiftOn = false;
    }

    /*
    if (key === 'Enter' && !this.isShiftOn) {
      if (!this.canEnter) {
        return;
      }
      this.canEnter = false;
      this.entered.emit();
    }
    */
  }

}
