import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StoreSession } from 'src/app/logic/store/session.store';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
})
export class SigninComponent implements OnInit {

  formGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
    ]),
    password: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(private sessionService: StoreSession) { }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe((value: { [key: string]: string }) => {
      // TODO: validate
    });
  }

  login() {
    // if (this.formGroup.valid) {
    //   this.sessionService.login(this.formGroup.value.email, this.formGroup.value.password);
    // } else {
    //   console.error('入力エラー');
    // }
  }

  logout() {
    this.sessionService.logout();
  }

}
