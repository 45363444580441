
<div class="dn-sidebar-header">
  <div
    rdPopover
    [bodyTemplate]="partySetting"
    [trigger]="'hover'"
    [arrow]="true"
    [placement]="'bottom'"
    [customClass]="'is-tooltip'"
    #modal="rdPopover"
    class="dn-sidebar-header__item">
    <span class="ft-size-3 ft-weight-bold text-ellipsis">{{storeSidemenu.selectedParty?.name}}</span>
    <button>
      <i class="ri-arrow-down-s-line dn-icon-style ml-16"></i>
    </button>
    <ng-template #partySetting>
      <div class="area-min-200px area-max-400px">
        <div class="layout-row i-center c-between height-50px box-w-p15 bdr-bottom bdr-ini">
          <span class="ft-size-3 ft-weight-bold text-ellipsis">{{storeSidemenu.selectedParty?.name}}</span>
          <button class="btn-main small ml-10">edit</button>
        </div>
        <div (click)="onAddPartyUser()" class="modal-menu layout-row i-center box-w-p15">
          User招待
        </div>
        <div
          rdPopover
          [bodyTemplate]="modalBodySelectParty"
          class="modal-menu layout-row i-center box-w-p15">
          Party切り替え
        </div>
        <div class="modal-menu layout-row i-center box-w-p15 bdr-bottom bdr-ini">
          <a routerLink="/party" routerLinkActive="active">
            Party作成
          </a>
        </div>
        <div class="modal-menu layout-row i-center height-50px box-w-p15">
          Sign Out
        </div>
      </div>
    </ng-template>

    <ng-template #modalBodySelectParty>
      <div
        *ngFor="let party of storeSidemenu.parties"
        class="modal-menu layout-row i-center box-w-p15"
        (click)="onSwitchParty(party)">{{ party.name }}</div>
    </ng-template>
  </div>
  <div class="dn-sidebar-header__item_right">
    <!-- <button 
      (click)="clickEvent()" 
      class="dn-sidebar-header_right dn-btn-icon">
      <i [ngClass]="{'ri-menu-fold-line': !isCollapsedSidebar, 'ri-menu-unfold-line': isCollapsedSidebar}"
        class="dn-icon-style"></i>
    </button> -->
    <button (click)="onChangeCollapsedSidebar()" class="dn-sidebar-header_right dn-btn-icon">
      <i [ngClass]="{'ri-menu-fold-line': !storeSidemenu.isCollapsed, 'ri-menu-unfold-line': storeSidemenu.isCollapsed}"
        class="dn-icon-style"></i>
    </button>
  </div>
</div>
<div class="dn-sidebar-list">
  <div 
    *ngIf="storeSidemenu.selectedParty" 
    app-side-menu-project-list 
    role="presentation" 
    style="display: flex; flex-direction: column; overflow: hidden;">
  </div>
  <a
    *ngIf="storeSidemenu.selectedParty"
    class="dn-sidebar-list__item"
    [routerLink]="['party', storeSidemenu.selectedParty.id, 'storage']"
    routerLinkActive="active"
    (click)="onClickMenu()">
    <span>Storage</span>
  </a>
  <a class="dn-sidebar-list__item" routerLink="/setting" routerLinkActive="active">
    <span>設定</span>
  </a>
</div>