import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DataCreative } from 'src/app/logic/data/creative.data';
import { DataPipelineMessage } from 'src/app/logic/data/pipeline-message.data';
import { partyId } from 'src/app/logic/helper/util';
import { ICreative, IRequestPostCreative } from 'src/app/logic/interface/creative';
import { IPipeline } from 'src/app/logic/interface/pipeline';
import { IPipelineMessage, IPipelineMessageInputData } from 'src/app/logic/interface/pipeline-message';
import { LibAlert } from 'src/app/logic/lib/alert.lib';
import { LibLoading } from 'src/app/logic/lib/loading.lib';
import { ModelPipeline } from 'src/app/logic/model/pipeline.model';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';

export interface IModalPostMaterialData {
  imageType: 'local' | 'creative';
  pipeline: IPipeline;
  file?: File;
  creative?: ICreative;
}

@Component({
  selector: 'app-modal-post-material',
  templateUrl: './post-material.component.html',
})

export class ModalPostMaterialComponent implements OnInit {

  pipelineOptions: IPipeline[] = []
  inputData: IPipelineMessageInputData
  versions: string[] = []

  @ViewChild('image', { static: false }) imageTag: ElementRef;
  @ViewChild('messageBox', { static: false }) messageBox: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ModalPostMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalPostMaterialData,
    private modelPipeline: ModelPipeline,
    private loading: LibLoading,
    private dataPipelineMessage: DataPipelineMessage,
    private storeSidemenu: StoreSidemenu,
    private alert: LibAlert,
  ) {
  }

  ngOnInit() {
    this.pipelineOptions = [this.data.pipeline]

    // 指定のPipelineに対してLocal画像を投稿する場合
    if (this.data.imageType === 'local') {
      this.loading.show()
      // 同Pipeline内で同じファイル名のCreativeを取得する
      this.dataPipelineMessage.getVersions(this.data.pipeline.id, this.data.file.name).subscribe((versions) => {
       this.versions = versions

        let max = '1.0.0'
        this.versions.forEach((version) => {
          const latestVals = max.split('.')
          const currentVals = version.split('.')
          if (currentVals[0] !== undefined && Number(currentVals[0]) > Number(latestVals[0])) {
            max = version
          } else if (currentVals[1] !== undefined && Number(currentVals[1]) > Number(latestVals[1])) {
            max = version
          } else if (currentVals[2] !== undefined && Number(currentVals[2]) > Number(latestVals[2])) {
            max = version
          }
        })
        let version = '1.0.0'
        if (this.versions.length > 0) {
          const vals = max.split('.')
          vals[2] = `${Number(vals[2]) + 1}`
          version = vals.join('.')
        }
        this.inputData = {
          pipelineId: this.data.pipeline.id,
          files: [this.data.file],
          message: '',
          version,
        }

        this.loading.hide()
      })
    } else if (this.data.imageType === 'creative') {
      this.inputData = {
        pipelineId: this.data.pipeline.id,
        creative: this.data.creative,
        message: '',
        version: '1.0.0',
        files: [],
      }
    }
  }

  onSubmit() {
    this.loading.show()

    const element = this.messageBox.nativeElement as HTMLElement
    this.inputData.message = element.innerHTML

    let ob: Observable<IPipelineMessage>
    const caseObj = this.storeSidemenu.getCase(this.data.pipeline.caseId)
    if (this.data.imageType === 'local') {
      // // 画像のサイズを取得
      // const { width, height } = this.getImageSize();

      // const file = this.inputData.file;
      // const params: IRequestPostCreative = {
      //   partyId: partyId(),
      //   index: 0,
      //   projectId: caseObj.projectId,
      //   fileName: file.name,
      //   fileType: file.type,
      //   fileSize: file.size,
      //   fileWidth: width,
      //   fileHeight: height,
      // }
      // ob = this.modelPipeline.sendMessageWithLocalImage(caseObj.id, params, this.inputData);

    } else if (this.data.imageType === 'creative') {
      ob = this.modelPipeline.sendMessageWithCreative(caseObj.id, this.inputData);
    }
    ob.subscribe({
      next: (result) => {
        this.loading.hide();
        this.dialogRef.close(result);
      },
      complete: () => {
        console.log('Complete♪');
      },
      error: (error) => {
        this.loading.hide();
        console.error(error);
        this.alert.showNotice('エラー', '投稿できませんでした', '閉じる')
      },
    });
  }

  validate(): boolean {
    if (!this.inputData) {
      return false
    }
    if (this.versions.includes(this.inputData.version)) {
      return false;
    }
    return true;
  }

  isImage(fileType: string) {
    return fileType.indexOf('image') >= 0
  }

  fileType(fileType: string): 'pdf' | 'ppt' | 'xls' {
    if (fileType.indexOf('pdf') >= 0) {
      return 'pdf'
    }
    if (fileType.indexOf('ms-powerpoint') >= 0 || fileType.indexOf('presentationml.presentation') >= 0) {
      return 'ppt'
    }
    if (fileType.indexOf('ms-excel') >= 0 || fileType.indexOf('spreadsheetml.sheet') >= 0) {
      return 'ppt'
    }
  }

  private getImageSize() {
    if (this.data.file && this.isImage(this.data.file.type)) {
      const imageElement = this.imageTag.nativeElement as HTMLImageElement;
      const width = imageElement.naturalWidth;
      const height = imageElement.naturalHeight;
      return { width, height }
    }
    return { width: 0, height: 0 }
  }
}


