<div [formGroup]="formGroup">
  <div class="layout-column">
    <span class="color-gray1 ft-size-1">ファイル名</span>
    <input class="mt-4 ft-size-1" type="text" formControlName="fileName">
  </div>
  <div class="layout-column mt-10">
    <span class="color-gray1 ft-size-1">ファイルタイプ</span>
    <input class="mt-4 ft-size-1" type="text" formControlName="fileType">
  </div>
  <div class="layout-column mt-10">
    <span class="color-gray1 ft-size-1">プロジェクト</span>
    <mat-form-field class="mt-4 ft-size-1">
      <mat-select formControlName="projectId">
        <mat-option [value]="0">選択してください</mat-option>
        <mat-option *ngFor="let project of projects;" [value]="project.id">{{project.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="layout-column mt-10">
    <span class="color-gray1 ft-size-1">ディレクトリ</span>
    <mat-form-field class="mt-4 ft-size-1">
      <mat-select [(value)]="selected" disableRipple>
        <mat-option value="指定しないで登録">選択しないで登録</mat-option>
        <mat-option value="ゲーム向け">ゲーム向け</mat-option>
        <mat-option value="アプリ">アプリ</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="mt-10"
    ngfDrop
    [selectable]=true
    [(file)]="file"
    (filesChange)="onChangeFile()">
    <div class="img-thumb c-center height-200px bg-gray4 bdr-box bdr-theme bdr-radius hidden-box">
      <img [ngfSrc]="file" #image>
    </div>
  </div>
</div>
<!-- <div class="box-p5 area-300px">
  <div class="layout-column box-p5">
    <span class="color-gray1 ft-size-1">ファイル名</span>
    <input class="mt-4">
  </div>
  <div class="layout-column box-p5">
    <span class="color-gray1 ft-size-1">ファイルタイプ</span>
    <input class="mt-4">
  </div>
  <div class="layout-column box-p5">
    <span class="color-gray1 ft-size-1">ディレクトリ</span>
    <mat-form-field class="mt-4">
      <mat-select [(value)]="selected" disableRipple>
        <mat-option value="全て">全て</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="box-p5">
    <div class="img-thumb c-center height-160px bg-gray4 bdr-box bdr-ini bdr-radius hidden-box">
      <img src="../../../../assets/img/banner_h.jpg">
    </div>
  </div>
</div> -->