import { Component } from '@angular/core';
import { ICase } from 'src/app/logic/interface/case';
import { ECreativeType } from 'src/app/logic/definition/enum';
import { IProject } from 'src/app/logic/interface/project';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { ICreative } from 'src/app/logic/interface/creative';
import { Subject } from 'rxjs';
import { DataCreative } from 'src/app/logic/data/creative.data';

@Component({
  selector: 'app-common-creative-list',
  template: '',
})
export class CommonCreativeListComponent {

  private _changeList = new Subject<void>();
  protected changeList = this._changeList.asObservable();

  public CreativeType: typeof ECreativeType = ECreativeType;
  public isListView = true;
  public dirId: string | null = null;
  protected directory: ICreative | null = null;
  selectedProjectIds: string[] = [];

  creatives: ICreative[] = [];

  changeLayout = new Subject<void>();

  constructor(
    public storeSidemenu: StoreSidemenu,
    public dataCreative: DataCreative,
  ) {
  }

  onClickAllProject() {
    if (this.selectedProjectIds.length < this.storeSidemenu.projects.length) {
      this.selectedProjectIds = this.storeSidemenu.projects.map((project) => project.id);
    } else if (this.selectedProjectIds.length === this.storeSidemenu.projects.length) {
      this.selectedProjectIds.length = 0;
    }
  }

  onClickProjectFilter(project: IProject) {
    const index = this.selectedProjectIds.findIndex((id) => id === project.id);
    if (index >= 0) {
      this.selectedProjectIds.splice(index, 1);
    } else {
      this.selectedProjectIds.push(project.id);
    }
  }

  convert(cases: Pick<ICase, "id" | "name">[]): string {
    const names = cases.map((c) => c.name);
    return names.join('<br>');
  }

  protected getCreatives() {
    const projectIds = this.storeSidemenu.projects.map((project) => project.id);
    this.selectedProjectIds = projectIds;
    this.dataCreative.getCreatives(this.dirId, projectIds).subscribe({
      next: (list) => {
        this.creatives = list;
        this._changeList.next();
        this.changeLayout.next();
      },
    });
  }

  protected getDirectory() {
    this.dataCreative.getDirectory(this.dirId).subscribe({
      next: (creative) => {
        this.directory = creative;
      },
    });
  }

}
