import { Component, OnInit } from '@angular/core';
import { ModelInvitation } from 'src/app/logic/model/invitation.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { IDBParty } from 'src/app/logic/interface/party';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
})
export class InvitationComponent implements OnInit {
  formGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(
    private modelInvitation: ModelInvitation,
    private route: ActivatedRoute,
  ) { }
  public party: IDBParty

  ngOnInit() {
    this.formGroup.valueChanges.subscribe((value: { [key: string]: string }) => {
      // TODO: validate
    });
    const code: string = encodeURIComponent(this.route.snapshot.params.code)
    this.modelInvitation.getInvitedPartyData(code).subscribe(
      (party) => {
        this.party = party
      }
    )
  }

  postInvitedUserToPartyUser(tooltip: MatTooltip) {
    if (!this.formGroup.valid) {
      return;
    }
    const code: string = decodeURIComponent(this.route.snapshot.params.code)
    this.modelInvitation.postInvitedUserToPartyUser(code, this.formGroup.get('name').value).subscribe({
      next: () => {
        window.location.href = `http://localhost:4002/party/${this.party.id}`
      },
      error: (error) => {
        console.error(error);
        tooltip.message = error.error.message ? error.error.message : '参加できませんでした。';
        tooltip.show();
        setTimeout(() => {
          tooltip.hide();
          tooltip.message = '';
        }, 2000);
      }
    });
  }
}
