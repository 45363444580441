import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LibHttp } from '../lib/http.lib';
import { IDBParty, IPostedParty } from 'src/app/logic/interface/party';

@Injectable({
  providedIn: 'root'
})
export class DataParty {

  constructor(private http: LibHttp) { }

  public getParties(): Observable<IDBParty[]> {
    return this.http.get('party', {});
  }

  public postParty(name: string, partyUserName: string): Observable<IPostedParty> {
    return this.http.post('party', { name, partyUserName });
  }
}
