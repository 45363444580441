import { Pipe, PipeTransform } from '@angular/core';

type TUnit = 'B' | 'KB' | 'MB' | 'GB' | 'TB';

@Pipe({
  name: 'size'
})
export class ImageSizePipe implements PipeTransform {

  transform(size: number, kind: 'size' | 'unit'): string {
    const byte = 1;
    const kByte = byte * 1024;
    const mByte = kByte ** 2;
    const gByte = kByte ** 3;
    const tByte = kByte ** 4;

    let unit: TUnit = 'B';
    let divisor = byte;
    if (tByte > size && size >= gByte) {
      divisor = gByte;
      unit = 'GB';
    } else if (size >= mByte) {
      divisor = mByte;
      unit = 'MB';
    } else if (size >= kByte) {
      divisor = kByte;
      unit = 'KB';
    }
    if (kind === 'size') {
      // 小数点第二位以下を切り捨て
      const fixedSize = Math.floor((size / divisor) * 10) / 10;
      return `${fixedSize}`;
    } else {
      return unit;
    }
  }

}
