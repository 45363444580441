import { Injectable } from '@angular/core';
import { ISession } from '../interface/session';
import { environment } from '../../../environments/environment';

import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Subject, Observable, throwError, of, from, Subscriber } from 'rxjs';
import { catchError, retryWhen, timeout, switchMap, flatMap, map, delay, concatMap } from 'rxjs/operators';
import { LibStorage } from './storage.lib';

export enum HttpErrorStatus {
  INPUT = 400,
  AUTH = 401,
  METHOD = 405,
  FATAL = 500,
  TIMEOUT = 504,
}

// 60秒 * 1 = 分
const TIMEOUT = 60000 * 1;

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {

  constructor(private strage: LibStorage) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // トークンセット
    const session = this.strage.getObject<ISession>('session');
    if (session != null) {
      let headers: HttpHeaders = request.headers;
      if (headers.get('Content-Type') === null) {
        headers = headers.set('Content-Type', 'application/json');
      }
      headers = headers.set('Authorization', 'Bearer ' + session.token);

      const newRequest = request.clone({ headers: headers });
      return next.handle(newRequest);
      // ログインAPIだけはそのまま返す
    } else {
      return next.handle(request);
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class LibHttp {
  public subject = new Subject<HttpErrorStatus>();
  public observer = this.subject.asObservable();

  constructor(private http: HttpClient, private strage: LibStorage) { }

  public session: ISession = null;
  public isRefreshing = false;

  get(path: string, hash: { [key: string]: any }): Observable<any> {
    const params = new HttpParams({ fromObject: hash });
    return this.http.get<any>(environment.apiUrl + path, { params: params })
      .pipe(
        timeout(TIMEOUT),
        // retryWhen(errors => {
        //   return this.refresh(errors);
        // }),
        catchError(this.errorHandler)
      );
  }

  post(path: string, params: { [key: string]: any }): Observable<any> {
    return this.http.post<any>(environment.apiUrl + path, params)
      .pipe(
        timeout(TIMEOUT),
        // retryWhen(errors => {
        //   return this.refresh(errors);
        // }),
        catchError(this.errorHandler)
      );
  }

  async postWithFile(path: string, params: { [key: string]: any }) {
    const params2 = new FormData()
    Object.keys(params).forEach((key) => {
      params2.append(key, params[key])

    })
    const session = this.strage.getObject<ISession>('session');
    const response = await fetch(environment.apiUrl + path, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+ session.token,
        // 'Content-Type': 'application/json',
      },
      body: params2,
    })
    const result = await response.json()
    return result
  }

  postWithoutAuth(path: string, params: { [key: string]: any }): Observable<any> {
    return this.http.post<any>(environment.apiUrl + path, params)
      .pipe(
        timeout(TIMEOUT),
        catchError(this.errorHandler)
      );
  }

  patch(path: string, params: { [key: string]: any }): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + path, params)
      .pipe(
        timeout(TIMEOUT),
        // retryWhen(errors => {
        //   return this.refresh(errors);
        // }),
        catchError(this.errorHandler)
      );
  }

  post2(path: string, params: HttpParams): Observable<any> {
    const header = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const httpOptions = {
      headers: new HttpHeaders(header)
    };
    return this.http.post<any>(environment.apiUrl + path, params.toString(), httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  delete(path: string, params: { [key: string]: any }): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + path, params)
      .pipe(
        timeout(TIMEOUT),
        // retryWhen(errors => {
        //   return this.refresh(errors);
        // }),
        catchError(this.errorHandler)
      );
  }

  private errorHandler = (error: HttpErrorResponse) => {
    console.error(error);

    // app-rootへ通知
    if (this.isRefreshing) {
      this.isRefreshing = false;
      this.subject.next(401);
    } else {
      this.subject.next(error.status);
    }

    return throwError(error);
  }

  // private refresh(obs: Observable<any>): Observable<any> {
  //   return obs.pipe(
  //     // delay(5000),
  //     switchMap((x: any, index: number) => {
  //       // 認証エラーの場合は1回だけリフレッシュをかける
  //       // リフレッシュに成功した場合は再度実行される
  //       if (x.status === 401 && index < 1) {
  //         return of(x);
  //       }
  //       // 認証エラー以外またはリフレッシュに失敗した場合はエラーを流す
  //       return throwError(x);
  //     }),
  //     // scan((count, _error) => {
  //     //   return count + 1;
  //     // }, 0),
  //     // takeWhile(count => count < 3),
  //     flatMap((_error) => {
  //       this.isRefreshing = true;
  //       const params = { refresh: this.session.refresh };
  //       return this.postWithoutAuth('auth/jwt/refresh/', params).pipe(
  //         map(
  //           (response: any) => {
  //             this.session.token = response.access;
  //             this.strage.setObject('session', this.session);
  //             return;
  //           }
  //         ),
  //       );
  //     })
  //   );
  // }

}
