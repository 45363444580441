<div 
  class="dn-sidebar-list__item dn-sidebar-project__title"
  (click)="isCollapsedProjects = !isCollapsedProjects">
  <!-- TODO: mouseアクションがselect中は(click)を発火させない -->
  <i 
    [ngClass]="{'is-collapsed__projects': isCollapsedProjects}"
    class="dn-sidebar-toggle-icon ri-arrow-down-s-fill dn-icon-style"></i>
  <span class="text-ellipsis">Project</span>
  <div class="dn-sidebar-list__item_right">
    <button class="dn-btn-icon" (click)="onAddProject()">
      <i class="ri-add-line dn-icon-style"></i>
    </button>
  </div>
</div>
<div class="scroll-container" appScrollbar>
  <div class="scroll-y">
    <!-- TODO: MounseOn状態でproject及びcaseの開閉を行った場合、scroll-barの長さをリアルタイムで調整する -->
    <ng-container *ngFor="let project of storeSidemenu.projects; index as i;">
      <div *ngIf="!isCollapsedProjects">
        <div 
          class="dn-sidebar-project"
          (click)="isCollapsedCases[i] = !isCollapsedCases[i]">
          <!-- TODO: mouseアクションがselect中は(click)を発火させない -->
          <i 
            [ngClass]="{'is-collapsed__cases': isCollapsedCases[i]}"
            class="dn-sidebar-toggle-icon ri-arrow-down-s-fill dn-icon-style"></i>
          <div class="dn-icon-project__xs">
            <img *ngIf="project.imageUrl !== null" [src]="project.imageUrl">
          </div>
          <span class="dn-sidebar-project__name">{{project.name}}</span>
          <div class="dn-sidebar-list__item_right dn-sidebar-list__item_menu">
            <!-- TODO: メニュー表示及びclick中は(click)を発火させない -->  
            <button class="dn-btn-icon" rdPopover [bodyTemplate]="modalBody" #modal="rdPopover">
              <i class="ri-user-line dn-icon-style"></i>
            </button>
            <ng-template #modalBody>
              <div class="modal-menu layout-row i-center box-w-p15" (click)="onProjectSetting(project); modal.hide();">
                <i class="jam jam-cogs mr-10 ft-size-4"></i>
                設定
              </div>
              <div class="modal-menu layout-row i-center box-w-p15" (click)="onAddCase(project.id); modal.hide();">
                <i class="jam jam-plus-circle mr-10 ft-size-4"></i>
                case追加
              </div>
            </ng-template>

            <button class="dn-btn-icon" rdPopover [bodyTemplate]="modalBody" #modal="rdPopover">
              <i class="ri-more-fill dn-icon-style"></i>
            </button>
            <ng-template #modalBody>
              <div class="modal-menu layout-row i-center box-w-p15" (click)="onProjectSetting(project); modal.hide();">
                <i class="jam jam-cogs mr-10 ft-size-4"></i>
                設定
              </div>
              <div class="modal-menu layout-row i-center box-w-p15" (click)="onAddCase(project.id); modal.hide();">
                <i class="jam jam-plus-circle mr-10 ft-size-4"></i>
                case追加
              </div>
            </ng-template>
          </div>
        </div>
        <ng-container *ngFor="let case of project.cases">
          <a
            *ngIf="!isCollapsedCases[i]"
            [class.active]="storeSidemenu.selectedCase?.id === case.id"
            [routerLink]=""
            queryParamsHandling="preserve"
            class="dn-sidebar-case"
            (click)="onClickCase(project, case)">
            <div class="dn-icon-case__xxs">
              <img *ngIf="case.imageUrl !== null" [src]="case.imageUrl">
            </div>
            <span class="dn-sidebar-project__name">{{case.name}}</span>
            <div class="dn-sidebar-list__item_right dn-sidebar-list__item_menu">
              <button class="dn-btn-icon" rdPopover [bodyTemplate]="caseMenu" #modal="rdPopover">
                <i class="ri-more-fill dn-icon-style"></i>
              </button>
              <ng-template #caseMenu>
                <div class="modal-menu layout-row i-center box-w-p15" (click)="onDeleteCase(case); modal.hide();">
                  <i class="jam jam-trash mr-10 ft-size-4"></i>
                  削除
                </div>
              </ng-template>
            </div>
          </a>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>