<div class="layout-row height-100vh bg-w">
  <div class="layout-column i-center c-center area-30">
    <img src="../../assets/img/logo_main.svg" class="area-50" />
  </div>

  <div class="layout-column i-end c-center area-70 bg-highlight1">
    <p class="area-80">Party名: {{this.party?.name}}</p>
    <div [formGroup]="formGroup" class="area-80">
      <input class="mt-10" formControlName="name" type="text" placeholder="Party表示名" />
      <button class="btn-main area-100px mt-20"
        #tooltip="matTooltip"
        matTooltip
        matTooltipPosition="above"
        (click)="postInvitedUserToPartyUser(tooltip)">
        参加する
      </button>
    </div>
  </div>
</div>
