<div class="dn-pipeline-item__left dn-icon-user__la">
  <img *ngIf="!message.partyUser.profileImage" style="background-color: lightgray;">
  <img *ngIf="message.partyUser.profileImage !== null" [src]="message.partyUser.profileImage">
</div>
<div class="dn-pipeline-item__right layout-column area-100">
  <div class="dn-pipeline-item__sent__info">
    <button class="dn-sender-name">{{ message.partyUser.name }}</button>
    <p class="dn-sent-time">{{message.updated | date:"yy/MM/dd HH:mm"}}</p>
  </div>
  <div *ngIf="message.id === null; else templateReady" >
    ファイル処理中
  </div>
  <ng-template #templateReady>
    <div *ngFor="let creative of message.creatives" class="layout-row box-p5 mr-5">
      <!-- 画像の場合 -->
      <div
        *ngIf="isImage(creative.fileType); else templateIsNotImage"
        class="img-thumb area-60 bg-gray4 bdr-box bdr-gray3 hidden-box">
        <img
          [src]="creative.url"
          (click)="onShowFileDetail(creative)">
        <!-- download -->
        <button (click)="onDownloadFile(creative)">
          <i class="jam jam-download ft-size-4"></i>
        </button>
      </div>
      <!--その他のファイルの場合 -->
      <ng-template #templateIsNotImage>
        <div class="img-thumb area-60 bg-gray4 bdr-box bdr-gray3 hidden-box">
          <div [ngSwitch]="fileType(creative.fileType)">
            <!-- TODO: ファイルに応じたアイコンを表示 -->
            <ng-container *ngSwitchCase="'pdf'">
              PDF
              <img
                [src]="creative.previewImageUrl"
                (click)="onShowFileDetail(creative)">
            </ng-container>
            <ng-container *ngSwitchCase="'ppt'">
              PPT/PPTX
              <img
                [src]="creative.previewImageUrl"
                (click)="onShowFileDetail(creative)">
            </ng-container>
            <ng-container *ngSwitchCase="'xls'">
              XLS/XLSX
              <img
                [src]="creative.previewImageUrl"
                (click)="onShowFileDetail(creative)">
            </ng-container>
            <ng-container *ngSwitchCase="'word'">
              DOC/DOCX
              <img
                [src]="creative.previewImageUrl"
                (click)="onShowFileDetail(creative)">
            </ng-container>
            <ng-container *ngSwitchDefault>
              未判定のファイル形式
            </ng-container>
          </div>
  
          <!-- download -->
          <button (click)="onDownloadFile(creative)">
            <i class="jam jam-download ft-size-4"></i>
          </button>
        </div>
      </ng-template>
      <div class="ml-20 area-40">
        <div
          class="layout-row i-center c-center box-w-p5 height-30px bg-gray2 color-w ft-size-1 text-center bdr-box bdr-gray2 round-box">
          {{ creative.version }}
        </div>
        <div class="mt-6 color-gray2">{{ creative.name }}</div>
      </div>
    </div>
  </ng-template>

  <div class="dn-pipeline-item__sent__text" [innerHTML]="message.message"></div>
  <div
    *ngIf="message.metaData !== null"
    class="dn-pipeline-item__sent__text">
    <div *ngFor="let metaData of message.metaData | keyvalue">
      <div><img src="http://www.google.com/s2/favicons?domain={{ metaData.value.siteUrl }}">{{ metaData.value.siteName }}</div>
      <div><a href="{{ metaData.value.siteUrl }}">{{ metaData.value.title }}</a></div>
      <div [innerHTML]="metaData.value.description"></div>
      <img src="{{ metaData.value.imageUrl }}" width="300px">
    </div>
  </div>
</div>