import { Injectable } from '@angular/core';

import { Observable, of, map } from 'rxjs';

import { concatMap } from 'rxjs/operators';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { DataProject } from '../data/project.data';
import { ModelImage } from './image.model';

@Injectable({
  providedIn: 'root'
})
export class ModelProject {

  constructor(
    private dataProject: DataProject,
    private storeSidemenu: StoreSidemenu,
    private modelImage: ModelImage,
  ) { }

  public addProject(name: string, description: string, image: File | undefined): Observable<void> {

    const partyId: string = location.href.split('/')[4]

    return this.dataProject.addProject(partyId, name, description, image ? image.type : null).pipe(
      concatMap((result) => {
        if (!image) {
          return of(result.project);
        }
        return this.modelImage.uploadImage(result.imageUrl, image).pipe(
          map(() => result.project),
        );
      }),
      map((project) => {
        this.storeSidemenu.addProject(project);
      }),
    );
  }

}
