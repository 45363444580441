<div class="layout-row height-100vh bg-w">
    <div class="layout-column c-center area-100 bg-highlight1">
      <div [formGroup]="formGroup" class="area-50 ml-10">
        <p>ユーザー登録</p>
        <input class="mt-10" formControlName="email" type="text" placeholder="mail" />
        <input class="mt-10" formControlName="password" type="password" placeholder="password" />
        <input class="mt-10" formControlName="name" type="text" placeholder="name" />
        <button class="btn-main area-100px mt-20"
          #tooltip="matTooltip"
          matTooltip
          matTooltipPosition="above"
          (click)="signup(tooltip)">
          Sign up
        </button>
      </div>
    </div>
  </div>
  