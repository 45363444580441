<button
  class="dn-btn-icon__xs"
  [ngClass]="{'active': storeTextEditor.isBold}"
  (click)="onClickFunctionButton('bold')">
  <i class="ri-bold"></i>
</button>
<button
  class="dn-btn-icon__xs"
  [ngClass]="{'active': storeTextEditor.isItalic}"
  (click)="onClickFunctionButton('italic')">
  <i class="ri-italic"></i>
</button>
<!-- TODO: 書式underlineを追加 -->
<button
  class="dn-btn-icon__xs"
  [ngClass]="{'active': storeTextEditor.isItalic}"
  (click)="onClickFunctionButton('italic')">
  <i class="ri-underline"></i>
</button>
<button
  class="dn-btn-icon__xs"
  [ngClass]="{'active': storeTextEditor.isStrikeThrough}"
  (click)="onClickFunctionButton('strike-through')">
  <i class="ri-strikethrough"></i>
</button>
<button
  rdPopover
  [bodyTemplate]="modalInputLink" #modal="rdPopover"
  class="dn-btn-icon__xs">
  <i class="ri-link"></i>
</button>
<ng-template #modalInputLink>
  <div
    [formGroup]="formGroupLink"
    class="box-w-p20 box-h-p15">
    <div class="layout-row i-center">
      <div class="layout-column">
        <span class="color-gray1 ft-size-1">テキスト</span>
        <input formControlName="text" class="area-220px mt-4">
      </div>  
    </div>
    <div class="layout-row i-center">
      <div class="layout-column">
        <span class="color-gray1 ft-size-1">リンク</span>
        <input formControlName="link" class="area-220px mt-4">
      </div>  
    </div>
    <div class="layout-row i-center c-end flex-shrink box-w-p20 box-h-p15">
      <button
        class="btn-main middle area-100px"
        (click)="onCloseLinkModal(modal)">
        キャンセル
      </button>
      <button
        class="btn-main middle area-100px"
        (click)="onSubmitLink(modal)"
        [ngClass]="{'disabled': !formGroupLink.valid}"
        [attr.disabled]="!formGroupLink.valid || null">
        作成
      </button>
    </div>
  </div>
</ng-template>
<button
  class="dn-btn-icon__xs"
  (click)="onClickFunctionButton('order-list')">
  <i class="ri-list-ordered"></i>
</button>
<button
  class="dn-btn-icon__xs"
  (click)="onClickFunctionButton('point-list')">
  <i class="ri-list-unordered"></i>
</button>
<button
  class="dn-btn-icon__xs"
  (click)="onClickFunctionButton('quote')">
  <i class="ri-double-quotes-l"></i>
</button>
<button
  class="dn-btn-icon__xs"
  [ngClass]="{'active': storeTextEditor.isCode}"
  (click)="onClickFunctionButton('code')">
  <i class="ri-code-view"></i>
</button>
<button
  class="dn-btn-icon__xs"
  [ngClass]="{'active': storeTextEditor.isCodeBlock}"
  (click)="onClickFunctionButton('code-block')">
  <i class="ri-code-block"></i>
</button>