import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';

export class AlertDialogConfig extends MatDialogConfig {
  title?: string;
  message?: string;
  showCancel?: boolean;
  okButtonLabel?: string;
  cancelButtonLabel?: string;
}

@Component({
  selector: 'app-common-alert',
  templateUrl: './alert.component.html'
})
export class CommonAlertComponent implements OnInit {

  title = '';
  message = '';
  cancelButtonLabel = 'いいえ';
  okButtonLabel = 'はい';
  showCancel = false;

  html = '';

  constructor(
    public dialogRef: MatDialogRef<CommonAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogConfig) {

    if (data.title !== undefined) {
      this.title = data.title;
    }
    if (data.message !== undefined) {
      this.message = data.message;
    }
    if (data.okButtonLabel !== undefined) {
      this.okButtonLabel = data.okButtonLabel;
    }
    if (data.cancelButtonLabel !== undefined) {
      this.cancelButtonLabel = data.cancelButtonLabel;
    }
    if (data.showCancel !== undefined) {
      this.showCancel = data.showCancel;
    }
  }

  ngOnInit() {
  }

  onSubmit(result: boolean) {
    this.dialogRef.close(result);
  }
}
