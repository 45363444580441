import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent implements OnInit {

  @Output() selectedFiles = new EventEmitter<File[]>();

  files: File[];

  constructor() { }

  ngOnInit() { }

  onChangeFile() {
    setTimeout(() => {
      this.selectedFiles.emit(this.files.concat());

      // 同ファイルを再度選択すると検知されないため、選択状態をリセットする
      // const elements = document.getElementsByTagName('input');
      // const element: HTMLInputElement = elements.item(0);
      // element.value = null;

      this.files.length = 0;
    }, 10);
  }
}
