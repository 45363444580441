<div class="layout-column area-440px height-100 bg-bg hidden-box" tabindex="0">
  <i class="close-modal rex-icon-close" (click)="dialogRef.close()"></i>
  <div class="layout-row i-center flex-shrink height-48px box-w-p24 bg-w shadow-bottom">
    <p class="ft-size-3 ft-weight-bold">{{title}}</p>
  </div>

  <div class="scroll-container" appScrollbar>
    <div class="scroll-y">
      <div class="layout-column box-p12">
        <div class="box-p12">
          <div class="alert-box danger layout-row">
            <i class="rex-icon-warning-outlined ft-size-6"></i>
            <span class="ml-16" [innerHTML]="message"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="layout-row i-center c-center flex-shrink area-100 height-64px box-w-p16 bg-w shadow-top">
    <ng-container *ngIf="showCancel; else tempSingle;">
      <div class="box-w-p8">
        <button (click)="onSubmit(false)" class="btn-secondary black area-136px">{{cancelButtonLabel}}</button>
      </div>
      <div class="box-w-p8">
        <button (click)="onSubmit(true)" class="btn-primary danger area-136px">{{okButtonLabel}}</button>
      </div>
    </ng-container>
    <ng-template #tempSingle>
      <div class="box-w-p8">
        <button (click)="onSubmit(false)" class="btn-secondary black area-136px">{{okButtonLabel}}</button>
      </div>
    </ng-template>
  </div>

</div>
