import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StoreSession } from 'src/app/logic/store/session.store';
import { StoreTextEditor } from 'src/app/component/view/project/case/pipeline/post-area/text-editor/text-editor.store';
import { TFuncName } from '../text-editor/text-editor.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PopoverDirective } from 'src/app/directive/popover/popover.directive';

@Component({
  selector: '[app-case-pipeline-post-area-toolbar]',
  templateUrl: './toolbar.component.html',
})
export class CasePipelinePostAreaToolbarComponent implements OnInit {

  @Output() clickedFunc = new EventEmitter<TFuncName>()

  formGroupLink = new FormGroup({
    text: new FormControl('', [
      Validators.required,
    ]),
    link: new FormControl('', [
      Validators.required,
    ]),
  })

  constructor(
    public session: StoreSession,
    public storeTextEditor: StoreTextEditor,
  ) { }

  ngOnInit() {
  }

  onClickFunctionButton(funcName: TFuncName) {
    this.clickedFunc.emit(funcName)
  }

  onSubmitLink(modal: PopoverDirective) {
    this.storeTextEditor.insertLink(this.formGroupLink.value.text, this.formGroupLink.value.link)
    this.onCloseLinkModal(modal)
  }

  onCloseLinkModal(modal: PopoverDirective) {
    modal.hide()
  }

}
