import {
  Directive, ElementRef, Input, OnInit, TemplateRef, Output, EventEmitter, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef
} from '@angular/core';
import { CommonPopoverComponent } from './popover.component';
import { StorePopover } from './popover.store';

export enum MouseEventType {
  MOUSE_ENTER = 'mouseenter',
  MOUSE_LEAVE = 'mouseleave',
}

export type TPopoverPlacement = 'left' | 'top' | 'right' | 'bottom' | 'vertical' | 'horizontal' | 'auto' | 'bottom auto' | 'top auto' | 'left auto' | 'right auto'

@Directive({
  selector: '[rdPopover]',
  exportAs: 'rdPopover',
  providers: [StorePopover]
})
export class PopoverDirective implements OnInit {

  @Input() bodyTemplate: TemplateRef<any>;
  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  /* Popover Options */
  @Input() trigger?: 'click' | 'hover' | 'focus' | 'manual' = 'click'
  @Input() placement?: TPopoverPlacement = 'auto'
  @Input() alignX?: 'left' | 'center' | 'right' = 'center'
  @Input() alignY?: 'top' | 'center' | 'bottom' = 'center'
  @Input() arrow?: boolean = false
  @Input() arrowLeft?: number
  @Input() arrowRight?: number
  @Input() fit?: 'width' | 'min-width' | 'max-width'
  @Input() noninteractive?: boolean = false
  @Input() disabled?: boolean = false
  @Input() showOnlyDisabled?: boolean = false
  @Input() customClass?: string
  @Input() overlay?: boolean = false
  @Input() scrollElement?: string = '.scroll-box-y'
  @Input() offset?: number = 0
  @Input() show?: boolean = false
  /* Popover Options */

  private factory: ComponentFactory<CommonPopoverComponent>;

  private popoverComponent: CommonPopoverComponent
  private triggerElement: HTMLElement

  placementFixed: TPopoverPlacement

  lastX: number
  lastY: number

  constructor(
    private triggerElementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private store: StorePopover) {
  }

  ngOnInit() {
    console.log('ngOnInit@directive')

    this.store.changeIsShow.subscribe((isShow) => {
      if (isShow) {
        this.showPopover()
      } else {
        this.hide()
      }
    })

    addEventListener('click', this.clickOutside)
    if (this.trigger === 'hover') {
      addEventListener('mousemove', this.mouseMove)
    }

    this.triggerElement = this.triggerElementRef.nativeElement as HTMLElement
    this.store.triggerElement = this.triggerElement
    if (this.trigger === 'click') {
      this.triggerElement.addEventListener('click', this.store.onClick)
    } else if (this.trigger === 'focus') {
      this.triggerElement.addEventListener('focus', this.store.onFocus)
    } else if (this.trigger === 'hover') {
      this.triggerElement.addEventListener('mouseover', this.store.onMouseOver)
    }

    if (this.show) {
      this.store.isShow = this.show
    }
    
    const scrollBox = this.triggerElement.closest(this.scrollElement)
    if (scrollBox) {
      scrollBox.addEventListener('scroll', (this.scrolled))
    }
  }

  public hide() {
    document.body.removeChild(this.popoverComponent.el.nativeElement)
  }

  /**
   * click対象からpopoverを閉じるかどうか判定する
   * @param e
   */
  private clickOutside = (e: MouseEvent | FocusEvent) => {
    if (!this.store.isShow) {
      return
    }
    // bodyは無視
    // TODO: 判定方法を考えた方がいいかも
    if ((e.target as HTMLElement).tagName === 'BODY') {
      return
    }
    // popover内のclickは閉じない
    if (e.target instanceof Node && this.popoverComponent.refPopover.nativeElement && this.popoverComponent.refPopover.nativeElement.contains(e.target)) {
      return
    }
    if (this.show) {
      // props showを渡している場合、枠外を押下してもpopoverを閉じたくない場合があるため
      return
    }
    const otherPopover = (e.target as HTMLElement).closest('.rd-popover-body')
    // 親または子のPopoverをclickした場合
    if (otherPopover) {
      // 子をclickした場合は自分は閉じない(⇔親の場合は自分(子)は閉じる)
      if (this.popoverComponent.uuid < Number((otherPopover as HTMLElement).dataset.uuid)) {
        return
      }
    }
    // trigger要素内は閉じない
    if (e.target instanceof Node && this.triggerElement.contains(e.target)) {
      return
    }
    // 閉じる
    this.store.isShow = false
  }

  /**
   * hover時にtriggerとpopoverの余白を移動しても非表示にならないようにする
   * @param e
   */
  private mouseMove = (e: MouseEvent) => {
    this.lastX = e.x
    this.lastY = e.y

    if (!this.store.isShow || !this.popoverComponent.refPopover.nativeElement) {
      return
    }
    // dummy trigger内
    if (e.target instanceof Node && this.popoverComponent.refClickable && this.popoverComponent.refClickable.nativeElement.contains(e.target)) {
      return
    }
    // trigger内
    if (e.target instanceof Node && this.triggerElement && this.triggerElement.contains(e.target)) {
      return
    }
    // popover内
    if (e.target instanceof Node && this.popoverComponent.refPopover.nativeElement && this.popoverComponent.refPopover.nativeElement.contains(e.target) && this.noninteractive === false) {
      return
    }
    // 余白内
    const MARGIN = 2 // ぎりぎりの位置で非表示にならないように少し余裕をもたせる
    if (this.placementFixed === 'top' && e.y >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().bottom - MARGIN && e.y <= this.triggerElement.getBoundingClientRect().top + MARGIN && e.x >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().left && e.x <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().right) {
      return
    }
    if (this.placementFixed === 'bottom' && e.y >= this.triggerElement.getBoundingClientRect().bottom - MARGIN && e.y <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().top + MARGIN && e.x >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().left && e.x <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().right) {
      return
    }
    if (this.placementFixed === 'left' && e.y >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().top && e.y <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().bottom && e.x >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().right - MARGIN && e.x <= this.triggerElement.getBoundingClientRect().left + MARGIN) {
      return
    }
    if (this.placementFixed === 'right' && e.y >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().top && e.y <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().bottom && e.x >= this.triggerElement.getBoundingClientRect().right - MARGIN && e.x <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().left + MARGIN) {
      return
    }
    this.store.isShow = false
  }

  private scrolled = (e: Event) => {
    if (!this.store.isShow) {
      return
    }
    this.popoverComponent.setPosition()
  
    const scrollBox = this.triggerElement.closest(this.scrollElement)
    if (this.popoverComponent.refPopover.nativeElement!.getBoundingClientRect().top < scrollBox!.getBoundingClientRect().top || this.popoverComponent.refPopover.nativeElement!.getBoundingClientRect().bottom > scrollBox!.getBoundingClientRect().bottom) {
      this.store.isShow = false
      return
    }
  
    // tooltipはscrollで消す
    if (this.trigger === 'hover') {
      setTimeout(() => {
        if (!this.popoverComponent.refPopover.nativeElement) {
          return
        }
  
        // dummy trigger内
        if (
          (this.popoverComponent.refClickable && this.lastX >= this.popoverComponent.refClickable.nativeElement.getBoundingClientRect().left)
          && (this.popoverComponent.refClickable.nativeElement && this.lastX <= this.popoverComponent.refClickable.nativeElement.getBoundingClientRect().right)
          && (this.popoverComponent.refClickable.nativeElement && this.lastY >= this.popoverComponent.refClickable.nativeElement.getBoundingClientRect().top)
          && (this.popoverComponent.refClickable.nativeElement && this.lastY <= this.popoverComponent.refClickable.nativeElement.getBoundingClientRect().bottom)
        ) {
          return
        }
        // trigger内
        if (
          (this.triggerElement && this.lastX >= this.triggerElement.getBoundingClientRect().left)
          && (this.triggerElement && this.lastX <= this.triggerElement.getBoundingClientRect().right)
          && (this.triggerElement && this.lastY >= this.triggerElement.getBoundingClientRect().top)
          && (this.triggerElement && this.lastY <= this.triggerElement.getBoundingClientRect().bottom)
        ) {
          return
        }
        // popover内
        if (
          (this.popoverComponent.refPopover.nativeElement && this.lastX >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().left)
          && (this.popoverComponent.refPopover.nativeElement && this.lastX <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().right)
          && (this.popoverComponent.refPopover.nativeElement && this.lastY >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().top)
          && (this.popoverComponent.refPopover.nativeElement && this.lastY <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().bottom)
        ) {
          return
        }
  
        // 余白内
        const MARGIN = 2 // ぎりぎりの位置で非表示にならないように少し余裕をもたせる
        if (this.placementFixed === 'top' && this.lastY >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().bottom - MARGIN && this.lastY <= this.triggerElement.getBoundingClientRect().top + MARGIN && this.lastX >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().left && this.lastX <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().right) {
          return
        }
        if (this.placementFixed === 'bottom' && this.lastY >= this.triggerElement.getBoundingClientRect().bottom - MARGIN && this.lastY <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().top + MARGIN && this.lastX >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().left && this.lastX <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().right) {
          return
        }
        if (this.placementFixed === 'left' && this.lastY >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().top && this.lastY <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().bottom && this.lastX >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().right - MARGIN && this.lastX <= this.triggerElement.getBoundingClientRect().left + MARGIN) {
          return
        }
        if (this.placementFixed === 'right' && this.lastY >= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().top && this.lastY <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().bottom && this.lastX >= this.triggerElement.getBoundingClientRect().right - MARGIN && this.lastX <= this.popoverComponent.refPopover.nativeElement.getBoundingClientRect().left + MARGIN) {
          return
        }
        this.store.isShow = false
      }, 100)
    }
  }

  private showPopover = () => {
    // popoverを生成
    this.factory = this.resolver.resolveComponentFactory(CommonPopoverComponent);
    const componentRef: ComponentRef<CommonPopoverComponent> = this.viewContainerRef.createComponent(this.factory);
    this.popoverComponent = componentRef.instance;
    document.body.appendChild(this.popoverComponent.el.nativeElement)

    this.placementFixed = this.placement

    // 余白の比率が大きい方にコンテンツを表示するための計算
    if (this.placement === 'auto') {
      // 左端から見て何%の位置にあるか
      let x = this.triggerElement.getBoundingClientRect().left / document.body.offsetWidth
      // 画面中央より右側にある場合は右端から見て何%の位置にあるか
      if (this.triggerElement.getBoundingClientRect().left / document.body.offsetWidth > 0.5) {
        x = 1 - this.triggerElement.getBoundingClientRect().left / document.body.offsetWidth
      }
      // 下端から見て何%の位置にあるか
      let y = this.triggerElement.getBoundingClientRect().top / document.body.offsetHeight
      // 画面中央より上側にある場合は上端から見て何%の位置にあるか
      if (this.triggerElement.getBoundingClientRect().top / document.body.offsetHeight > 0.5) {
        y = 1 - this.triggerElement.getBoundingClientRect().top / document.body.offsetHeight
      }
  
      // 横方向よりも縦方向の方が余白(%)が大きい場合は、上か下かで余白が大きい方を決定
      if (x > y) {
        if (this.triggerElement.getBoundingClientRect().top / document.body.offsetHeight > 0.5) {
          this.placementFixed = 'top'
        } else {
          this.placementFixed = 'bottom'
        }
      // 左右のどちらにするか決定する
      } else if (this.triggerElement.getBoundingClientRect().left / document.body.offsetWidth > 0.5) {
        this.placementFixed = 'left'
      } else {
        this.placementFixed = 'right'
      }
    } else if (this.placement === 'vertical') {
      if (this.triggerElement.getBoundingClientRect().top / document.body.offsetHeight > 0.5) {
        this.placementFixed = 'top'
      } else {
        this.placementFixed = 'bottom'
      }
    } else if (this.placement === 'horizontal') {
      if (this.triggerElement.getBoundingClientRect().left / document.body.offsetWidth > 0.5) {
        this.placementFixed = 'left'
      } else {
        this.placementFixed = 'right'
      }
    } else if (this.placement === 'bottom auto') {
      // bottomの場合の位置を出して下端がWindowに収まらない場合は反転(topに)する
      const y = this.triggerElement.getBoundingClientRect().bottom + this.popoverComponent.popoverBody.offsetHeight
      if (y > document.body.offsetHeight) {
        this.placementFixed = 'top'
      } else {
        this.placementFixed = 'bottom'
      }
    } else if (this.placement === 'top auto') {
      // topの場合の位置を出して上端がWindowに収まらない場合は反転(topに)する
      const y = this.triggerElement.getBoundingClientRect().top - this.popoverComponent.popoverBody.offsetHeight
      if (y < 0) {
        this.placementFixed = 'bottom'
      } else {
        this.placementFixed = 'top'
      }
    } else if (this.placement === 'left auto') {
      // leftの場合の位置を出して左端がWindowに収まらない場合は反転(rightに)する
      const x = this.triggerElement.getBoundingClientRect().left - this.popoverComponent.popoverBody.offsetWidth
      if (x < 0) {
        this.placementFixed = 'right'
      } else {
        this.placementFixed = 'left'
      }
    } else if (this.placement === 'right auto') {
      const x = this.triggerElement.getBoundingClientRect().right + this.popoverComponent.popoverBody.offsetWidth
      if (x > document.body.offsetWidth) {
        this.placementFixed = 'left'
      } else {
        this.placementFixed = 'right'
      }
    }

    console.log(this.placementFixed)

    this.store.isShow = true
    this.popoverComponent.initialize(
      this.store,
      this.bodyTemplate,

      this.trigger,
      this.showOnlyDisabled,
      this.disabled,
      this.arrow,
      this.placementFixed,
      this.alignX,
      this.alignY,
      this.arrowLeft,
      this.arrowRight,
      this.offset,
      this.fit,
      this.overlay,
      this.customClass ?? '',
    )
  
    // TODO
    // // ローディングが表示されている場合のみ最後尾に移動する
    // // (この条件に限らず最後尾にしたいのだが、エラーが出てしまいうまくいかなかったため条件付きの対応とした)
    // const loadingElems = document.body.getElementsByClassName('loading-content')
    // if (loadingElems.length > 0) {
    //   document.body.appendChild(this.popoverComponent.refPopover.nativeElement)
    // }
  }
  
}
