import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { interval, Observable, Subscriber } from 'rxjs';
import { StoreSidemenu } from '../logic/store/sidemenu.store';


@Injectable({
  providedIn: 'root'
})
export class InitializationGuard  {
  constructor(private router: Router, private storeSidemenu: StoreSidemenu) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable<boolean>(
      (observer: Subscriber<boolean>) => {
        const sub = interval(250).subscribe((i) => {
          if (this.storeSidemenu.noParty !== undefined) {
            console.log('Guard OK', this.storeSidemenu.parties)
            sub.unsubscribe();
            observer.next(true);
          }
          if (i > 100) {
            observer.next(false);
            sub.unsubscribe();
            this.router.navigate([`/login`])
          }
        });
      },
    );
  }

}
