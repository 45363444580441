import { Injectable, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ModelScroll {

  private active = false;
  private parent: ElementRef | null = null;
  private scrollbar: ElementRef | null = null;
  private scrollbarthumb?: ElementRef;
  private scrolltrack: number;

  private scrollArea: HTMLElement;
  private scrollBoxY: HTMLElement;
  private scrollbarthumbCursorY: any;

  private subjectMouseup = new Subject();
  public mouseup = this.subjectMouseup.asObservable();

  constructor() {
    this.initScrollbar();
  }

  public setScrollbar(
    parent: ElementRef,
    scrollbar: ElementRef,
    scrollbarthumb: ElementRef,
    scrolltrack: number,
    scrollbarthumbCursorY: any
  ) {
    this.parent = parent;
    this.scrollbar = scrollbar;
    this.scrollbarthumb = scrollbarthumb;
    this.scrolltrack = scrolltrack;
    this.scrollbarthumbCursorY = scrollbarthumbCursorY;
    this.active = true;

    if (parent !== null) {
      this.active = true;
      // 各要素を取得
      this.scrollArea = this.parent.nativeElement as HTMLElement;
      this.scrollBoxY = this.scrollArea.getElementsByClassName('scroll-y')[0] as HTMLElement;
    } else {
      this.active = false;
    }
  }

  private initScrollbar() {

    const onMouseMove = (event) => {
      if (!this.active) { return; }

      const scrollbarRect = (this.scrollbar.nativeElement as HTMLElement).getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrollbarthumbY = ((event.pageY - scrollbarRect.top + scrollTop) / this.scrolltrack * this.scrolltrack)
        - this.scrollbarthumbCursorY;

      // つまみが上下の領域外を超えないようにする
      if (scrollbarthumbY < 0) {
        scrollbarthumbY = 0;
      } else if (scrollbarthumbY > this.scrolltrack) {
        scrollbarthumbY = this.scrolltrack;
      }

      // つまみの位置設定
      const scrollbarthumb = this.scrollbarthumb.nativeElement as HTMLElement;
      scrollbarthumb.style.transform = 'translateY(' + scrollbarthumbY + 'px)';

      // つまみの位置に応じてスクロールさせる
      const scrollAreaHeight = this.scrollArea.clientHeight;
      const scrollBoxYHeight = this.scrollBoxY.scrollHeight;

      const scrollbarthumbRect = scrollbarthumb.getBoundingClientRect();
      this.scrollBoxY.scrollTo(
        0,
        (scrollbarthumbRect.top + scrollTop - scrollbarRect.top + scrollTop) / this.scrolltrack * (scrollBoxYHeight - scrollAreaHeight)
      );
    };

    document.onmouseup = () => {
      this.active = false;
      this.setScrollbar(null, null, null, 0, null);

      // mouseupの通知
      this.subjectMouseup.next(void 0);
    };
    document.onmousemove = onMouseMove;
  }
}
