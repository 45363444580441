import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';

import { LoadingComponent, LOADING_DATA } from '../../component/common/loading/loading.component';

@Injectable({
  providedIn: 'root'
})
export class LibLoading {

  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay) { }

  show(config: OverlayConfig = {}) {
    const merged = Object.assign(this.overlayConfig(), config);
    this.showLoading('loading', merged);
  }

  showCustom(message: string, config: OverlayConfig = {}) {
    const merged = Object.assign(this.overlayConfig(), config);
    this.showLoading(message, merged);
  }

  hide() {
    this.overlayRef?.dispose();
  }

  private showLoading(message: string, config: OverlayConfig) {
    const injector: Injector = Injector.create({
      providers: [{
        provide: LOADING_DATA,
        useValue: { message: message }
      }]
    });

    const componentPortal = new ComponentPortal(
      LoadingComponent,
      null,
      injector
    );
    this.overlayRef = this.overlay.create(config);
    this.overlayRef.attach(componentPortal);
  }

  private overlayConfig(): OverlayConfig {
    const config = new OverlayConfig({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      // スクロールさせない
      scrollStrategy: this.overlay.scrollStrategies.block(),
      // 背景黒
      hasBackdrop: true,
      // backdropClass: 'overlay-loading'
    });
    return config;
  }

}
