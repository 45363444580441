<div class="layout-column height-100"
  (dragstart)="dragEvent($event, 'dragstart')"  
  (drag)="dragEvent($event, 'drag');$event.preventDefault();"
  (drop)="onDrop($event)"
  (dragend)="dragEvent($event, 'dragend');$event.preventDefault();"
  (dragover)="onDragOver($event)"
  (mouseleave)="onMouseLeave($event)">
  <header class="layout-row c-between flex-shrink bdr-bottom bdr-gray4">
    <div class="layout-row area-90">
      <span class="ft-size-4 ft-weight-bold">Storage</span>
    </div>
    <div class="img-box-40 circle s-center">
      <img src="./../../assets/img/user.jpg">
    </div>
  </header>

  <!-- 未登録時 -->
  <!-- <div class="layout-column i-center c-center height-84">
    <div class="layout-column i-center c-center area-400px height-300px bdr-box bdr-radius bdr-ini">
      <span class="ft-size-5 ft-weight-bold">Select or Drag and Drop</span>
    </div>
  </div> -->

  <div class="layout-row i-center flex-shrink box-w-p20 height-50px bg-w bdr-bottom bdr-gray4 shadow-bottom">
    <button class="btn-main middle area-100px" (click)="onAddMaterial()">
      新規登録
    </button>
    <i (click)="isListView = true" class="jam jam-unordered-list ml-40 ft-size-6 pointer"></i>
    <i (click)="isListView = false" class="jam jam-grid ml-10 ft-size-6 pointer"></i>
    <i *ngIf="dirId" (click)="onAddDirectory()" class="jam jam-folder-open ml-40 ft-size-6 pointer"></i>
  </div>
  <div class="layout-column hidden-box">
    <!-- Project単位でディレクトリを切る仕様のためフィルター一旦削除 -->
    <!-- <div class="layout-row i-center flex-shrink box-w-p20 height-46px bg-w color-gray1 shadow-bottom">
      <button class="btn-bdr middle area-min-80px"
       [class.selected]="selectedProjectIds.length === storeSidemenu.projects.length"
       (click)="onClickAllProject()">
       全て
      </button>
      <button *ngFor="let project of storeSidemenu.projects; index as i;"
        class="btn-bdr middle area-min-80px area-max-120px ml-10"
        [class.selected]="selectedProjectIds.indexOf(project.id) >= 0"
        (click)="onClickProjectFilter(project)">
        <div class="img-box-18 rect">
          <img *ngIf="project.imageUrl !== null" [src]="project.imageUrl">
        </div>
        <span class="ml-6 text-ellipsis">{{project.name}}</span>
      </button>
    </div> -->
    <!-- リスト表示 -->
    <ng-container *ngIf="isListView; else templateGallery" >
      <div class="layout-row i-center flex-shrink box-w-p20 height-34px bg-w color-gray1 ft-size-1 shadow-bottom">
        <div class="flex-shrink area-30px"><i class="jam jam-arrow-square-down ft-size-3"></i></div>
        <div class="area-30 ml-10">名前</div>
        <div class="area-10 ml-10">サイズ</div>
        <div class="area-10 ml-10">更新日</div>
        <div class="area-15 ml-10">更新者</div>
        <div class="area-17 ml-10">ステータス</div>
        <div class="area-18 ml-10">サムネイル</div>
        <div class="area-18 ml-10">version</div>
        <div class="area-18 ml-10">pipeline名</div>
        <div class="flex-shrink area-20px ml-20"></div>
      </div>
      <div class="scroll-container" appScrollbar>
        <ul class="scroll-y">
          <li *ngFor="let row of creatives" class="list-row layout-row i-center box-w-p20 height-80px bg-w" (dblclick)="onDblClick(row)">
            <div class="flex-shrink area-30px">
              <i class="jam ft-size-5"
                [class.jam-picture]="row.type === CreativeType.FILE"
                [class.jam-folder]="row.type === CreativeType.DIRECTORY"></i>
            </div>
            <div class="area-30 ml-10 text-ellipsis2">{{row.name}}</div>
            <div class="area-10 ml-10">
            <div>{{ row.size | size: 'size' }}<span class="ml-2 ft-size-1">{{ row.size | size: 'unit' }}</span></div>
              <div *ngIf="row.type === CreativeType.FILE">{{row.width}} x {{row.height}}</div>
            </div>
            <div class="area-10 ml-10">{{row.updatedAt | date:"yyyy/MM/dd"}}</div>
            <div class="area-15 ml-10">{{row.user.name}}</div>
            <div class="area-17 ml-10" [innerHTML]="convert(row.cases)"></div>
            <div class="area-18 ml-10">
              <div class="img-thumb height-60px">
                <img src="{{row.url}}">
              </div>
            </div>
            <div class="area-15 ml-10">{{row?.version}}</div>
            <div class="area-15 ml-10">{{row?.pipelineName}}</div>
            <div class="flex-shrink area-20px ml-20 text-center">
              <i class="jam jam-more-horizontal"></i>
            </div>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-template #templateGallery>
      <!-- ギャラリー表示 -->
      <div class="scroll-container" appScrollbar>
        <div class="scroll-y">
          <div class="box-p10 layout-row flex-wrap">
            <div *ngFor="let row of creatives" class="box-p10">
              <div class="layout-column i-center box-p5 area-218px bg-gray4 bdr-box bdr-gray3 bdr-radius">
                <div class="img-thumb layout-column i-center c-center flex-shrink box-w-p5 height-140px mt-10">
                  <img src="{{row.url}}">
                </div>
                <div class="layout-column c-center box-p5 height-40px ft-size-1 text-center">
                  <span class="text-ellipsis2">{{row.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <div id="drop-layer" *ngIf="isDragOver"></div>

</div>
