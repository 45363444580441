import { Injectable } from '@angular/core';
import { Subject, interval } from 'rxjs';

export class StorePopover {

  /* Subject */
  private _changeIsShow = new Subject<boolean>();
  public changeIsShow = this._changeIsShow.asObservable();
  /* Subject */

  private _triggerElement: HTMLElement
  private _isShow = false

  get triggerElement(): HTMLElement {
    return this._triggerElement;
  }
  get isShow(): boolean {
    return this._isShow;
  }

  set triggerElement(val: HTMLElement) {
    this._triggerElement = val;
  }
  set isShow(val: boolean) {
    this._isShow = val;
  }

  constructor() {
    console.log('constructor')
  }
  
  onClick = (event: PointerEvent) => {
    event.stopPropagation()
  
    const parentPopover = this.triggerElement.closest('.rd-popover-body')
    // Popover ON Popoverの場合は他のPopoverを閉じない
    if (!parentPopover) {
      // 他のPopoverを消す
      if (!this.isShow) {
        // clickoutside内でbodyを無視しているため#appを対象としている
        // TODO: 構造に依存するため検討した方がよい
        document.getElementById('app')?.click()
        // document.body.click()
      }
    }
    this.isShow = !this.isShow

    this._changeIsShow.next(this.isShow)
  }

  onMouseOver = () => {
    if (!this._isShow) {
      this._isShow = true
      this._changeIsShow.next(this.isShow)
    }
  }
  
  onFocus = (event: PointerEvent) => {
    event.stopPropagation()
    // 他のPopoverを消す
    if (!this._isShow) {
      document.body.click()
    }
    this._isShow = true
    this._changeIsShow.next(this.isShow)
  }

}
