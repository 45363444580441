import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICreative } from 'src/app/logic/interface/creative';
import { DataCreative } from 'src/app/logic/data/creative.data';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { CommonCreativeListComponent } from '../../common/creative-list.component';


@Component({
  selector: 'app-modal-select-material',
  templateUrl: './select-material.component.html',
})

export class ModalSelectMaterialComponent extends CommonCreativeListComponent implements OnInit {

  selectedCreative: ICreative;

  constructor(
    public dialogRef: MatDialogRef<ModalSelectMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public storeSidemenu: StoreSidemenu,
    public dataCreative: DataCreative,
  ) {
    super(storeSidemenu, dataCreative);
  }

  ngOnInit() {
    this.getCreatives();
    this.changeList.subscribe(() => {
      if (!this.selectedCreative && this.creatives.length > 0) {
        this.selectedCreative = this.creatives[0];
      }
    });
  }

  onClickCreative(creative: ICreative) {
    this.selectedCreative = creative;
  }

  onPostMaterial() {
    this.dialogRef.close(this.selectedCreative);
  }
}


