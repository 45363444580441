import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LibHttp } from '../lib/http.lib';
import { IDBParty } from 'src/app/logic/interface/party';

@Injectable({
  providedIn: 'root'
})
export class DataInvitation {

  constructor(private http: LibHttp) { }

  getInvitedPartyData(code: string): Observable<IDBParty> {
    return this.http.get(`invitation/${code}`, {});
  }

  postInvitedUserToPartyUser(code: string, name: string): Observable<IDBParty> {
    const param = {
      code,
      name
    }
    return this.http.post(`invitation/accept`, param);
  }

}
