import { Injectable } from '@angular/core';

import { Observable, from } from 'rxjs';

import { LibHttp } from '../lib/http.lib';
import { ICreative, IRequestPostCreative, IRequestPostDirectory, IResponsePostCreative } from 'src/app/logic/interface/creative';

@Injectable({
  providedIn: 'root'
})
export class DataCreative {

  constructor(private http: LibHttp) { }

  public addCreative(params: IRequestPostCreative): Observable<IResponsePostCreative> {
    return this.http.post('creative', params);
  }

  public addCreativeWithFile(params: IRequestPostCreative): Observable<IResponsePostCreative> {
    return from(this.http.postWithFile('creative2', params))
  }

  public addDirectory(params: IRequestPostDirectory): Observable<ICreative> {
    return this.http.post('creative/directory', params);
  }

  public getCreatives(directoryId: string | null, projectIds: string[]): Observable<ICreative[]> {
    const params: any = { projectIds };
    if (directoryId) {
      params.directoryId = directoryId;
    }
    return this.http.get('creative', params);
  }

  public getDirectory(directoryId: string): Observable<ICreative> {
    return this.http.get(`creative/${directoryId}`, {});
  }

}
