import { Directive, ElementRef, HostListener, Input } from '@angular/core';

export enum MouseEventType {
  MOUSE_ENTER = 'mouseenter',
  MOUSE_LEAVE = 'mouseleave',
}

@Directive({
  selector: '[appEvent]'
})
export class MouseEventDirective {
  constructor(private el: ElementRef) { }

  /**
   * html
   * <p appEvent [onMouseEvent]="mouseEvent">
   *
   * ts
   * mouseEvent = (eventType: MouseEventType) => {
   * }
   */
  @Input() onMouseEvent: (event: MouseEventType) => void;

  @HostListener('mouseenter') onMouseEnter() {
    this.onMouseEvent(MouseEventType.MOUSE_ENTER);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.onMouseEvent(MouseEventType.MOUSE_LEAVE);
  }

}
