import { Component, Inject, InjectionToken, OnInit } from '@angular/core';

export interface ILoadingConfig {
  message: string;
}
export const LOADING_DATA = new InjectionToken<ILoadingConfig>('LoadingData');

@Component({
  selector: 'app-common-loading',
  templateUrl: './loading.component.html',
})
export class LoadingComponent implements OnInit {

  message = '';

  constructor(@Inject(LOADING_DATA) public data: ILoadingConfig) {
    this.message = data.message;
  }

  ngOnInit() {
  }
}
