import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalAddPipelineComponent } from '../../../modal/add-pipeline/add-pipeline.component';
import { ICase } from 'src/app/logic/interface/case';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, interval } from 'rxjs';
import { DataPipeline } from 'src/app/logic/data/pipeline.data';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { partyId } from 'src/app/logic/helper/util';
import { environment } from 'src/environments/environment';
import { CasePipelineComponent } from './pipeline/pipeline.component';
import { IPipelineMessage } from 'src/app/logic/interface/pipeline-message';
import { TSocketEvent } from 'src/app/logic/definition/socket-event';
import { IPipeline } from 'src/app/logic/interface/pipeline';
import { StoreWebSocket } from 'src/app/logic/store/websocket.store';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  host: {
    class: 'dn-workspace-container',
  }
})
export class CaseComponent implements OnInit, OnDestroy {

  @ViewChildren(CasePipelineComponent) public pipelineComponents: QueryList<CasePipelineComponent>;

  public case: ICase

  public pipeLines: IPipeline[] = []

  private destroyed = new Subject<any>()
  
  constructor(
    public storeSidemenu: StoreSidemenu,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private dataPipeline: DataPipeline,
    private storeWebSocket: StoreWebSocket,
  ) { }

  ngOnInit() {
    const favicon = document.getElementById('favicon')
    favicon.setAttribute('href', 'assets/img/favicon.ico')

    this.activatedRoute.params.subscribe((params) => {
      const caseId = params.cid;

      if (!this.storeSidemenu.projects) {
        const ref = interval(500).subscribe(() => {
          if (!this.storeSidemenu.projects) {
            return
          }
          ref.unsubscribe()
          this.initialize(caseId)
        })

      } else {
        this.initialize(caseId)
      }
    });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
          console.log('タブに戻ってきました')
          favicon.setAttribute('href', 'assets/img/favicon.ico')
      } else {
          console.log('タブを離れました');
      }
  });

    // TODO: 2回目移行キャンペーンをクリックした場合のリロード処理
    // this.router.events.pipe(
    //   filter((event: RouterEvent) => event instanceof NavigationEnd),
    //   takeUntil(this.destroyed),
    // ).subscribe(() => {
    //   this.case = this.transitionCase.case;
    //   this.getPipeLines();
    // });
  }

  ngOnDestroy() {
    this.destroyed.next(void 0);
    this.destroyed.complete();
  }

  onAddPipeline() {
    const dialogRef = this.dialog.open(ModalAddPipelineComponent, {
      maxHeight: '90vh',
      data: this.case
    })
    dialogRef.afterClosed().subscribe((newPipeline?: IPipeline) => {
      if (newPipeline) {
        this.addPipeline(newPipeline)
      }
    })
  }

  onDeletePipeline(pipelineId: string) {
    const idx = this.pipeLines.findIndex((row) => row.id === pipelineId)
    if (idx >= 0) {
      this.pipeLines.splice(idx, 1)
    }
  }

  getTotalFileCount() {
    return this.pipeLines.reduce((sum: number, curr) => {
      return sum + curr.fileCount
    }, 0)
  }

  getTotalCompleteCount() {
    return this.pipeLines.reduce((sum: number, curr) => {
      return curr.isComplete ? sum + 1 : sum
    }, 0)
  }

  getTotalUncompleteCount() {
    return this.pipeLines.reduce((sum: number, curr) => {
      return curr.isComplete ? sum : sum + 1
    }, 0)
  }

  onChangeText() {
    console.log('onChangeText')

  }

  onKeypress() {
    console.log('onKeypress')
  }

  onKeyup() {
    console.log('onKeyup')
  }

  private initialize(caseId: string) {
    this.case = this.storeSidemenu.getCase(caseId)
    if (!this.storeSidemenu.selectedCase) {
      setTimeout(() => {
        this.storeSidemenu.selectedCase = this.case
      })
    }
    this.getPipeLines()
    this.connectWebsocket()
  }

  private getPipeLines() {
    this.dataPipeline.getPipelines(partyId(), this.case.id).subscribe((results) => {
      console.log(results)
      this.pipeLines = results
    })
  }

  private connectWebsocket() {
    
    this.storeWebSocket.socket.emit('join', { caseId: this.case.id })

    // コメントがあった時に呼ばれる
    this.storeWebSocket.socket.on<TSocketEvent>('message_post', (data: IPipelineMessage) => {
      console.log('message_post', data)
      const target = this.pipelineComponents.find((pipelineComponent) => pipelineComponent.pipeline.id === data.pipelineId)
      if (target) {
        target.receiveNewMessage(data)
      }
    })
    // パイプライン追加時
    this.storeWebSocket.socket.on<TSocketEvent>('pipeline_post', (data: IPipeline) => {
      console.log('pipeline_post', data)
      this.addPipeline(data)
    })
    // パイプライン削除時
    this.storeWebSocket.socket.on<TSocketEvent>('pipeline_delete', (data: IPipeline) => {
      this.onDeletePipeline(data.id)
    })
    // パイプライン完了時
    this.storeWebSocket.socket.on<TSocketEvent>('pipeline_complete', (data: IPipeline) => {
      console.log('pipeline_complete', data)
      
      const targetIndex = this.pipeLines.findIndex((row) => row.id === data.id)
      if (targetIndex < 0) return
      const targets = this.pipeLines.splice(targetIndex, 1)

      const index = this.pipeLines.findIndex((row) => row.isComplete && (new Date(row.latestMessagePosted)).getTime() < (new Date(data.latestMessagePosted)).getTime())
      if (index >= 0) {
        this.pipeLines.splice(index, 0, targets[0])
      } else {
        this.pipeLines.push(targets[0])
      }

    })
    // パイプライン復元時
    this.storeWebSocket.socket.on<TSocketEvent>('pipeline_restore', (data: IPipeline) => {
      console.log('pipeline_restore', data)
      
      const targetIndex = this.pipeLines.findIndex((row) => row.id === data.id)
      if (targetIndex < 0) return
      const targets = this.pipeLines.splice(targetIndex, 1)

      const index = this.pipeLines.findIndex((row) => !row.isComplete && (new Date(row.latestMessagePosted)).getTime() < (new Date(data.latestMessagePosted)).getTime())
      if (index >= 0) {
        this.pipeLines.splice(index, 0, targets[0])
      } else {
        const index2 = this.pipeLines.findIndex((row) => row.isComplete)
        if (index2 >= 0) {
          this.pipeLines.splice(index2, 0, targets[0])
        } else {
          this.pipeLines.push(targets[0])
        }
      }
    })
  }

  private addPipeline(pipeline: IPipeline) {
    const exist = this.pipeLines.slice(-5).find((p) => p.id === pipeline.id)
    if (!exist) {
      this.pipeLines.unshift(pipeline)
    }
  }
}
