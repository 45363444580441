<div class="layout-column c-between area-min-400px height-100">
  <div class="layout-row i-center flex-shrink box-w-p20 height-50px">
    <p class="ft-size-6 ft-weight-bold">Post</p>
    <button [mat-dialog-close]="false">
      <i class="jam jam-close close-icon ft-size-6"></i>
    </button>
  </div>
  <div *ngIf="inputData" class="layout-column hidden-box">
    <!-- <div *ngIf="data.latestVersion">
      <p style="color: red">同一パイプライン内に同一ファイル名のversion{{data.latestVersion}}が存在しています。ファイル名を変更するかversionを変更してください。</p>
    </div> -->

    <div class="layout-row box-w-p10">
      <div class="box-w-p10 area-100">
        <div class="layout-column">
          <span class="color-gray1 ft-size-1">投稿パイプライン</span>
          <mat-form-field class="mt-4 ft-size-1">
            <mat-select disableRipple [(value)]="inputData.pipelineId">
              <mat-option *ngFor="let pipeline of pipelineOptions" [value]="pipeline.id">{{pipeline.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- TODO: Directory選択 -->
        <!-- <div class="layout-column mt-10">
          <span class="color-gray1 ft-size-1">ディレクトリ</span>
          <mat-form-field class="mt-4 ft-size-1">
            <mat-select disableRipple>
              <mat-option value="全て">全て</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->

        <!-- TODO: Versionの入力方法検討 -->
        <div class="layout-column mt-10">
          <span class="color-gray1 ft-size-1">Version</span>
          <input class="mt-4" [(ngModel)]="inputData.version">
        </div>

        <div class="img-thumb c-center height-160px mt-10 bg-gray4 bdr-box bdr-ini bdr-radius hidden-box">
          <ng-container *ngIf="inputData.files">
            <img
              *ngIf="isImage(inputData.files[0].type); else templateIsNotImage"
              appImage [file]="inputData.files[0]" #image>
            <ng-template #templateIsNotImage>
              <div [ngSwitch]="fileType(inputData.files[0].type)">
                <ng-container *ngSwitchCase="'pdf'">
                  PDF
                </ng-container>
                <ng-container *ngSwitchCase="'ppt'">
                  PPT/PPTX
                </ng-container>
                <ng-container *ngSwitchCase="'xls'">
                  XLS/XLSX
                </ng-container>
                <ng-container *ngSwitchDefault>
                  未判定のファイル形式
                </ng-container>
              </div>
            </ng-template>
            <div class="img-title box-p5 text-center">
              {{inputData.files[0].name}}
            </div>
          </ng-container>
          <ng-container *ngIf="inputData.creative">
            <img [src]="inputData.creative.url">
            <div class="img-title box-p5 text-center">
              {{inputData.creative.name}}
            </div>
          </ng-container>
        </div>
      </div>
      
    </div>
    <div class="box-w-p20 mt-10">
      <div class="bdr-box bdr-radius">
        <div role="textbox" contenteditable="true" aria-multiline="true"
          class="layout-column box-p10 height-max-300px height-80px text-area hidden-box">
          <div class="scroll-container" appScrollbar>
            <div class="scroll-y" #messageBox>
              <p><br></p>
            </div>
          </div>
        </div>
        <!-- <div class="layout-row i-center c-between flex-shrink box-w-p10 height-36px bdr-top">
          <button class="btn-dashed small">
            versionを変更
          </button>
        </div> -->
      </div>
    </div>
  </div>

  <div class="layout-row i-center c-end flex-shrink box-w-p20 height-60px shadow-top">
    <button class="btn-main middle area-80px" (click)="onSubmit()"
      [ngClass]="{'disabled': !validate()}"
      [attr.disabled]="!validate() || null">
      送信
    </button>
  </div>
</div>
