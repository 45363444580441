<!-- 全画面共通 -->
<div
  id="donatello-ui"
  #rootRef
  [class.is-collapsed__sidebar]="storeSidemenu.isCollapsed">
  <div class="dn-common-nav">
    <div class="dn-common-nav__center">
      <div [class.hoge]="storeSidemenu.isCollapsed">search</div>
    </div>
    <div class="dn-common-nav__right">
      <div class="dn-icon-user">
        <img src="./../../assets/img/user.jpg">
      </div>
    </div>
  </div>
  <!-- サイドバーの開閉の状態に応じてclassを切り替える -->
  <div 
    id="dn-sidebar-container"
    *ngIf="isShowSideMenu"
    app-root-side-menu
    [class.is-collapsed__sidebar]="storeSidemenu.isCollapsed"></div>

  <div id="dn-main-container">
    <!-- パスによって切り替わる部分 -->
    <router-outlet></router-outlet>
  </div>
</div>
