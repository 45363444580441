import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { LibHttp } from '../lib/http.lib'
import { map } from 'rxjs/operators'
import { IPipelineFile, IPipelineMessage } from 'src/app/logic/interface/pipeline-message'
import { partyId } from '../helper/util'

@Injectable({
  providedIn: 'root'
})
export class DataPipelineMessage {

  constructor(private http: LibHttp) { }

  public addPipelineMessage(
    caseId: string,
    pipelineId: string,
    message: string,
    creatives: { creativeId: string, version: string }[],
    urls?: string[],
  ): Observable<IPipelineMessage> {
    let params = {
      message,
      partyId: partyId(),
      caseId,
      creatives,
    }
    if (urls && urls.length > 0) {
      params['urls'] = urls
    }
    return this.http.post(`pipeline/${pipelineId}/message`, params).pipe(
      map(
        (response: any) => {
          const pipelineMessage: IPipelineMessage = response.message
          return pipelineMessage
        })
    )
  }

  public getVersions(pipelineId: string | null, fileName: string): Observable<string[]> {
    const params = {
      fileName,
    }
    return this.http.get(`pipeline/${pipelineId}/version`, params).pipe(
      map((res) => {
        return res.versions
      })
    )
  }

  public getFiles(pipelineId: string): Observable<IPipelineFile[]> {
    return this.http.get(`pipeline/${pipelineId}/files`, {})
  }

}
