import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LibHttp } from '../lib/http.lib';
import { ICase, IRequestPostCase } from 'src/app/logic/interface/case';

@Injectable({
  providedIn: 'root'
})
export class DataCase {

  constructor(private http: LibHttp) { }

  public addCase(params: IRequestPostCase): Observable<{ case: ICase, imageUrl: string }> {
    return this.http.post('case', params);
  }

  public deleteCase(partyId: string, caseId: string) {
    return this.http.delete(`case/${caseId}?partyId=${partyId}`, {});
  }

}
