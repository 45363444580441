import { Component, OnInit } from '@angular/core';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {

  constructor(public store: StoreSidemenu) { }

  ngOnInit() { }

}
