<div class="layout-row height-100vh bg-w">
    <div class="layout-column c-center area-100 bg-highlight1">
      <div [formGroup]="formGroup" class="area-50 ml-10">
        <p>party作成</p>
        <input class="mt-10" formControlName="name" type="text" placeholder="party名" />
        <input class="mt-10" formControlName="partyUserName" type="text" placeholder="partyUser名" />
        <button class="btn-main area-120px mt-20"
          #tooltip="matTooltip"
          matTooltip
          matTooltipPosition="above"
          (click)="postParty(tooltip)">
          パーティ作成
        </button>
      </div>
    </div>
  </div>
  