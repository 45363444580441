import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IPipeline } from 'src/app/logic/interface/pipeline';
import { DataPipelineMessage } from 'src/app/logic/data/pipeline-message.data';
import { IPipelineFile, IPipelineMessage } from 'src/app/logic/interface/pipeline-message';
import { StoreSession } from 'src/app/logic/store/session.store';
import { ICase } from 'src/app/logic/interface/case';
import { Subject, interval } from 'rxjs';
import { LibAlert } from 'src/app/logic/lib/alert.lib';
import { DataPipeline } from 'src/app/logic/data/pipeline.data';
import { CasePipelinePostAreaComponent } from './post-area/post-area.component';
import { WebpushService } from 'src/app/logic/webpush.service';
import { partyId } from 'src/app/logic/helper/util';

@Component({
  selector: '[app-case-pipeline]',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.css'],
})
export class CasePipelineComponent implements OnInit, OnChanges {

  @Input() case: ICase
  @Input() pipeline: IPipeline
  @Output() deletePipeline = new EventEmitter<string>();

  @ViewChild(CasePipelinePostAreaComponent) postArea: CasePipelinePostAreaComponent
  @ViewChild('scrollArea', { static: false }) scrollArea: ElementRef

  changeLayout = new Subject<void>();

  isInputFocus = false
  isDragOver = false
  fileSets: { root: IPipelineFile, history: IPipelineFile[]}[] = []
  rockUpdate = false

  constructor(
    public session: StoreSession,
    private dataPipeline: DataPipeline,
    private dataPipelineMessage: DataPipelineMessage,
    private alert: LibAlert,
    private webpushService: WebpushService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pipeline) {
      setTimeout(() => {
        this.scrollBottom()
      }, 50);
    }
  }

  // ステータスを完了にする
  onSwitchStatus() {
    if (!this.pipeline.isComplete) {
      const ref = this.alert.showConfirm('完了', '完了しますか？', '完了する', '完了しない')
      ref.afterClosed().subscribe((result) => {
        if (result !== true) {
          return
        }
        this.dataPipeline.completePipeline(this.pipeline.id).subscribe({
          next: () => {
            this.pipeline.isComplete = true


          },
          error: (e) => {
            this.alert.showNotice('エラー', '完了できませんでした。', '閉じる')
          }
        })
      })
    } else {
      const ref = this.alert.showConfirm('戻す', '進行中に戻しますか？', '戻す', 'しない')
      ref.afterClosed().subscribe((result) => {
        if (result !== true) {
          return
        }
        this.dataPipeline.restorePipeline(this.pipeline.id).subscribe({
          next: () => {
            this.pipeline.isComplete = false
          },
          error: (e) => {
            this.alert.showNotice('エラー', '完了できませんでした。', '閉じる')
          }
        })
      })
    }
  }

  onDeletePipeline() {
    const ref = this.alert.showConfirm('確認', '削除しますか？', '削除する', '削除しない')
      ref.afterClosed().subscribe((result) => {
        if (result !== true) {
          return
        }
        this.dataPipeline.deletePipeline(this.pipeline.id).subscribe({
          next: () => {
            this.deletePipeline.emit(this.pipeline.id)
          },
          error: (e) => {
            this.alert.showNotice('エラー', '完了できませんでした。', '閉じる')
          }
        })
      })
  }

  // 履歴を表示する
  onDisplayHistory() {
    console.log('TODO: 履歴表示')
    this.dataPipelineMessage.getFiles(this.pipeline.id).subscribe((results) => {
      this.fileSets = []
      const dic: { [key: string]: IPipelineFile[] } = {}
      results.forEach((row) => {
        if (!(row.name in dic)) {
          dic[row.name] = []
        }
        dic[row.name].push(row)
      })
      Object.entries(dic).forEach(([key, values], i) => {
        this.fileSets.push({
          root: values[0],
          history: values.splice(1)
        })
      })
      console.log(this.fileSets)

    })
  }

  // 親Componentから呼ばれる
  public receiveNewMessage(newMessage: IPipelineMessage) {
    setTimeout(() => {
      this.addMessage(newMessage)
    }, 500);
  }

  onSendMessage() {
    const sub = interval(100).subscribe({
      next: (i) => {
        this.scrollBottom()
        if (i > 10) {
          sub.unsubscribe()
        }
      }
    })
    
  }

  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    
    this.isDragOver = true;
  }

  onDragLeave(event: MouseEvent) {
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isDragOver = false
    const { files } = event.dataTransfer
    this.postArea.onDropedFile(files)
  }

  onScrolled(e: Event) {
    // 過去のメッセージを取得する (重複して取らないように注意？)
    if (this.scrollArea.nativeElement.scrollTop < 100) {
      if (this.rockUpdate) {
        return
      }
      console.log('getOlderMessage')
      this.rockUpdate = true
      this.dataPipeline.getPipelineMessages(partyId(), this.pipeline.id, this.pipeline.messages[0].id).subscribe((results) => {
        
        // console.log(results)
        // this.pipeline.messages.unshift(...results)

        results.reverse().forEach((row) => {
          this.pipeline.messages.unshift(row)
        })



        this.rockUpdate = false
      })
    }

  }

  private addMessage(newMessage: IPipelineMessage) {
    // 重複を避けるための処理
    const target = this.pipeline.messages.slice(-5).find((m) => m.id === newMessage.id)
    if (!target) {
      this.pipeline.messages.push(newMessage)

      if (newMessage.creatives.length > 0) {
        this.pipeline.fileCount = this.pipeline.fileCount + newMessage.creatives.length
      }
      setTimeout(() => {
        this.changeLayout.next()
        this.scrollBottom()
      }, 500);
    }
  }

  private scrollBottom() {
    if (!this.scrollArea) {
      return
    }
    this.scrollArea.nativeElement.scrollTop = this.scrollArea.nativeElement.scrollHeight - this.scrollArea.nativeElement.clientHeight
  }

}
