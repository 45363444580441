import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModelPartyUser } from 'src/app/logic/model/party-user.model';
import { LibLoading } from 'src/app/logic/lib/loading.lib';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { DataPartyUser } from 'src/app/logic/data/party-user.data';

@Component({
  selector: 'app-modal-add-project-party',
  templateUrl: './add-project-party-user.component.html',
})

export class ModalAddProjectPartyUserComponent implements OnInit {

  image: File;

  formGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
    ]),
    description: new FormControl(''),
  });

  anyOneOfChecked: boolean = Object.values(this.storeSidemenu.settingPartyUsersCheckList).find((val) => val === true)

  constructor(
    public storeSidemenu: StoreSidemenu,
    public dialogRef: MatDialogRef<ModalAddProjectPartyUserComponent, boolean>,
    private dataPartyUser: DataPartyUser,
    private loading: LibLoading,
    private modelPartyUser: ModelPartyUser,
  ) { }

  ngOnInit() {
  }

  onChecked(partyUserId: string) {
    this.storeSidemenu.settingPartyUsersCheckList[partyUserId] = !this.storeSidemenu.settingPartyUsersCheckList[partyUserId]
    this.anyOneOfChecked = Object.values(this.storeSidemenu.settingPartyUsersCheckList).find((val) => val === true)
  }

  onSubmit() {
    this.loading.show();
    const partyId: string = this.storeSidemenu.settingProject.partyId
    const projectId: string = this.storeSidemenu.settingProject.id
    let partyUserIds: string[] = []
    this.storeSidemenu.settingPartyUsersOfNotJoinedProject.forEach((partyUser) => {
      if (this.storeSidemenu.settingPartyUsersCheckList[partyUser.id]) {
        partyUserIds.push(partyUser.id)
      }
    })
    this.modelPartyUser.addProjectPartyUser(partyId, projectId, partyUserIds).subscribe(
      () => {
        // storeの値を更新する
        this.storeSidemenu.settingPartyUsers.filter((p) => partyUserIds.includes(p.id)).forEach((partyUser) => {
          this.storeSidemenu.settingProjectPartyUsers.push(partyUser)
        })
        const projectPartyUserIds = this.storeSidemenu.settingProjectPartyUsers.map((projectPartyUser) => projectPartyUser.id)
        this.storeSidemenu.settingPartyUsersOfNotJoinedProject = this.storeSidemenu.settingPartyUsers.filter((partyUser) => !projectPartyUserIds.includes(partyUser.id))
        let checkList: { [key: string]: boolean } = {}
        this.storeSidemenu.settingPartyUsersOfNotJoinedProject.forEach((projectPartyUser) => {
          checkList[projectPartyUser.id] = false
        })
        this.storeSidemenu.settingPartyUsersCheckList = checkList
        this.loading.hide();
        this.dialogRef.close(true);
      },
      (error) => {
        this.loading.hide();
        alert('エラー');
      }
    );
  }
}


