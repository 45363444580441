import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { ModelParty } from 'src/app/logic/model/party.model';

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
})
export class PartyComponent {

  formGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
    ]),
    partyUserName: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(
    private modelParty: ModelParty,
    private router: Router,
  ) { }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe((value: { [key: string]: string }) => {
      // TODO: validate
    });
  }

  postParty(tooltip: MatTooltip) {
    if (!this.formGroup.valid) {
      return;
    }
    const name: string = this.formGroup.get('name').value
    const partyUserName: string = this.formGroup.get('partyUserName').value
    this.modelParty.postParty(name, partyUserName).subscribe({
      next: (result) => {
        location.href = `/party/${result}`
      },
      error: (error) => {
        // 登録失敗
        console.error(error);
        tooltip.message = error.error.message;
        tooltip.show();
        setTimeout(() => {
          tooltip.hide();
          tooltip.message = '';
        }, 2000);
      }
    });
  }

}
