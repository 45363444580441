import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalSelectMaterialComponent } from 'src/app/component/modal/select-material/select-material.component';
import { IPipeline } from 'src/app/logic/interface/pipeline';
import { DataPipelineMessage } from 'src/app/logic/data/pipeline-message.data';
import { IPipelineMessage } from 'src/app/logic/interface/pipeline-message';
import { ModalPostMaterialComponent } from 'src/app/component/modal/post-material/post-material.component';
import { StoreSession } from 'src/app/logic/store/session.store';
import { ICase } from 'src/app/logic/interface/case';
import { Observable, Subject, Subscriber, concatMap, from, map, of } from 'rxjs';
import { LibAlert } from 'src/app/logic/lib/alert.lib';
import { DataPipeline } from 'src/app/logic/data/pipeline.data';
import { TextEditorComponent } from 'src/app/component/view/project/case/pipeline/post-area/text-editor/text-editor.component';
import { StoreTextEditor } from 'src/app/component/view/project/case/pipeline/post-area/text-editor/text-editor.store';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { IRequestPostCreative } from 'src/app/logic/interface/creative';
import { partyId } from 'src/app/logic/helper/util';
import { ModelPipeline } from 'src/app/logic/model/pipeline.model';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist'
// この設定しないとエラーになる
GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js'

@Component({
  selector: '[app-case-pipeline-post-area]',
  templateUrl: './post-area.component.html',
  providers: [StoreTextEditor]
})
export class CasePipelinePostAreaComponent implements OnInit {
  @Input() case: ICase
  @Input() pipeline: IPipeline
  @Output() sendMessage = new EventEmitter<void>();
  
  @ViewChild(TextEditorComponent) editorComponent: TextEditorComponent
  @ViewChild('scrollArea', { static: false }) scrollArea: ElementRef

  changeLayout = new Subject<void>();

  isInputFocus = false;

  fileDataList: { file: File, version: string  }[] = []
  creativeList: { creativeId: string, version: string }[] = []

  isUploading = false
  
  private inputFileElement: HTMLInputElement;

  constructor(
    public session: StoreSession,
    public storeTextEditor: StoreTextEditor,
    private storeSidemenu: StoreSidemenu,
    private dialog: MatDialog,
    private dataPipeline: DataPipeline,
    private dataPipelineMessage: DataPipelineMessage,
    private el: ElementRef,
    private alert: LibAlert,
    private modelPipeline: ModelPipeline,
  ) { }

  ngOnInit() {
    this.storeTextEditor.pipelineId = this.pipeline.id
  }

  onSelectMaterial() {
    const dialogRef = this.dialog.open(ModalSelectMaterialComponent, {
      maxHeight: '90vh',
    });
    dialogRef.afterClosed().subscribe(creative => {
      if (!creative) {
        return;
      }
      const dialogRef = this.dialog.open(ModalPostMaterialComponent, {
        maxHeight: '90vh',
        data: {
          imageType: 'creative',
          pipeline: this.pipeline,
          creative,
        },
      });
      dialogRef.afterClosed().subscribe(pipelienMessage => {
        if (pipelienMessage) {
          this.pipeline.messages.push(pipelienMessage);
          // TODO: 一番下にスクロールする
        }
      });
    });
  }

  onSelectLocalFile() {
    // ファイル選択UI表示
    this.inputFileElement = document.createElement("input");
    this.inputFileElement.setAttribute("type", "file");
    this.inputFileElement.multiple = true
    // input.setAttribute("accept", "text/csv");
    this.inputFileElement.hidden = true;
    this.inputFileElement.onchange = this.onSelectedFile;
    this.el.nativeElement.appendChild(this.inputFileElement);
    this.inputFileElement.click();
  }

  async onSendMessage() {
    const messageElem = this.editorComponent.getMessageElement()
    const message = this.editorComponent.getMessageContent()
    const aTags = messageElem.getElementsByTagName('a')
    const urls: string[] = []
    if (aTags.length > 0) {
      Array.from(aTags).forEach((aTag) => {
        urls.push(aTag.href)
      })
    }
    this.editorComponent.reset()

    if (this.fileDataList.length > 0) {
      this.fileDataList.length = 0
      const caseObj = this.storeSidemenu.getCase(this.pipeline.caseId)

      const now = this.getCurrentDateTime()
      const tempMessage: IPipelineMessage = {
        id: null,
        pipelineId: this.pipeline.id,
        message,
        created: now,
        updated: now,
        partyUser: {
          id: this.session.nowPartyUser.id,
          name: this.session.nowPartyUser.name,
          profileImage: this.session.nowPartyUser.profileImage,
        },
        creatives: [],
        metaData: null,
      }

      // 先にメッセージを追加する
      this.addMessage(tempMessage)
      this.sendMessage.emit()

      from(this.wait()).pipe(
        concatMap(() => {
          return this.modelPipeline.sendMessageWithLocalImage(caseObj.id, this.pipeline.id, message, this.creativeList)
        })
      ).subscribe({
        next: (result) => {
          const targetMessage = this.pipeline.messages.find((m) => m.id === null)
          targetMessage.id = result.id
          targetMessage.created = result.created
          targetMessage.updated = result.updated
          targetMessage.metaData = result.metaData
          targetMessage.creatives = result.creatives
          // this.addMessage(result)
          this.creativeList.length = 0
        },
        complete: () => {
          console.log('Complete♪')
          this.sendMessage.emit()
        },
        error: (error) => {
          console.error(error);
          this.alert.showNotice('エラー', '投稿できませんでした', '閉じる')
        },
      });

    } else {
      this.dataPipelineMessage.addPipelineMessage(this.case.id, this.pipeline.id, message, [], urls).subscribe(
        (pipelienMessage: IPipelineMessage) => {
          this.addMessage(pipelienMessage)
          this.sendMessage.emit()
        },
        (error) => {
          this.editorComponent.setMessageContent(message)
          alert('エラー');
        }
      );
    }
  }

  isImage(fileType: string) {
    return fileType.indexOf('image') >= 0
  }

  fileType(fileType: string): 'pdf' | 'ppt' | 'xls' {
    if (fileType.indexOf('pdf') >= 0) {
      return 'pdf'
    }
    if (fileType.indexOf('ms-powerpoint') >= 0 || fileType.indexOf('presentationml.presentation') >= 0) {
      return 'ppt'
    }
    if (fileType.indexOf('ms-excel') >= 0 || fileType.indexOf('spreadsheetml.sheet') >= 0) {
      return 'ppt'
    }
  }

  // ドラッグ&ドロップによるファイル追加時
  public onDropedFile = (files: FileList) => {
    for (let i = 0; i < files.length; i += 1) {
      this.addFile(files[i])
    }
  }
  // Local fileよりファイル選択時
  private onSelectedFile = () => {
    for (let i = 0; i < this.inputFileElement.files.length; i += 1) {
      this.addFile(this.inputFileElement.files[i])
    }

    // const sameFileMessage = this.pipeline.messages.find((row) => row.creative?.name === file.name);
    // let version: string;
    // if (sameFileMessage) {
    //   version = sameFileMessage.version;
    // }

    // const dialogRef = this.dialog.open(ModalPostMaterialComponent, {
    //   maxHeight: '90vh',
    //   data: {
    //     imageType: 'local',
    //     pipeline: this.pipeline,
    //     file,
    //   },
    // });
    // dialogRef.afterClosed().subscribe(pipelienMessage => {
    //   this.inputFileElement.value = null
    //   if (pipelienMessage) {
    //     this.addMessage(pipelienMessage)
    //   }
    // })
  }

  private async addFile(file: File) {
    const caseObj = this.storeSidemenu.getCase(this.pipeline.caseId)

    this.isUploading = true

    // ファイルのバージョンを取得する
    this.getVersion(file).pipe(
      concatMap((version) => {
        this.fileDataList.push({
          file,
          version,
        })
        if (this.inputFileElement) {
          this.inputFileElement.value = null
        }
        return from(this.createFileImage([{
          file,
          version,
        }]))
      }),
      concatMap((fileDataSets) => {
        // この時点でファイルをアップロードする
        return this.modelPipeline.addCreatives(caseObj.projectId, fileDataSets, )
      }),
    ).subscribe({
      next: (creatives) => {
        this.creativeList.push(...creatives)
        this.isUploading = false
      }
    })
  }

  private addMessage(newMessage: IPipelineMessage) {
    // 重複を避けるための処理
    const target = this.pipeline.messages.slice(-5).find((m) => m.id === newMessage.id)
    if (!target) {
      this.pipeline.messages.push(newMessage)
      if (newMessage.creatives.length > 0) {
        this.pipeline.fileCount = this.pipeline.fileCount + newMessage.creatives.length
      }
      setTimeout(() => {
        this.changeLayout.next()
      }, 500);
    }
  }

  /**
   * ファイルのバージョンを割り当てる
   * @param file 選択したファイル
   */
  private getVersion(file: File) {
    // 同Pipeline内で同じファイル名のCreativeを取得する
    return this.dataPipelineMessage.getVersions(this.pipeline.id, file.name).pipe(
      map((versions) => {
        let max = '1.0.0'
        versions.forEach((version) => {
          const latestVals = max.split('.')
          const currentVals = version.split('.')
          if (currentVals[0] !== undefined && Number(currentVals[0]) > Number(latestVals[0])) {
            max = version
          } else if (currentVals[1] !== undefined && Number(currentVals[1]) > Number(latestVals[1])) {
            max = version
          } else if (currentVals[2] !== undefined && Number(currentVals[2]) > Number(latestVals[2])) {
            max = version
          }
        })
        let version = '1.0.0'
        if (versions.length > 0) {
          const vals = max.split('.')
          vals[2] = `${Number(vals[2]) + 1}`
          version = vals.join('.')
        }
        return version

      }),
    )
  }

  /**
   * pdfファイルの画像を作成する
   * @param fileDatasets pdfファイル
   */
  private async createFileImage(fileDatasets: { file: File, version: string }[]): Promise<{ file: File, version: string, previewImage?: File }[]> {
    return Promise.all(fileDatasets.map(async (file) => {
      // pdfファイルの場合のみプレビュー画像作成
      if (file.file.type !== 'application/pdf') {
        return new Promise((resolve) => {
          resolve({
            file: file.file,
            version: file.version
          })
        })
      }
      const buffer = await file.file.arrayBuffer()
      const pdf = await getDocument(buffer).promise
      const page = await pdf.getPage(1)
      const viewParams = { scale: 1 }
      const viewPort = page.getViewport(viewParams)
      const canvas = document.createElement('canvas')
      canvas.width = viewPort.height
      canvas.height = viewPort.width
      canvas.style.display = 'none'
      document.body.append(canvas)
      await page.render({
        canvasContext: canvas.getContext('2d'),
        transform: [
          viewParams.scale,
          0,
          0,
          viewParams.scale,
          0,
          0
        ],
        viewport: viewPort,
        intent: 'print'
      }).promise
      const fileParams = {
        'lastModified': new Date().getTime(),
        type: 'image/png'
      }
      let rv
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          rv = new File(
            [blob],
            `${file.file.name.split('.pdf')[0]}.png`,
            fileParams
          )
          resolve({
            file: file.file,
            version: file.version,
            previewImage: rv as File
          })
        }, 'image/png')
        canvas.remove()
      })
    }))
  }

  // ファイルのアップロードが完了するまで待機する
  private async wait() {
    console.log('wait', this.isUploading)
    return new Promise((resolve) => {
      const ref = setInterval(() => {
        console.log('waiting...')
        if (!this.isUploading) {
          clearInterval(ref)
          resolve(null)
        }
      }, 500)
    })
  }

  private getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hour = now.getHours().toString().padStart(2, '0');
    const minute = now.getMinutes().toString().padStart(2, '0');
    
    return `${year}-${month}-${day} ${hour}:${minute}`;
}

}
