import { Injectable } from '@angular/core';

import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModelImage {

  constructor() { }

  public uploadImage(imageUrl: string, image: File) {
    // 取得したurlにアップロードする
    return new Observable<void>(
      (observer: Subscriber<void>) => {
        fetch(imageUrl, {
          method: 'PUT',
          mode: 'cors',
          body: image,
          headers: {
            'Content-Type': image.type,
            // 'Content-Type': '',
          }
        }).then(res => {
          observer.next();
        }).catch(error => {
          observer.error(error);
        });
      },
    );
  }

}
