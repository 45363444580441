import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appImage]'
})
export class ImageDirective implements OnChanges {
  constructor(private el: ElementRef) { }

  @Input() file: File;

  ngOnChanges(changes: SimpleChanges) {
    var blobUrl = window.URL.createObjectURL(this.file);
    (this.el.nativeElement as HTMLImageElement).src = blobUrl;
  }

}
