import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LibHttp } from '../lib/http.lib';
import { IUser } from '../interface/user';

@Injectable({
  providedIn: 'root'
})
export class DataUser {

  constructor(private http: LibHttp) { }

  login(email: string, password: string): Observable<any> {
    const params = {
      email,
      password
    };
    return this.http.postWithoutAuth('user/login/', params);
  }

  getMe(): Observable<{ user: IUser }> {
    return this.http.get('user/me/', {});
  }
}
