import { Injectable } from '@angular/core';

import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { TSocketEvent } from '../definition/socket-event';
import { IPipeline } from '../interface/pipeline';
import { IPipelineMessage } from '../interface/pipeline-message';
import { WebpushService } from '../webpush.service';
import { StoreSession } from './session.store';

@Injectable({
  providedIn: 'root'
})
export class StoreWebSocket {

  private _socket: Socket;

  constructor(
    private webpushService: WebpushService,
    private session: StoreSession) {}

  get socket(): Socket {
    return this._socket;
  }
  set socket(val) {
    this._socket = val
  }

  public connect() {
    this.socket = io(environment.socketUrl, {
      // withCredentials: false,
      transports: ['websocket'],
      // autoConnect: false,
      reconnectionAttempts: 0,
      reconnection: true,
      // auth: {
      //   authorize: token,
      // },
      // extraHeaders: {
      //   authorize: token as string,
      // },
      path: '/socket.io',
    })
  }

  public joinParty(partyId: string) {
    console.log('joinParty', partyId)
    this.socket.emit('join-party', { partyId })

    // コメントがあった時に呼ばれる
    this.socket.on<TSocketEvent>('message_post_all', (data: IPipelineMessage) => {
      if (data.partyUser.id === this.session.user.partyUsers[0].id) return
      
      const options = {
        body: `${data.partyUser.name}さんからのメッセージ`,
        icon: 'assets/img/favicon.ico',
        data: { url: `http://localhost:4002` },
      };
      this.webpushService.showNotification('新着メッセージ', options);

      const favicon = document.getElementById('favicon')
      favicon.setAttribute('href', 'assets/img/favicon_notification.ico')


    })
  }
}
