<div
  *ngIf="disabled"
  #refClickable></div>

<div
  *ngIf="overlay && trigger !== 'hover' && store.isShow"
  class="modal-overlay"></div>
<div
  *ngIf="store && ((!showOnlyDisabled && store.isShow) || (showOnlyDisabled && disabled && store.isShow))"
  #refPopover
  [class]="getRootClass()"  
  role="tooltip"
  [attr.data-popper-placement]="placementFixed"
  style="position: absolute; visibility: hidden; z-index: 999;">
  <div
    *ngIf="arrow"
    class="rd-popover-arrow"
    style="position: absolute;"></div>
  <div
    [attr.data-uuid]="uuid"
    class="rd-popover-body"
    style="visibility: hidden">
    <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
  </div>
</div>