import { Component, OnInit, ViewChild, OnDestroy, ElementRef, Input, TemplateRef, ContentChild, InjectionToken, Inject } from '@angular/core';
import { TPopoverPlacement } from './popover.directive';
import { StorePopover } from './popover.store';

export const COMMON_POPOVER_DATA = new InjectionToken<ICommonPopoverParam>('CommonPopoverData');

export interface ICommonPopoverParam { }

// arrowのwidthとheight
const ARROW_WIDTH = 12.79
const ARROW_HEIGHT = 6.4

// tooltipの左端(または右端)とwindowとの最低幅
const MIN_MERGIN = 16

@Component({
  selector: 'rd-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.css'],
})
export class CommonPopoverComponent implements OnInit, OnDestroy {

  // モーダルの中身
  @ContentChild(TemplateRef, { static: true }) bodyTemplate: TemplateRef<any>;
  @ViewChild('refPopover', { static: false }) refPopover: ElementRef;
  @ViewChild('refClickable', { static: false }) refClickable: ElementRef;
  
  // TODO: オプションをどう引き渡すか
  trigger: 'click' | 'hover' | 'focus' | 'manual'
  showOnlyDisabled = false
  disabled = false
  arrow = false
  placementFixed: TPopoverPlacement
  alignX?: 'left' | 'center' | 'right' = 'center'
  alignY?: 'top' | 'center' | 'bottom' = 'center'
  arrowLeft?: number
  arrowRight?: number
  fit?: 'width' | 'min-width' | 'max-width'
  offset: number
  overlay = false
  customClass: string

  uuid = 0
  popoverArrow: HTMLElement | null // 吹き出し
  popoverBody: HTMLElement | null // コンテンツ
  
  store: StorePopover
  private timerClickable: any
  

  constructor(
    public el: ElementRef,
  ) {
  }

  ngOnInit() {
    // (this.container.nativeElement as HTMLElement).style.visibility = 'hidden';
  }

  ngOnDestroy() {
    clearInterval(this.timerClickable)
  }

  initialize(
    store: StorePopover,
    bodyTemplate: TemplateRef<any>,
    
    trigger: 'click' | 'hover' | 'focus' | 'manual',
    showOnlyDisabled: boolean,
    disabled: boolean,
    arrow: boolean,
    placementFixed: TPopoverPlacement,
    alignX: 'left' | 'center' | 'right' | undefined,
    alignY: 'top' | 'center' | 'bottom' | undefined,
    arrowLeft: number | undefined,
    arrowRight: number | undefined,
    offset: number,
    fit: 'width' | 'min-width' | 'max-width' | undefined,
    overlay: boolean,
    customClass: string,
  ) {
    console.log('initialize@component', 1)

    this.store = store
    this.bodyTemplate = bodyTemplate

    this.trigger = trigger
    this.showOnlyDisabled = showOnlyDisabled
    this.disabled = disabled
    this.arrow = arrow
    this.placementFixed = placementFixed
    this.alignX = alignX
    this.alignY = alignY
    this.arrowLeft = arrowLeft
    this.arrowRight = arrowRight
    this.offset = offset
    this.fit = fit
    this.overlay = overlay,
    this.customClass = customClass

    // UUIDを発番(親子関係を判定するため)
    this.uuid = (new Date()).getTime()

    setTimeout(() => {
      if (!this.refPopover) {
        return
      }
      if (this.arrow) {
        this.popoverArrow = this.refPopover.nativeElement.getElementsByClassName('rd-popover-arrow')[0] as HTMLElement
      }
      this.popoverBody = this.refPopover.nativeElement.getElementsByClassName('rd-popover-body')[0] as HTMLElement
    
      this.setPosition()
  
      this.popoverBody.style.visibility = 'visible'
      this.refPopover.nativeElement.style.visibility = 'visible'
  
      if (this.disabled) {
        this.setClickable()
  
        // triggerの移動を検知してclickable要素を移動する必要があるため暫定的にタイマー形式にしている
        this.timerClickable = setInterval(() => {
          this.updateClickablePosition()
        }, 1000)
      }
      
    }, 100);
  }

  public setPosition = () => {
    if (!this.refPopover.nativeElement || (this.arrow && !this.popoverArrow) || !this.popoverBody) {
      return
    }
    console.log(this.placementFixed)
    
    let popoverX = 0
    let popoverY = 0
    let arrowX = 0
    let arrowY = 0
    if (this.placementFixed === 'top' || this.placementFixed === 'bottom') {
      let arrowHeight = 0
      if (this.arrow) {
        arrowHeight = ARROW_HEIGHT
      }
      if (this.placementFixed === 'top') {
        // trigger要素のtopにpopoverのbottomを配置してarrowHeight分上に
        popoverY = this.store.triggerElement.getBoundingClientRect().top - this.refPopover.nativeElement.offsetHeight - arrowHeight
        arrowY = this.refPopover.nativeElement.getBoundingClientRect().height
      } else {
        // trigger要素のbottomにpopoverのtopを配置してarrowHeight分下に
        popoverY = this.store.triggerElement.getBoundingClientRect().bottom + arrowHeight
        arrowY = -arrowHeight + 2
      }
      // 横方向の寄せの設定
      if (this.alignX === 'left') {
        popoverX = this.store.triggerElement.getBoundingClientRect().left
        if (this.arrowLeft) {
          arrowX = this.arrowLeft
        } else {
          arrowX = (this.store.triggerElement.getBoundingClientRect().width - ARROW_WIDTH) / 2
        }
      } else if (this.alignX === 'right') {
        popoverX = this.store.triggerElement.getBoundingClientRect().right - this.refPopover.nativeElement.offsetWidth
        if (this.arrowRight) {
          arrowX = this.refPopover.nativeElement.offsetWidth - this.arrowRight
        } else {
          arrowX = this.refPopover.nativeElement.offsetWidth - this.store.triggerElement.getBoundingClientRect().width + (this.store.triggerElement.getBoundingClientRect().width - ARROW_WIDTH) / 2
        }
      } else {
        popoverX = this.store.triggerElement.getBoundingClientRect().left + (this.store.triggerElement.getBoundingClientRect().width - this.refPopover.nativeElement.offsetWidth) / 2
        arrowX = (this.refPopover.nativeElement.offsetWidth - ARROW_WIDTH) / 2
      }
      if (Number(this.offset) > 0) {
        popoverX += Number(this.offset)
      }
    } else if (this.placementFixed === 'left' || this.placementFixed === 'right') {
      let arrowWidth = 0
      if (this.arrow) {
        arrowWidth = ARROW_HEIGHT
      }
      if (this.placementFixed === 'left') {
        // trigger要素のleftにpopoverのrightを配置してarrowWidth分左へ
        popoverX = this.store.triggerElement.getBoundingClientRect().left - this.refPopover.nativeElement.offsetWidth - arrowWidth
        arrowX = this.refPopover.nativeElement.offsetWidth
      } else {
        // trigger要素のrightにpopoverのleftを配置してarrowWidth分右へ
        popoverX = this.store.triggerElement.getBoundingClientRect().right + arrowWidth
        arrowX = -arrowWidth
      }
      // 縦方向の寄せの設定
      if (this.alignY === 'top') {
        popoverY = this.store.triggerElement.getBoundingClientRect().top
        arrowY = (this.store.triggerElement.getBoundingClientRect().height - ARROW_HEIGHT) / 2
      } else if (this.alignY === 'bottom') {
        popoverY = this.store.triggerElement.getBoundingClientRect().bottom - this.refPopover.nativeElement.offsetHeight
        arrowY = this.refPopover.nativeElement.offsetHeight - this.store.triggerElement.getBoundingClientRect().height + (this.store.triggerElement.getBoundingClientRect().height - ARROW_HEIGHT) / 2
      } else {
        popoverY = this.store.triggerElement.getBoundingClientRect().top - (this.refPopover.nativeElement.offsetHeight - this.store.triggerElement.getBoundingClientRect().height) / 2
        arrowY = (this.refPopover.nativeElement.offsetHeight - ARROW_WIDTH) / 2
      }
    }
    // 左右にはみでる場合はwindowにおさまるように補正をかける且つarrowはそのままの位置にする
    if (popoverX < MIN_MERGIN) {
      const diff = -popoverX + MIN_MERGIN
      arrowX -= diff
      popoverX += diff
    } else if (popoverX + this.refPopover.nativeElement.offsetWidth > window.innerWidth - MIN_MERGIN) {
      const diff = (popoverX + this.refPopover.nativeElement.offsetWidth - (window.innerWidth - MIN_MERGIN))
      arrowX += diff
      popoverX -= diff
    }
    console.log(popoverX, popoverY)
    this.refPopover.nativeElement.style.top = `${popoverY}px`
    this.refPopover.nativeElement.style.left = `${popoverX}px`
    if (this.fit) {
      if (this.fit === 'width') {
        this.refPopover.nativeElement.style[this.fit] = `${this.store.triggerElement.offsetWidth}px`
      } else if (this.fit === 'min-width') {
        this.refPopover.nativeElement.style.minWidth = `${this.store.triggerElement.offsetWidth}px`
      } else if (this.fit === 'max-width') {
        this.refPopover.nativeElement.style.maxWidth = `${this.store.triggerElement.offsetWidth}px`
      }
    }
    if (this.popoverArrow) {
      this.popoverArrow.style.top = `${arrowY}px`
      this.popoverArrow.style.left = `${arrowX}px`
    }
  }

  public getRootClass() {
    const alignClass: string[] = []
    alignClass.push(`rd-popover-${this.placementFixed}`)

    if (this.alignX === 'left' || this.alignX === 'right') {
      alignClass.push(`align-${this.alignX}`)
    }
    if (this.alignY === 'top' || this.alignY === 'bottom') {
      alignClass.push(`align-${this.alignY}`)
    }
    alignClass.push()
    if (this.customClass) {
      this.customClass.split(' ').forEach((c) => {
        alignClass.push(c)
      })
    }
    return alignClass
  }

  private setClickable() {
    // PopoverまたはModal内のPopoverの場合は全面にもってくる(現時点では不要なため3重構造までは考慮していない)
    const parentPopover = this.store.triggerElement.closest('.rd-popover-body')
    const parentModal = this.store.triggerElement.closest('.modal-content')
    if (parentPopover || parentModal) {
      this.refClickable.nativeElement.style.zIndex = '10000'
    }
    this.refClickable.nativeElement.style.position = 'absolute'
    if (this.trigger === 'click') {
      this.refClickable.nativeElement.addEventListener('click', this.store.onClick as any)
    } else if (this.trigger === 'hover') {
      this.refClickable.nativeElement.addEventListener('mouseover', this.store.onMouseOver)
    }
    this.updateClickablePosition()
  }

  private updateClickablePosition() {
    if (!this.refClickable.nativeElement) {
      return
    }
    this.refClickable.nativeElement.style.top = `${this.store.triggerElement.getBoundingClientRect().top}px`
    this.refClickable.nativeElement.style.left = `${this.store.triggerElement.getBoundingClientRect().left}px`
    this.refClickable.nativeElement.style.width = `${this.store.triggerElement.getBoundingClientRect().width}px`
    this.refClickable.nativeElement.style.height = `${this.store.triggerElement.getBoundingClientRect().height}px`
  }
}
