import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataPartyUser } from 'src/app/logic/data/party-user.data';
import { StoreSidemenu } from 'src/app/logic/store/sidemenu.store';
import { ModalAddProjectPartyUserComponent } from '../add-project-party-user/add-project-party-user.component';

@Component({
  selector: 'app-modal-project-setting',
  templateUrl: './project-setting.component.html',
})

export class ModalProjectSettingComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModalProjectSettingComponent, boolean>,
    public storeSidemenu: StoreSidemenu,
    private dataPartyUser: DataPartyUser,
    private dialog: MatDialog,
  ) { }

  image

  ngOnInit() {
    const partyId: string = this.storeSidemenu.settingProject.partyId
    const projectId: string = this.storeSidemenu.settingProject.id
    // projectに参加しているpartyユーザーを取得
    this.dataPartyUser.getPartyUsersByPartyIdOrProjectId(partyId, projectId).subscribe((projectPartyUsers) => {
      this.storeSidemenu.settingProjectPartyUsers = projectPartyUsers
    })
    // partyユーザーを取得
    this.dataPartyUser.getPartyUsersByPartyIdOrProjectId(partyId).subscribe((partyUsers) => {
      this.storeSidemenu.settingPartyUsers = partyUsers
    })
    const intervalId = setInterval(() => {
      if (this.storeSidemenu.settingProjectPartyUsers) {
        const projectPartyUserIds = this.storeSidemenu.settingProjectPartyUsers.map((projectPartyUser) => projectPartyUser.id)
        // projectに参加していないpartyユーザーを算出
        this.storeSidemenu.settingPartyUsersOfNotJoinedProject = this.storeSidemenu.settingPartyUsers.filter((partyUser) => !projectPartyUserIds.includes(partyUser.id))
        clearInterval(intervalId)
        // projectPartyUser追加で使うチェックリストをここで生成する
        let checkList: { [key: string]: boolean } = {}
        this.storeSidemenu.settingPartyUsersOfNotJoinedProject.forEach((projectPartyUser) => {
          checkList[projectPartyUser.id] = false
        })
        this.storeSidemenu.settingPartyUsersCheckList = checkList
      }
    }, 100)
  }

  onAddProjectPartyUser() {
    this.dialog.open(ModalAddProjectPartyUserComponent, {
      maxHeight: '90vh',
    });
  }
}


