import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { ModelUserAuth } from 'src/app/logic/model/user-auth.model';
import { LibStorage } from 'src/app/logic/lib/storage.lib'

@Component({
  selector: 'app-user-auth',
  templateUrl: './user-auth.component.html',
})
export class UserAuthComponent {

  formGroup = new FormGroup({
    authenticationCode: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(
    private modelUserAuth: ModelUserAuth,
    private storage: LibStorage
  ) { }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe((value: { [key: string]: string }) => {
      // TODO: validate
    });
  }

  authenticateUser(tooltip: MatTooltip) {
    if (!this.formGroup.valid) {
      return;
    }
    const email: string = this.storage.getObject('email')['email']
    const authenticationCode: number = Number(this.formGroup.get('authenticationCode').value)
    this.modelUserAuth.authenticateUser(email, authenticationCode).subscribe({
      next: (result) => {
        this.storage.remove('email')
      },
      error: (error) => {
        // 登録失敗
        console.error(error);
        tooltip.message = error.error.message;
        tooltip.show();
        setTimeout(() => {
          tooltip.hide();
          tooltip.message = '';
        }, 2000);
      }
    });
  }

}
