<div
  id="{{elementId}}"
  #rootRef
  placeholder="Message"
  role="textbox"
  aria-multiline="true"
  contenteditable="true"
  [attr.data-placeholder]="dataPlaceholder"
  appKeyEvent
  (entered)="onEntered()"
  (tabed)="onTabed($event)"
  (click)="onClick($event)"
  (focus)="onFocus()"
  (mouseup)="onMouseup()"
  (keydown)="onKeydown($event)"
  (keyup)="onKeyup($event)"
  (paste)="onPaste($event)"
  [innerHTML]="dummyHtml | sanitizeHtml"
  class="dn-message-input">
</div>