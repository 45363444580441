import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalSelectMaterialComponent } from 'src/app/component/modal/select-material/select-material.component';
import { IPipeline } from 'src/app/logic/interface/pipeline';
import { DataPipelineMessage } from 'src/app/logic/data/pipeline-message.data';
import { IPipelineFile, IPipelineMessage } from 'src/app/logic/interface/pipeline-message';
import { ModalPostMaterialComponent } from 'src/app/component/modal/post-material/post-material.component';
import { StoreSession } from 'src/app/logic/store/session.store';
import { ICase } from 'src/app/logic/interface/case';
import { Subject } from 'rxjs';
import { LibAlert } from 'src/app/logic/lib/alert.lib';
import { DataPipeline } from 'src/app/logic/data/pipeline.data';
import { TCreativeMin } from 'src/app/logic/interface/creative';
import { ModalFileDetailComponent } from 'src/app/component/modal/file-detail/file-detail.component';

@Component({
  selector: '[app-case-pipeline-message-item]',
  templateUrl: './message-item.component.html',
})
export class CasePipelineMessageItemComponent implements OnInit, OnChanges {

  @Input() case: ICase
  @Input() pipeline: IPipeline
  @Input() message: IPipelineMessage

  changeLayout = new Subject<void>();

  isInputFocus = false;
  isDragOver = false;
  fileSets: { root: IPipelineFile, history: IPipelineFile[]}[] = []

  constructor(
    public session: StoreSession,
    private dialog: MatDialog,
    private dataPipeline: DataPipeline,
    private dataPipelineMessage: DataPipelineMessage,
    private el: ElementRef,
    private alert: LibAlert,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

  public isImage(fileType: string) {
    return fileType.indexOf('image') >= 0
  }

  public fileType(fileType: string): 'pdf' | 'ppt' | 'xls' | 'word' {
    if (fileType.indexOf('pdf') >= 0) {
      return 'pdf'
    }
    if (fileType.indexOf('ms-powerpoint') >= 0 || fileType.indexOf('presentationml.presentation') >= 0) {
      return 'ppt'
    }
    if (fileType.indexOf('ms-excel') >= 0 || fileType.indexOf('spreadsheetml.sheet') >= 0) {
      return 'xls'
    }
    if (fileType.indexOf('ms-word') >= 0 || fileType.indexOf('wordprocessingml.document') >= 0) {
      return 'word'
    }
  }

  public onDownloadFile(creative: TCreativeMin) {
    console.log(creative.url)

    fetch(creative.url, {
      method: 'GET',
      mode: 'cors',
      // body: file,
      // headers: {
      //   'Content-Type': file.type
      // }
    }).then(async (res) => {

      const blob = await res.blob();
      const newBlob = new Blob([blob]);

      const blobUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `${creative.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
    }).catch(error => {

    })

  }

  
  onShowFileDetail(creative: TCreativeMin) {
    this.dialog.open(ModalFileDetailComponent, {
      maxHeight: '90vh',
      data: creative,
    })
  }

}
