import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LibLoading } from 'src/app/logic/lib/loading.lib';
import { ModelPartyUser } from 'src/app/logic/model/party-user.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-party-user',
  templateUrl: './add-party-user.component.html',
})

export class ModalAddPartyUserComponent implements OnInit {

  formGroup = new FormGroup({
    emails: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<ModalAddPartyUserComponent, boolean>,
    private loading: LibLoading,
    private modelPartyUser: ModelPartyUser,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.loading.show();

    const partyId: string = location.href.split('/')[4]
    const emails: string[] = this.formGroup.get('emails').value.split('\n')

    this.modelPartyUser.addPartyUser(partyId, emails).subscribe(
      () => {
        this.loading.hide();
        this.dialogRef.close();
        alert('ユーザーに招待メールを送信しました');
      },
      (error) => {
        this.loading.hide();
        const message = error.error.message ? error.error.message : '想定外のエラーです';
        alert(message);
      }
    );

  }

}



