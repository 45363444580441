import { Injectable } from '@angular/core';
import { Observable, of, map } from 'rxjs';

import { DataPartyUser } from '../data/party-user.data';

@Injectable({
  providedIn: 'root'
})
export class ModelPartyUser {

  constructor(
    private dataPartyUser: DataPartyUser,
  ) { }

  public addPartyUser(partyId: string, emails: string[]): Observable<string> {
    return this.dataPartyUser.addPartyUser(partyId, emails)
  }

  public addProjectPartyUser(partyId: string, projectId: string, partyUserIds: string[]): Observable<string> {
    return this.dataPartyUser.addProjectPartyUser(partyId, projectId, partyUserIds)
  }

}
