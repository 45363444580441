import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { ModelUserAuth } from 'src/app/logic/model/user-auth.model';
import { LibStorage } from 'src/app/logic/lib/storage.lib'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
})
export class SignupComponent {

  formGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
    ]),
    password: new FormControl('', [
      Validators.required,
    ]),
    name: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(
    private modelUserAuth: ModelUserAuth,
    private router: Router,
    private storage: LibStorage
  ) { }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe((value: { [key: string]: string }) => {
      // TODO: validate
    });
  }

  signup(tooltip: MatTooltip) {
    if (!this.formGroup.valid) {
      return;
    }
    this.modelUserAuth.signup(this.formGroup.get('email').value, this.formGroup.get('password').value, this.formGroup.get('name').value).subscribe({
      next: () => {
        // ユーザー認証画面へ遷移する
        this.storage.setObject('email', { email: this.formGroup.get('email').value })
        this.router.navigate(['/user-auth'])
      },
      error: (error) => {
        // 登録失敗
        console.error(error);
        tooltip.message = error.error.message;
        tooltip.show();
        setTimeout(() => {
          tooltip.hide();
          tooltip.message = '';
        }, 2000);
      }
    });
  }


}
