
export const partyId = (): string => {
  const path = location.pathname
  const pattern = /^\/party\/([^\/]+)(?:\/project\/([^\/]+))?/i;
  const result = path.match(pattern)
  return result[1]
}

export const isImage = (fileType: string) => {
  return fileType.indexOf('image') >= 0
}