import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LibHttp } from '../lib/http.lib';

@Injectable({
    providedIn: 'root'
})
export class DataUserAuth {

    constructor(private http: LibHttp) { }

    signup(email: string, password: string, name: string): Observable<any> {
        const params = {
            email,
            password,
            name
        };
        return this.http.postWithoutAuth('user_auth', params);
    }

    authenticateUser(email: string, authenticationCode: number): Observable<any> {
        const params = {
            email,
            authenticationCode
        };
        return this.http.postWithoutAuth('user_auth/complete', params);
    }

}
